import React from "react"

export const withNavigationHeaderDesktopDropdown = Component => ({
  name = "NavigationHeaderDesktopDropdown",
  item,
  active,
}) => {
  const { type, items, shopAll } = item

  const cols = items?.reduce(
    (acc, cur) => (cur.type === "twocolimage" ? acc + 2 : acc + 1),
    0,
  )

  Component.displayName = name
  return (
    <Component
      type={type}
      items={items}
      active={active}
      cols={cols}
      shopAll={shopAll}
    />
  )
}
