import tw from "twin.macro"

export const List = tw.div`
  block mx-1-6 w-full
`

export const Title = tw.h3`
  text-14 leading-1.71 mb-1-2 font-bold uppercase 
`

export const ListItem = tw.div`
  text-14 leading-none py-0-6 my-0-6 text-white text-opacity-70
`
