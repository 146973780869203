import React, { useMemo, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"
import { useSale } from "../hooks/useSale"
import { useShopify } from "../hooks/useShopify"
import { useLocation } from "../hooks/useLocation"

export const CheckoutContext: any = React.createContext({})

export const CheckoutProvider = ({ children }): JSX.Element => {
  const [checkout, setCheckout] = useState(false)

  const { location } = useLocation()

  const [freeGiftSets, setFreeGiftSets] = useState([])

  const [initializedLoading, setInitializedLoading] = useState(true)

  const { edgeNormaliser } = useShopify()

  const { getOnSale } = useSale()

  const { pageGiftcard, rawPromotions } = useStaticQuery(graphql`
    query SANITY_CHECKOUT {
      pageGiftcard: sanityPageGiftcard {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
      }
      rawPromotions: allSanityPromotion(filter: { active: { eq: true } }) {
        edges {
          node {
            title
            slug {
              current
            }
            promotionRules {
              isTheLast
              promotionTags
              rule: _rawRule(resolveReferences: { maxDepth: 10 })
              exclusions
            }
          }
        }
      }
    }
  `)

  const { settingGiftCardProduct } = useMemo(() => {
    return pageGiftcard || {}
  }, [pageGiftcard])

  const promotions = useMemo(() => {
    return rawPromotions
      ? edgeNormaliser(rawPromotions).reduce(
          (result, item) =>
            item?.slug?.current
              ? {
                  ...result,
                  [item.slug.current]: {
                    rules: item?.promotionRules,
                  },
                  [item.slug.current + "s"]: {
                    rules: item?.promotionRules,
                  },
                }
              : result,
          {},
        )
      : {}
  }, [rawPromotions, edgeNormaliser])

  const saleName = useMemo(() => {
    return getOnSale()
  }, [getOnSale])

  const saleNameSuffix = useMemo(() => {
    return location === "NZ" ? `-${location?.toLocaleLowerCase()}` : ""
  }, [location])

  const livePromotionRules = useMemo(() => {
    return (
      promotions?.[saleName + saleNameSuffix]?.rules ||
      promotions?.[saleName]?.rules ||
      []
    )
  }, [promotions, saleName, saleNameSuffix])

  const countryCode = useMemo(() => {
    return checkout?.buyerIdentity?.countryCode || location || "AU"
  }, [checkout?.buyerIdentity?.countryCode, location])

  const onSetCheckout = React.useCallback(
    checkout => {
      // let's you hook into the set checkout method for console.trace eg.
      setCheckout(checkout)
    },
    [setCheckout],
  )
  // const livePromotionRules = useMemo(() => {
  //   const ret = promotions?.[saleName]?.rules || []
  //   return ret?.filter(item => item?.rule)
  // }, [promotions, saleName])

  const values = {
    checkout,
    countryCode,
    setCheckout: onSetCheckout,
    freeGiftSets,
    setFreeGiftSets,
    initializedLoading,
    setInitializedLoading,
    livePromotionRules,
    count: checkout?.lineItems?.reduce(
      (acc, cur) =>
        acc +
        (cur.variant.product.handle === settingGiftCardProduct?.shopify?.handle
          ? 1
          : cur.quantity),
      0,
    ),
    giftcardHandle: settingGiftCardProduct?.shopify?.handle,
  }

  return (
    <CheckoutContext.Provider value={{ ...values }}>
      {children}
    </CheckoutContext.Provider>
  )
}
