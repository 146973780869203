import { useCallback, useEffect, useState } from "react"
import { useApolloClient, useLazyQuery, useMutation, useQuery, } from "@apollo/client"
import useDeepCompareEffect from "use-deep-compare-effect"

import { useAnalytics } from "./useAnalytics"
import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useCheckoutContext } from "./useCheckout"
import { useShopContext } from "./useShop"
import { useRoutes } from "./useRoutes"
import { useQueries } from "./useQueries"
import { useHotjar } from "./useHotjar"
import { useLocation } from "./useLocation"
import { GET_GROUPED_PRODUCTS_BY_HANDLE, GET_PRODUCTS_BY_HANDLE, GET_PRODUCTS_PRICES } from "../graphql/queries/product"

export const useShopify = () => {
  const client = useApolloClient()
  const { shop } = useShopContext()
  const { checkout } = useCheckoutContext()
  const {
    graphql: {
      queries: { GET_COLLECTION_PRODUCT_COMPLETE },
    },
  } = useCore()

  const {
    queries: {
      // GET_PRODUCTS_BY_HANDLE,
      GET_COLLECTION_PRODUCT_REFERENCES,
    },
  } = useQueries()

  const { location } = useLocation()

  const edgeNormaliser = object =>
    object?.edges?.map(({ node }) => node) || object || []

  const productNormaliser = product => ({
    ...product,
    images: edgeNormaliser(product?.images)?.map(image =>
      imageNormaliser(image),
    ),
    media: edgeNormaliser(product?.media).map(item => {
      if (item.mediaContentType === "IMAGE") {
        return {
          ...item,
          image: imageNormaliser(item.image),
        }
      } else {
        return {
          ...item,
          previewImage: imageNormaliser(item.previewImage),
        }
      }
    }),
    collections:
      edgeNormaliser(product?.collections)?.map(collection => ({
        ...collection,
        image: imageNormaliser(collection?.image),
      })) || [],
    metafields: product?.metafields?.filter(metafield => metafield),
    variants:
      edgeNormaliser(product?.variants)?.map(variant => ({
        ...variant,
        image: imageNormaliser(variant?.image),
        metafields: edgeNormaliser(variant?.metafields),
      })) || [],
  })

  const collectionNormaliser = collection => ({
    ...collection,
    image: imageNormaliser(collection?.image),
    metafields: edgeNormaliser(collection?.metafields),
    products: edgeNormaliser(collection?.products).map(product =>
      productNormaliser(product),
    ),
  })

  const imageNormaliser = (image, size = null) => ({
    alt: image?.altText || image?.alt || image?.asset?.alt || "",
    src: imageUrl(
      image?.originalSrc || image?.src || image?.asset?.url || ``,
      size,
    ),
    srcSet: imageSrcSets(
      image?.originalSrc || image?.src || image?.asset?.url,
      size,
    ),
  })

  const priceNormaliser = (presentmentPrices, field) =>
    Object.assign(
      {},
      ...edgeNormaliser(presentmentPrices)
        ?.filter(
          item =>
            item[field]?.currencyCode ===
            (checkout?.currencyCode || shop?.paymentSettings?.currencyCode || "AUD"),
        )
        .map(item => ({
          ...item[field],
          local: formatMoney(
            item[field]?.amount,
            checkout?.currencyCode || shop?.paymentSettings?.currencyCode || "AUD",
          ),
        })),
    )

  const checkoutNormaliser = checkout => {
    return ({
      ...checkout,
      discountApplications: edgeNormaliser(checkout?.discountApplications),
      lineItems:
        checkout?.lineItems?.edges.map(({ node }) => ({
          ...node,
          variant: {
            ...node?.variant,
            image: imageNormaliser(node?.variant?.image, 500),
            priceV2: node?.variant?.priceV2,
            compareAtPriceV2: node?.variant?.compareAtPriceV2,
          },
        })) || [],
    })
  }

  const formatMoney = (amount, currency = "AUD") =>
    new Intl.NumberFormat(`en-${shop?.paymentSettings?.countryCode || "AU"}`, {
      style: "currency",
      currency: currency,
    }).format(amount)

  const imageUrl = (src, size = null): any => {
    const dimensions = `${size}x${size}`
    const match = src?.match(
      /\.(jpg|jpeg|gif|png|bmp|bitmap|tiff|tif)(\?v=\d+)?$/i,
    )

    return match && src?.includes(`shopify.com`) && size && size !== `master`
      ? `${src?.split(match[0])[0]}_${dimensions}${match[0]}`.replace(
          /http(s)?:/,
          ``,
        )
      : src
  }

  const imageSrcSets = (src, size) =>
    src?.includes(`shopify.com`) &&
    [1, 500, 1000, 1500, 2000]
      .filter(set => !size || (size && size >= set))
      .map(set => `${imageUrl(src, set)} ${set}w`)
      .join(`,`)

  const onSale = (price, compareAtPrice) =>
    compareAtPrice && price && parseInt(compareAtPrice) > parseInt(price)

  const getHandle = item => item?.handle || item?.shopify?.shopifyHandle

  const getCollection = async ({
    firstCollections = 0,
    firstImages = 0,
    metafieldIdentifiers = [],
    firstProducts = 0,
    firstVariants = 0,
    firstMedia = 0,
    handle,
  }) => {
    const { data } = await client.query({
      query: GET_COLLECTION_PRODUCT_COMPLETE,
      variables: {
        countryCode: location,
        firstCollections,
        firstImages,
        metafieldIdentifiers,
        firstProducts,
        firstVariants,
        firstMedia,
        handle,
      },
    })

    return collectionNormaliser(data?.collection)
  }

  const getProducts = async ({
    firstImages = 0,
    metafieldIdentifiers = [],
    firstVariants = 0,
    handles = [],
  }) => {

    try {
      const { data, error } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(handles),
        variables: {
          countryCode: location,
          firstImages: firstImages || 99,
          metafieldIdentifiers,
          firstVariants: firstVariants || 99,
          firstCollections: 99,
          firstMedia: 99,
          firstMetafields: 99,
        },
      })

      return handles?.map(handle =>
        productNormaliser(data[`product${handle?.replace(/-/g, "")}`]),
      )
    } catch (err) {
      console.error('err', err)
      return []
    }
  }

  const getGroupedProducts = async ({
    firstImages = 0,
    firstVariants = 0,
    products = [],
  }) => {
    const { data } = await client.query({
      query: GET_GROUPED_PRODUCTS_BY_HANDLE(products),
      variables: {
        countryCode: location,
        firstMedia:firstImages,
        firstCollections: 1,
        firstImages,
        firstVariants,
        metafieldIdentifiers: []
      },
    })

    return products
      ?.map(({ handle }) => ({
        product: data[`product${handle?.replace(/-/g, "")}`]
          ? productNormaliser(data[`product${handle?.replace(/-/g, "")}`])
          : null,
        group: edgeNormaliser(
          data[`productgrouped${handle?.replace(/-/g, "")}`],
        ).map(product => productNormaliser(product)),
      }))
      .filter(item => item.product)
  }

  const getCollectionGroupedProducts = async ({
    collectionHandle,
    firstProducts = 10,
    firstImages = 2,
    firstVariants = 1,
  }) => {
    const {
      data: { collection },
    } = await client.query({
      query: GET_COLLECTION_PRODUCT_REFERENCES,
      variables: {
        countryCode: location,
        handle: collectionHandle,
        firstProducts: 50,
      },
    })
    return await getGroupedProducts({
      firstImages,
      firstVariants,
      products: edgeNormaliser(collection.products)
        .filter(product => product?.tags?.includes?.("sanity:hidden"))
        .filter((_: any, index) => index < firstProducts),
    })
  }


  const getProductsPrices = async (handles) => {
    try {
      if (!Array.isArray(handles) || handles.length === 0) {
        console.warn('getProductsPrices called with invalid handles');
        return { prices: [], outOfStockVariants: [] };
      }
  
      const query = handles.map(handle => `${handle}`).join(' OR ');
  
      const { data } = await client.query({
        query: GET_PRODUCTS_PRICES,
        variables: { 
          query: query,
        },
      });
  
      console.log("Raw data from query: ", data);
  
      if (!data || !data.products || !Array.isArray(data.products.edges)) {
        console.error('Unexpected data structure received from API');
        return { prices: [], outOfStockVariants: [] };
      }
  
      const prices = data.products.edges.flatMap(edge => {
        const product = edge.node;
        if (!product || !product.variants || !Array.isArray(product.variants.edges)) {
          console.warn(`Invalid product data structure for product ${product?.id || 'unknown'}`);
          return [];
        }
        return product.variants.edges.map(variantEdge => ({
          id: product.id,
          priceV2: variantEdge.node.priceV2,
          compareAtPriceV2: variantEdge.node.compareAtPriceV2,
        }));
      });
  
      const outOfStockVariants = data.products.edges.flatMap(edge => {
        const product = edge.node;
        if (!product || !product.variants || !Array.isArray(product.variants.edges)) {
          return [];
        }
        return product.variants.edges
          .filter(variantEdge => variantEdge.node && variantEdge.node.availableForSale === false)
          .map(variantEdge => variantEdge.node.id);
      });
  
      console.log("Processed prices: ", prices);
      console.log("Processed out of stock variants: ", outOfStockVariants);
  
      return { prices, outOfStockVariants };
    } catch (error) {
      console.error('Error in getProductsPrices:', error);
      return { prices: [], outOfStockVariants: [] };
    }
  }

  return {
    client,
    useQuery,
    useLazyQuery,
    useMutation,
    onSale,
    imageUrl,
    imageSrcSets,
    formatMoney,
    edgeNormaliser,
    imageNormaliser,
    checkoutNormaliser,
    priceNormaliser,
    productNormaliser,
    collectionNormaliser,
    getHandle,
    getCollection,
    getProducts,
    getGroupedProducts,
    getCollectionGroupedProducts,
    getProductsPrices,
  }
}

export const useShopifyProduct = () => {
  const { activeProduct, setActiveProduct } = useApp()

  const selectProduct = useCallback(
    (product, path) => {
      if (path?.includes("products")) {
        if (!activeProduct) setActiveProduct(product)
      } else {
        if (activeProduct !== false) setActiveProduct(false)
      }
    },
    [activeProduct, setActiveProduct],
  )

  return { activeProduct, selectProduct }
}

export const getVariantBySelectedOptions = (selectedOptions, variants) =>
  variants?.find(
    ({ selectedOptions: variantOptions }) =>
      variantOptions?.filter(
        variantOption =>
          variantOption.value ===
          selectedOptions.find(
            selectedOption => selectedOption.name === variantOption.name,
          )?.value,
      )?.length === selectedOptions?.length,
  )

export const useShopifyVariants = ({
  firstAvailable = false,
  useParameter = false,
  loading = false,
  product,
  preSelectedVariantTitle = undefined,
}) => {
  const { trackProductView } = useAnalytics()
  const {
    activeProduct,
    config: {
      settings: { params },
    },
  } = useApp()

  const {
    helpers: { encodeShopifyId, decodeShopifyId, isBrowser },
  } = useCore()

  const { getUrlParameter, setUrlParameter } = useRoutes()

  const [activeVariant, setActiveVariant] = useState(null)
  const [selectedOptions, setSelectedOptions] = useState([])

  // reset activeVariant and selectedOptions if product changes
  useDeepCompareEffect(() => {
    setActiveVariant(null)
    setSelectedOptions([])
  }, [product?.variants])

  const { variants, options } = product

  // get variant id from URL params
  const currentVariantId = getUrlParameter(params?.variant)

  // get default variant if useParameter or firstAvailable is enabled
  const defaultVariant =
    (useParameter &&
      variants?.find(
        variant =>
          variant?.id === encodeShopifyId(currentVariantId, "ProductVariant"),
      )) ||
    variants?.find(variant => variant?.title === preSelectedVariantTitle) ||
    (firstAvailable &&
      variants?.find(({ availableForSale }) => availableForSale))

  //if there is a default variant, set it to active variant
  useEffect(() => {
    if (defaultVariant && !activeVariant) {
      setActiveVariant(defaultVariant)
      setSelectedOptions(defaultVariant.selectedOptions)
    }
  }, [defaultVariant, activeVariant])

  // select option with only 1 value by default, e.g. Colour
  useEffect(() => {
    if (selectedOptions?.length === 0 && options?.length > 0) {
      options?.forEach(option => {
        if (option?.values.length === 1) {
          setSelectedOptions(prev => [
            ...prev.filter(prevOption => prevOption.name !== option.name),
            {
              name: option.name,
              value: option.values[0],
            },
          ])
        }
      })
    }
  }, [selectedOptions, options])

  // reset selectedOptions if options changes, for quick view products colours changes
  useDeepCompareEffect(() => {
    if (options?.length > 0) {
      setSelectedOptions([])
    }
  }, [options])

  // update selected variant if selectedOptions changes, selectedOption is an array. useEffect is not able to compare the objects in an array
  useDeepCompareEffect(() => {
    if (
      selectedOptions.length > 0 &&
      selectedOptions.length === options.length
    ) {
      const variant = getVariantBySelectedOptions(selectedOptions, variants)
      if (variant?.id) {
        setActiveVariant(variant)
      }
    }
  }, [selectedOptions])

  // Navigate to the active variant(add the URL params) if useParameter is enabled
  useEffect(() => {
    if (isBrowser && useParameter && activeVariant?.id && !loading) {
      if (
        currentVariantId !==
          encodeShopifyId(activeVariant?.id, "ProductVariant") &&
        activeVariant?.id !== defaultVariant?.id
      ) {
        window.history.replaceState(
          null,
          "",
          setUrlParameter(
            params?.variant,
            decodeShopifyId(activeVariant.id, "ProductVariant"),
          ),
        )
      }
      trackProductView(activeProduct, activeVariant)
    }
  }, [
    isBrowser,
    activeVariant,
    activeVariant?.id,
    defaultVariant?.id,
    currentVariantId,
    decodeShopifyId,
    encodeShopifyId,
    loading,
    params?.variant,
    setUrlParameter,
    useParameter,
    trackProductView,
    activeProduct,
  ])

  const { trackHotjarEvent } = useHotjar()

  // handle variant change
  const handleVariant = useCallback(option => {

    trackHotjarEvent("variant_change")

    setSelectedOptions(prev => [
      ...prev.filter(prevOption => prevOption.name !== option.name),
      option,
    ])
  }, [])

  return { activeVariant, handleVariant, selectedOptions }
}
