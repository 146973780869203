import React from "react"

import { withNavigationHeaderDesktopDropdown } from "./withNavigationHeaderDesktopDropdown"
import {
  Inner,
  Dropdown,
  Outer,
  OuterBackgroundLeft,
  OuterBackgroundRight,
  List,
  ShopAll,
} from "./NavigationHeaderDesktopDropdownStyles"
import { Link } from "../../../../../Link/Link"
import { NavigationItemsImageCard } from "../../../../../NavigationItems/ImageCard/NavigationItemsImageCard"
import { UnderlinedButton } from "../../../../../Styled/Button"
import { DropdownHeroCardList } from "../Dropdown/HeroCardList/DropdownHeroCardList"
import { DropdownSubList } from "../Dropdown/SubList/DropdownSubList"

export const NavigationHeaderDesktopDropdown = withNavigationHeaderDesktopDropdown(
  ({ items, active, cols, shopAll, type }) =>
    active ? (
      <Outer active={active}>
        <OuterBackgroundLeft />
        <OuterBackgroundRight />
        <Inner>
          {type === "subheroimages" ? (
            <DropdownHeroCardList items={items} />
          ) : (
            <Dropdown cols={cols}>
              {items?.map(item => {
                return item?.type === "twocolimage" ? (
                  <List twocol={true} key={item?._key}>
                    <NavigationItemsImageCard item={item} />
                  </List>
                ) : (
                  <List key={item?._key}>
                    <DropdownSubList item={item} />
                  </List>
                )
              })}
            </Dropdown>
          )}
          {Boolean(shopAll?.title) && (
            <ShopAll>
              <UnderlinedButton size={"primary"}>
                <span>
                  <Link
                    title={shopAll?.title}
                    url={shopAll?.url}
                    external={shopAll?.external}
                    isExternal={shopAll?.isExternal}
                  />
                </span>
              </UnderlinedButton>
            </ShopAll>
          )}
        </Inner>
      </Outer>
    ) : null,
)
