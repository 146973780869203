import gql from "graphql-tag"

import { CHECKOUT_FRAGMENT } from "../fragments/checkoutFragment"

export const GET_CHECKOUT = gql`
  query GET_CHECKOUT($checkoutId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    node(id: $checkoutId) {
      ... on Checkout {
        ...CustomizedCheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const GET_SHIPPING_RATES = gql`
  query GET_SHIPPING_RATES($id: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    node(id: $id) {
      ... on Checkout {
        ...CustomizedCheckoutFragment
        availableShippingRates {
          ready
          shippingRates {
            handle
            priceV2 {
              amount
              currencyCode
            }
            title
          }
        }
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`
