import React, { useState, useContext, createContext, cloneElement } from "react"

import { Dialog, DialogOverlay, DialogContent, Close } from "./ModalStyles"
import { Icon } from "../Icon/Icon"

const callAll = (...fns) => (...args) => fns.forEach(fn => fn && fn(...args))

const ModalContext = createContext(null)

function Modal(props) {
  const [isOpen, setIsOpen] = useState(false)

  React.useEffect(() => {
    if (props.toggleModal !== undefined) {
      setIsOpen(props.toggleModal)
    }
  }, [props.toggleModal])

  React.useEffect(() => {
    // if props.toggleModal is set and isOpen is false, call props.onClose
    if (props.toggleModal !== undefined && !isOpen) {
      props.onClose()
    }
  }, [isOpen])

  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalExternal({ isOpen, setIsOpen, ...props }) {
  return <ModalContext.Provider value={[isOpen, setIsOpen]} {...props} />
}

function ModalDismissButton({ children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(false), child.props.onClick),
  })
}

function ModalOpenButton({ disabled = false, children: child }) {
  const [, setIsOpen] = useContext(ModalContext)
  return cloneElement(child, {
    onClick: callAll(() => setIsOpen(!disabled), child.props.onClick),
  })
}

function ModalContents({ layout, ...props }) {
  const [isOpen, setIsOpen] = useContext(ModalContext)
  return (
    <Dialog
      layout={layout}
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      {...props}
    />
  )
}

function ModalContentsWithLayout({
  children,
  layout,
  closeButtonPosition = "inside",
  ...props
}) {
  const [isOpen, setIsOpen] = useContext(ModalContext)
  return (
    <DialogOverlay
      layout={layout}
      isOpen={isOpen}
      onDismiss={() => setIsOpen(false)}
      {...props}
    >
      {closeButtonPosition === "outside" && (
        <ModalDismissButton>
          <Close position={closeButtonPosition}>
            <span>Close</span>
            <Icon name={"cross"} />
          </Close>
        </ModalDismissButton>
      )}
      <DialogContent layout={layout}>
        {closeButtonPosition === "inside" && (
          <ModalDismissButton>
            <Close position={closeButtonPosition}>
              <Icon name={"cross"} />
            </Close>
          </ModalDismissButton>
        )}
        {children}
      </DialogContent>
    </DialogOverlay>
  )
}

export {
  Modal,
  ModalExternal,
  ModalDismissButton,
  ModalOpenButton,
  ModalContents,
  ModalContentsWithLayout,
}
