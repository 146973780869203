import tw from "twin.macro"

export const Wrapper = tw.div`
  flex flex-row justify-between mlg:justify-start items-center
`

export const Title = tw.h3`
  text-14 leading-1.71 tracking-10 font-bold uppercase mr-2-4
`

export const List = tw.ul`
  list-none flex flex-row items-center
`

export const ListItem = tw.li`

`
