import React from "react"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Icon } from "../../../Icon/Icon"
import {
  Wrapper,
  SliderWrapper,
  Prev,
  Next,
  StyledSwatch,
  SliderInnerWrapper,
} from "./CardSwatchesStyles"

SwiperCore.use([Navigation])

export const DesktopCardSwatches = ({
  activeProductHandle,
  defaultProductHandle,
  swatches,
  setActiveProductHandle,
  setHoverActiveProductHandle,
}) =>
  swatches.length > 7 ? (
    <SliderWrapper>
      <SliderInnerWrapper>
        <Swiper
          className={`${defaultProductHandle}-swatch-swiper`}
          id={`${defaultProductHandle}-swatch-swiper`}
          slidesPerView={7}
          spaceBetween={0}
          navigation={{
            nextEl: `#${defaultProductHandle}-next`,
            prevEl: `#${defaultProductHandle}-prev`,
          }}
        >
          {swatches?.map(({ title, handle, swatchImage }, idx) => (
            <SwiperSlide key={idx + handle}>
              <StyledSwatch
                isSlider
                title={title}
                image={swatchImage}
                active={activeProductHandle === handle}
                onClick={() => setActiveProductHandle(handle)}
                onMouseEnter={() => setHoverActiveProductHandle(handle)}
                onMouseLeave={() =>
                  setHoverActiveProductHandle(activeProductHandle)
                }
              />
            </SwiperSlide>
          ))}
        </Swiper>
      </SliderInnerWrapper>
      <Prev id={`${defaultProductHandle}-prev`}>
        <Icon name={"arrowLeft"} />
      </Prev>
      <Next id={`${defaultProductHandle}-next`}>
        <Icon name={"arrow"} />
      </Next>
    </SliderWrapper>
  ) : (
    <Wrapper>
      {swatches?.map(({ title, handle, swatchImage }, idx) => (
        <StyledSwatch
          isSlider={false}
          key={idx + handle}
          title={title}
          image={swatchImage}
          active={activeProductHandle === handle}
          onClick={() => setActiveProductHandle(handle)}
          onMouseEnter={() => setHoverActiveProductHandle(handle)}
          onMouseLeave={() => setHoverActiveProductHandle(activeProductHandle)}
        />
      ))}
    </Wrapper>
  )
