import React from "react"

import { List, ListItem, ListItemWrapper } from "./NavigationHeaderDesktopMainStyles"
import { Link } from "../../../../Link/Link"
import { withNavigationHeaderDesktopMain } from "./withNavigationHeaderDesktopMain"
import { NavigationHeaderDesktopDropdown } from "./Dropdown/NavigationHeaderDesktopDropdown"

export const NavigationHeaderDesktopMain = withNavigationHeaderDesktopMain(
  ({ items, handleHover, activeItem, onLinkPage }) => (
      <List>
        {items?.map(item => (
          <ListItemWrapper
            key={item?.title}
            onMouseEnter={handleHover(item.title)}
            onMouseLeave={handleHover("")}
            onClick={handleHover("")}
            data-hj-ignore-attributes
          >
            <ListItem
              active={onLinkPage(item) || activeItem === item.title}
              data-hj-ignore-attributes
            >
              <Link
                title={item?.title || item?.link?.title}
                url={item?.url || item?.link?.url}
                className={"whitespace-nowrap"}
                external={item?.external || item?.link?.external}
                isExternal={item?.isExternal || item?.link?.isExternal}
                trackingPrefix={"navigation_header_desktop_level_1"}
                color={item?.color || item?.link?.color}
              />
              {item.items?.length > 0 ? (
                <>
                  <NavigationHeaderDesktopDropdown
                    item={item}
                    active={activeItem === item.title}
                  />
                </>
              ) : null}
            </ListItem>
          </ListItemWrapper>
        ))}
      </List>
  ),
)
