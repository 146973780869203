import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Outer = tw.div`
  px-2-4 md:px-7-5 pt-2-4 pb-6
`

export const Inner = tw.div`
  grid grid-cols-2 mt-2-4
`

export const List = tw.div`
  flex flex-col mb-2-4
`

export const ListItem = tw.div`
  font-bold text-13 leading-1.53 uppercase mb-1
`

export const SubList = tw.div``

export const SubListItem = tw.div`
  text-13 font-normal leading-1.37 mb-0-8 text-grey
`

export const AdditionalLinksWrapper = tw.div`
  
`

export const AdditionalLink = styled(Link)`
  ${tw`w-full bg-grey-light flex flex-row items-center justify-center py-1-2 my-0-8`}
  p {
    ${tw`text-grey text-16 leading-1.62 ml-0-8`}
  }
`
