import React from "react"
import { Animate } from "react-show"

export const AccordionAnimation = ({ active, children }) => (
  <Animate
    show={active}
    transitionOnMount
    preMount
    stayMounted
    style={{
      height: "auto",
      display: "block",
      overflow: "hidden",
    }}
    start={{
      height: 0,
      display: "block",
    }}
  >
    {children}
  </Animate>
)
