import { useState, useCallback } from "react"
import { navigate, useStaticQuery, graphql } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"

const INITIAL_STATE = {
  password: "",
}

export const useMaintenance = location => {
  const {
    config: {
      settings: { keys, params, routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser, decodeBase64, encodeBase64, storage },
  } = useCore()
  const { getUrlParameter } = useRoutes()

  const { maintenance } =  useStaticQuery(graphql`
    query MAINTENANCE_SANITY_SETTINGS {
      maintenance: sanitySettingMaintenance {
        password
        enabled
      }
    }`)

  const { enabled, password } = maintenance

  const [error, setError] = useState(false)
  const [data, setData] = useState(INITIAL_STATE)

  const saved = storage.get(keys?.password)
  const authorised = !enabled || (saved && password === decodeBase64(saved))
  const active = location.pathname.startsWith(routes.PASSWORD)
  const navigateToSite = useCallback(() => navigate(`${getUrlParameter(params?.continue) || routes.HOMEPAGE}`, { replace: true }), [
    getUrlParameter,
    params?.continue,
    routes.HOMEPAGE,
  ])

  if (isBrowser) {
    if (enabled) {
      storage.set(keys?.maintenance, enabled)
      if (!authorised && !active)
        navigate(`${routes.PASSWORD}${location.pathname !== routes.HOMEPAGE ? `?${params?.continue}=${location.pathname}` : ``}`, {
          replace: true,
        })
    }

    if ((active && !enabled) || (active && enabled && authorised)) {
      storage.remove(keys?.maintenance)
      navigateToSite()
    }
  }

  const validatePassword = useCallback(() => {
    if (password === data?.password) {
      storage.set(keys?.password, encodeBase64(data?.password))
      setData(INITIAL_STATE)
      setError(false)
      navigateToSite()
    } else {
      storage.remove(keys?.password)
      setData(INITIAL_STATE)
      setError(true)
    }
  }, [keys?.password, navigateToSite, storage, encodeBase64, setData, setError, password, data])

  return { active, authorised, error, data, setData, validatePassword }
}
