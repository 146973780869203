import React from "react"
import { ImageVideo } from "../../../ImageVideo/ImageVideo"
import { withHeroCard } from "./withHeroCard"
import { Outer, Inner, Content } from "./HeroCardStyles"
import { TextButtonsGrid } from "../../../TextButtonsGrid/TextButtonsGrid"
import { Link } from "gatsby"

export const HeroCard = withHeroCard(
  ({
    imageVideo,
    content,
    left,
    top,
    mobileLeft,
    mobileTop,
    ratio,
    className,
    link,
    handlePromoClick,
  }) => (
    <Outer className={className} onClick={handlePromoClick}>
      <ImageVideo imageVideo={imageVideo} ratio={ratio} />
      {!content ? null : (
        <Inner
          as={link?.url && (link?.isExternal ? `a` : Link)}
          href={link?.isExternal && link?.url}
          target={link?.external && `_blank`}
          title={link?.title}
          to={!link?.isExternal && link?.url}
        >
          <Content
            left={left}
            top={top}
            mobileLeft={mobileLeft}
            mobileTop={mobileTop}
          >
            <TextButtonsGrid content={content} />
          </Content>
        </Inner>
      )}
    </Outer>
  ),
)
