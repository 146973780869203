import tw from "twin.macro"

export const Wrapper = tw.div`
  pt-1-8 pb-1-2
`

export const Title = tw.h3`
  text-20 leading-1.5 uppercase tracking-10 font-semibold text-center mb-2-4 md:mb-3-2
`

export const CardList = tw.div`
  flex flex-wrap items-center justify-center -mx-1-2 mt-2
`

export const CardWrapper = tw.div`
  w-full md:w-1/3 p-1-2
`
