import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"

export const withHeadOffice = Component => ({ name = "HeadOffice" }) => {
  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_OFFICE {
      footer: sanityTemplateGlobal {
        headOffice: _rawHeadOffice(resolveReferences: { maxDepth: 2 })
        contact: _rawContact(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const { headOffice, contact } = footer || {}

  const { linkResolver } = useRoutes()

  const { address, number, postcode, state, suburb, title } = headOffice || {}

  const { title: contactTitle, url } = linkResolver(contact) || {}

  Component.displayName = name
  return (
    <Component
      address={address}
      number={number}
      postcode={postcode}
      state={state}
      suburb={suburb}
      title={title}
      contactTitle={contactTitle}
      url={url}
    />
  )
}
