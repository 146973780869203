import React, { useState, useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"

import { globalHistory } from '@reach/router'
import { useLocation } from "../../../../../hooks/useLocation"

export const withNavigationHeaderDesktopMain = Component => ({
  name = "NavigationHeaderDesktopMain",
}) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()

  const { location } = useLocation()

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_DESKTOP_MAIN_MENU {
      menus: sanitySettingMenus {
        desktopHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              desktopHeader: _rawDesktopHeader(
                resolveReferences: { maxDepth: 6 }
              )
            }
          }
        }
      }
    }
  `)

  const { desktopHeaderMain } = menus || {}

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: {
                  items: item?.promotion?.desktopHeader?.items,
                },
              }
            : result,
        {},
      )
    : {}

  const saleName = getOnSale()
  const saleNameSuffix = location === "NZ" ? `-${location?.toLocaleLowerCase()}` : ""
  const rawItems =
    (saleName && promotions[saleName + saleNameSuffix]?.items) ||
    (saleName && promotions[saleName]?.items) ||
    desktopHeaderMain?.items
  const items = useMemo(() => mapItems(rawItems), [rawItems, mapItems])

  const [activeItem, setActiveItem] = useState("")

  const handleHover = value => () => {
    setActiveItem(value)
  }

  const [href, setHref] = React.useState(typeof window !== 'undefined' && window.location.href);
  
  const listenToLocation = () => {
    if (typeof window !== 'undefined') {
      const winHref = window?.location?.href;
      setHref(winHref);
    }
  };

  React.useEffect(() => {
    globalHistory.listen(listenToLocation);
  }, []);

  const onLinkPage = useMemo(() => (link) => {
    const url = link?.url || link?.link?.url
    if (href && href.includes(url)) {
      return true
    } else {
      return false
    }
  }, [href])

  Component.displayName = name
  return (
    <Component
      items={items}
      activeItem={activeItem}
      onLinkPage={onLinkPage}
      handleHover={handleHover}
    />
  )
}
