import React, { useMemo, useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useShopify } from "../../../../../hooks/useShopify"
import { useSale } from "../../../../../hooks/useSale"
import { useNavigation } from "../../../../../hooks/useNavigation"
import { useLocation } from "../../../../../hooks/useLocation"

export const withNavigationHeaderMobileMain = Component => ({
  name = "NavigationHeaderMobileMain",
}) => {
  const { mapItems } = useNavigation()
  const { getOnSale } = useSale()
  const { edgeNormaliser } = useShopify()
  const { location } = useLocation()

  const [activeItem, setActiveItem] = useState("")

  const { menus, promotions: rawPromotions } = useStaticQuery(graphql`
    query SANITY_SETTINGS_MOBILE_MAIN_MENU {
      menus: sanitySettingMenus {
        mobileHeaderMain {
          items: _rawItems(resolveReferences: { maxDepth: 10 })
        }
      }
      promotions: allSanityPromotion {
        edges {
          node {
            title
            slug {
              current
            }
            promotion {
              mobileHeader: _rawMobileHeader(
                resolveReferences: { maxDepth: 10 }
              )
            }
          }
        }
      }
    }
  `)

  const { mobileHeaderMain } = menus || {}

  const promotions = rawPromotions
    ? edgeNormaliser(rawPromotions).reduce(
        (result, item) =>
          item?.slug?.current
            ? {
                ...result,
                [item.slug.current]: {
                  items: item?.promotion?.mobileHeader?.items,
                },
              }
            : result,
        {},
      )
    : {}
  const saleName = getOnSale()
  const saleNameSuffix = location === "NZ" ? `-${location?.toLocaleLowerCase()}` : ""
  const rawItems =
    (saleName && promotions[saleName + saleNameSuffix]?.items) ||
    (saleName && promotions[saleName]?.items) ||
    mobileHeaderMain?.items
  const items = useMemo(() => mapItems(rawItems), [rawItems, mapItems])

  Component.displayName = name
  return (
    <Component
      items={items}
      activeItem={activeItem}
      setActiveItem={setActiveItem}
    />
  )
}
