import React from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withFooterCopyright = Component => ({
  name = "FooterCopyright",
}) => {
  const { footer } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_FOOTER_COPYRIGHT {
      footer: sanityTemplateGlobal {
        footerCopyrightText
      }
    }
  `)

  const { footerCopyrightText } = footer || {}

  const currentYear = new Date().getFullYear()

  const copy = footerCopyrightText?.replace("${year}", currentYear)

  Component.displayName = name
  return <Component copy={copy} />
}
