import { useCallback, useContext } from "react"

import { TrackingContext } from "../providers/tracking"

import { useCore } from "./useCore"
import { useShopify } from "./useShopify"
import { useCheckoutContext } from "./useCheckout"
import { useHotjar } from "./useHotjar"

export const useAnalytics = () => {
  const { setTracked, gtm, VisibilitySensor } = useContext(TrackingContext)
  const { trackHotjarEvent } = useHotjar()

  const {
    helpers: { decodeShopifyId, isDomReady },
  } = useCore()

  const { checkout } = useCheckoutContext()
  const { productNormaliser } = useShopify()

  const formatPrice = price =>
    parseFloat(price?.replace(/,/g, "."))?.toFixed(2) || 0

  const getVariantOptionValue = (options, selectedName) =>
    options?.find(
      ({ name }) => name?.toLowerCase() === selectedName?.toLowerCase(),
    )?.value

  const trackPageView = useCallback(() => {
    setTimeout(() => {
      // console.log(`[TRACKING] pageView (${document.title}) fired!`)
      gtm.dataLayer({
        dataLayer: {
          event: "Pageview",
          pagePath: document.location.pathname,
          pageTitle: document.title,
        },
        // auth: "Hge-o4A5REHtobb-sbKdsQ", // for environments
        // preview: "env-3", // for environments
      })
    }, 200)
  }, [gtm])

  const trackProductImpression = useCallback(
    async (product, position, list = null) => {
      const {
        collections,
        id,
        title,
        vendor,
        priceRange,
        price_min,
        product_type,
      } = productNormaliser(product)
      if (title) {
        // console.log(`[TRACKING] productImpression (${title} ${list}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productImpression",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              impressions: [
                {
                  id: decodeShopifyId(id, "Product"),
                  name: title,
                  brand: vendor,
                  category: collections?.[0]?.title || product_type,
                  price: formatPrice(
                    priceRange?.minVariantPrice?.amount || price_min,
                  ),
                  list: list || "Collection Results", // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                  position,
                },
              ],
            },
          },
        })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId],
  )

  const trackProductClick = useCallback(
    async (product, variant, position, list = null) => {
      const {
        collections,
        id,
        priceRange,
        price_min,
        title,
        vendor,
        product_type,
      } = productNormaliser(product)
      if (title) {
        // track hotjar
        trackHotjarEvent("product_click")

        // console.log(`[TRACKING] productClick (${title} – ${variant?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "productClick",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              click: {
                actionField: { list: list || "Collection Results" }, // Product Page, Collection Results, Instant Search, Search Results, Featured Products, Related Products, Cart
                products: [
                  {
                    id: decodeShopifyId(id, "Product"),
                    name: title,
                    brand: vendor,
                    category: collections?.[0]?.title || product_type,
                    price: formatPrice(
                      priceRange?.minVariantPrice?.amount || price_min,
                    ),
                    position,
                    variant: getVariantOptionValue(
                      variant?.selectedOptions,
                      `Colour`,
                    ),
                    // dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                    // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId, trackHotjarEvent],
  )

  const trackProductView = useCallback(
    async (product, variant, list = null) => {
      const { collections, id, productType, title, vendor } =
        productNormaliser(product)
      if (title) {
        const shopifyProduct = JSON.parse(product.shopify.raw)
        try {
          // console.log(`[TRACKING] productView (${title} – ${variant?.title}) fired!`)
          gtm.dataLayer({
            dataLayer: {
              event: "productDetail",
              ecommerce: {
                currencyCode: checkout?.currencyCode,
                detail: {
                  actionField: { list: list || "Product Page" }, // Product Page, Quick View
                  products: [
                    {
                      id: decodeShopifyId(shopifyProduct.id, "Product"),
                      name: title,
                      brand: vendor,
                      category: collections?.[0]?.title || productType,
                      price: formatPrice(
                        variant?.priceV2?.amount ||
                          (shopifyProduct?.variants &&
                            shopifyProduct?.variants[0]?.priceV2),
                      ),
                      priceExTax: formatPrice(
                        variant?.priceV2?.amount ||
                          (shopifyProduct?.variants &&
                            shopifyProduct?.variants[0]?.priceV2),
                      ),
                      variant: getVariantOptionValue(
                        variant?.selectedOptions,
                        `Colour`,
                      ),
                      // dimension2: getVariantOptionValue(variant?.selectedOptions, `Size`),
                      // dimension3: variant?.availableForSale ? `In Stock` : `Out of Stock`,
                    },
                  ],
                },
              },
            },
          })
        } catch (error) {
          console.error("Caught error tracking productView", error)
        }
      }
    },
    [gtm, checkout, productNormaliser, decodeShopifyId],
  )

  const trackCartUpdate = useCallback(
    async (type, variantId, quantity, lineitems) => {
      const selectedLineItem = lineitems?.filter(
        ({ variant }) => variant?.id === variantId,
      )[0]
      if (selectedLineItem?.title) {
        // track hotjar
        type === "add"
          ? trackHotjarEvent("add_to_cart")
          : trackHotjarEvent("remove_from_cart")

        // console.log(`[TRACKING] cartUpdate (${type} – ${selectedLineItem?.title}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: type === "add" ? "addToCart" : "removeFromCart",
            ecommerce: {
              currencyCode: checkout?.currencyCode,
              [type]: {
                products: [
                  {
                    id: decodeShopifyId(variantId, "ProductVariant"),
                    name: selectedLineItem?.title,
                    brand: selectedLineItem?.variant?.product?.vendor,
                    category: selectedLineItem?.variant?.product?.productType,
                    price: formatPrice(
                      selectedLineItem?.variant?.priceV2?.amount,
                    ),
                    quantity,
                    variant: getVariantOptionValue(
                      selectedLineItem?.variant?.selectedOptions,
                      `Colour`,
                    ),
                    // dimension2: getVariantOptionValue(selectedLineItem?.variant?.selectedOptions, `Size`),
                    // dimension3: selectedLineItem?.variant?.availableForSale ? `In Stock` : `Out of Stock`,
                  },
                ],
              },
            },
          },
        })
      }
    },
    [gtm, checkout, decodeShopifyId, trackHotjarEvent],
  )

  const trackPromoImpression = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        // console.log(`[TRACKING] promoImpression (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionView",
            ecommerce: {
              promoView: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm],
  )

  const trackPromoClick = useCallback(
    async ({ analyticsId: id, name, creative, position }) => {
      if (name) {
        // console.log(`[TRACKING] promoClick (${name}) fired!`)
        gtm.dataLayer({
          dataLayer: {
            event: "promotionClick",
            ecommerce: {
              promoClick: {
                promotions: [{ id, name, creative, position }],
              },
            },
          },
        })
      }
    },
    [gtm],
  )

  const trackEvent = useCallback(async () => {
    if (checkout?.currencyCode && isDomReady) {
      await setTracked(true)
      await trackPageView()
    }
  }, [checkout, setTracked, trackPageView, isDomReady])

  return {
    trackEvent,
    trackPageView,
    trackProductImpression,
    trackProductView,
    trackProductClick,
    trackCartUpdate,
    trackPromoImpression,
    trackPromoClick,
    VisibilitySensor,
  }
}
