import React, { useCallback } from "react"
import { useApp } from "../../../../hooks/useApp"

export const withQuickViewSwatches = Component => ({
  products,
  activeProductHandle,
  setActiveProductHandle,
  name = "QuickViewSwatches",
}) => {
  const {
    config: {
      settings: {
        tags: { swatchColor },
      },
    },
  } = useApp()

  const getSwatchColour = useCallback(
    tags => {
      const found = tags.find(tag => {
        const ret = tag.includes(swatchColor)
        return ret
      })
      const split = found?.split(":")
      const ret = split?.[split?.length-1]
      return ret?.replace(/\//g, "")
    },
    [swatchColor],
  )

  const swatches = products.map(product => ({
    ...product,
    swatchImage: `https://res.cloudinary.com/propel-group/RB%20SELLARS%20Swatch/${getSwatchColour(
      product.tags,
    )}`,
  }))

  Component.displayName = name
  return (
    <Component
      swatches={swatches}
      activeProductHandle={activeProductHandle}
      setActiveProductHandle={setActiveProductHandle}
    />
  )
}
