import React from "react"

import { List, ListItem } from "./DropdownHeroCardListStyles"
import { HeroCard } from "../../../../../../Sections/Hero/Card/HeroCard"

export const DropdownHeroCardList = ({ items }) => (
  <List>
    {items.map(item => (
      <ListItem key={item?._key}>
        <HeroCard card={item} />
      </ListItem>
    ))}
  </List>
)
