import React, {
  useEffect,
  createContext,
  useState,
  useContext,
  useCallback,
} from "react"
import { default as firebaseLib } from "firebase/app"
import "firebase/auth"
import "firebase/firestore"

import { useCore } from "../hooks/useCore"
import { useWindowLoad } from "../hooks/useWindowLoad"

export const FirebaseContext = createContext({
  firebase: null,
  app: null,
  auth: null,
  user: null,
  providers: null,
  isInitialized: false,
  sale: { enabled: false, name: null },
})

export const useFirebaseContext = () => {
  const data: any = useContext(FirebaseContext)
  return { ...data }
}

export const FirebaseProvider = ({ children, config }) => {
  const {
    helpers: { isBrowser },
  } = useCore()

  const { waitForGlobal } = useWindowLoad()
  const [firebase, setFirebase] = useState(null)
  const [firebaseApp, setFirebaseApp] = useState(null)
  const [firebaseDB, setFirebaseDB] = useState(null)
  const [firebaseAuth, setFirebaseAuth] = useState(null)
  const [firebaseAuthProviders, setFirebaseAuthProviders] = useState({
    google: undefined,
    facebook: undefined,
  })
  const [firebaseAuthUser, setFirebaseAuthUser] = useState(null)
  const [isFirebaseInitialized, setIsFirebaseInitialized] = useState(false)
  const [sale, setSale] = useState({ enabled: false, name: null })

  const initFirebase = useCallback(() => {
    const development = process.env.NODE_ENV === "development"

    if (firebaseLib) {
      const { apiKey, authDomain, databaseURL, projectId } = config
      const app =
        firebaseLib.apps?.[0] ||
        firebaseLib.initializeApp({
          apiKey,
          authDomain,
          databaseURL,
          projectId,
        })
      setFirebase(firebaseLib)
      setFirebaseApp(app)
      setIsFirebaseInitialized(true)
    }

    if (firebaseLib.auth) {
      const auth = firebaseLib.auth()
      setFirebaseAuth(auth)

      const providers = {
        google: new firebaseLib.auth.GoogleAuthProvider(),
        facebook: new firebaseLib.auth.FacebookAuthProvider(),
      }
      setFirebaseAuthProviders(providers)

      firebaseLib.auth().onAuthStateChanged(user => {
        if (user) {
          setFirebaseAuthUser(user)
        } else {
          setFirebaseAuthUser(null)
        }
      })
    }

    if (firebaseLib.firestore) {
      const db = firebaseLib.firestore()
      setFirebaseDB(db)
      if (development) firebaseLib.firestore().useEmulator("localhost", 8080)
    }
  }, [config])

  useEffect(() => {
    waitForGlobal("firebase", initFirebase)
  }, [initFirebase, waitForGlobal])

  useEffect(() => {
    if (firebaseDB)
      firebaseDB
        .collection("settings")
        .doc("promotion")
        .onSnapshot(doc => setSale(doc.data()))
  }, [firebaseDB])

  return isBrowser ? (
    <FirebaseContext.Provider
      value={{
        firebase,
        app: firebaseApp,
        auth: firebaseAuth,
        user: firebaseAuthUser,
        providers: firebaseAuthProviders,
        isInitialized: isFirebaseInitialized,
        sale,
      }}
    >
      {children}
    </FirebaseContext.Provider>
  ) : (
    <>{children}</>
  )
}
