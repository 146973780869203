import { Results, useResults, useSearch } from "@usereactify/search"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { InstantSearchResultsTop } from "./InstantSearchResultsTop"
import { InstantSearchResultsBottom } from "./InstantSearchResultsBottom"
import { InstantSearchNoResults } from "./InstantSearchNoResults"
import { ResultsWrapper, Wrapper } from "./InstantSearchStyles"
import { useShopify } from "../../../../hooks/useShopify"
import { SearchList } from "../../List/SearchList"

export const InstantResults = () => {
  const { searchTerm } = useSearch()
  const includeFields = ["collections", "handle", "images", "presentment_price_ranges", "product_type", "tags", "variants", "price_min", "price_max" ]
  const excludeFields = ["link"]
  const { results } = useResults()
  const { getProducts } = useShopify()
  const [ prices, setPrices ] = useState([])
  const handles = useMemo(() => results?.map((result) => result?.handle), [results])

  useEffect(() => {
    const fetchPrices = async () => {
      return await getProducts({
        handles,
        firstVariants: 1,
        firstImages: 0,
        firstCollections: 0,
        firstMedia: 0
      }).then(res => {
        const variants = res?.map((product) => product?.variants?.map(variant => ({
          id: product?.id,
          priceV2: variant?.priceV2,
          compareAtPriceV2: variant?.compareAtPriceV2
        }))).flat()

        setPrices(variants)
      })
    }
    fetchPrices()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [results])

  const memoizedRenderResultCardProduct = useCallback((props) => (
    <SearchList
      {...props}
      prices={prices}
      hideQuickView={true}
    />
  ), [prices])
  
  return searchTerm?.length > 0 ? (
    <Wrapper>
      <InstantSearchResultsTop data={results} />
      <ResultsWrapper>
        <Results
          includeFields={includeFields}
          excludeFields={excludeFields}
          listClassName="instant-search-results"
          pageSize={4}
          renderError={() => <div />}
          renderLoading={() => <div />}
          listStyle={{}}
          renderNoResults={() => <InstantSearchNoResults />}
          renderResultCardProduct={memoizedRenderResultCardProduct}
        />
      </ResultsWrapper>
      <InstantSearchResultsBottom />
    </Wrapper>
  ) : null
}
