import React from "react"

import { useApp } from "../../../hooks/useApp"
import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withSearchWidget = Component => ({ name = "SearchWidget" }) => {
  const { globalState } = useApp()

  const {
    template: {
      global: { additionalSearchText },
    },
  } = getAdditionalText()

  const [, dispatch] = globalState

  const handleClick = () => {
    dispatch({
      type: "SHOW_SEARCH",
    })
  }
  Component.displayName = name
  return <Component handleClick={handleClick} text={additionalSearchText} />
}
