import React from "react"

import {
  Wrapper,
  Back,
  StyledIcon,
  ShopAll,
  List,
  ListItem,
  SubListItem,
  ImagesWrapper,
} from "./NavigationHeaderMobileSubListStyles"

import { Link } from "../../../../../Link/Link"
import { ImageVideo } from "../../../../../ImageVideo/ImageVideo"
import { RichText } from "../../../../../RichText/RichText"
import { UnderlinedButton } from "../../../../../Styled/Button"

export const NavigationHeaderMobileSubList = ({ item, setActiveItem }) => {
  return (
    <Wrapper>
      <Back onClick={() => setActiveItem("")}>
        <StyledIcon name={"arrowLeft"} />
        Back to main menu
      </Back>
      {Boolean(item?.shopAll?.title) && (
        <ShopAll>
          <UnderlinedButton size={"primary"}>
            <span>
              <Link
                title={item?.shopAll?.title}
                url={item?.shopAll?.url}
                trackingPrefix={"navigation_header_mobile_level_2"}
                external={item?.shopAll?.external}
                isExternal={item?.shopAll?.isExternal}
              />
            </span>
          </UnderlinedButton>
        </ShopAll>
      )}
      <div>
        {item.items.map(item =>
          item?.type === "subimages" ? (
            <List key={item._key}>
              <ListItem>
                <Link
                  title={item?.title}
                  url={item?.url}
                  trackingPrefix={"navigation_header_mobile_level_2"}
                  external={item?.external}
                  isExternal={item?.isExternal}
                />
              </ListItem>
              <ImagesWrapper>
                {item?.items?.map(item => (
                  <div key={item?._key}>
                    <ImageVideo imageVideo={item?.image} />
                    <RichText>{item?.content}</RichText>
                  </div>
                ))}
              </ImagesWrapper>
            </List>
          ) : (
            <List key={item._key}>
              <ListItem>
                <Link
                  title={item?.title}
                  url={item?.url}
                  trackingPrefix={"navigation_header_mobile_level_2"}
                  external={item?.external}
                  isExternal={item?.isExternal}
                />
              </ListItem>
              {item?.items?.map(item => (
                <SubListItem key={item?.title}>
                  <Link
                    title={item?.title}
                    url={item?.url}
                    trackingPrefix={"navigation_header_mobile_level_2"}
                    external={item?.external}
                    isExternal={item?.isExternal}
                  />
                </SubListItem>
              ))}
            </List>
          ),
        )}
      </div>
    </Wrapper>
  )
}
