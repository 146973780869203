import React, { useState } from "react"

export const withQuickViewImages = Component => ({
  media,
  name = "QuickViewImages",
}) => {
  const [thumbsSwiper, setThumbsSwiper] = useState(null)

  Component.displayName = name
  return (
    <Component
      media={media}
      thumbsSwiper={thumbsSwiper}
      setThumbsSwiper={setThumbsSwiper}
    />
  )
}
