import React from "react"

import { withRichText } from "./withRichText"
import { RichTextContent } from "../Styled/Text"

export const RichText = withRichText(
  ({ children, align, spacing, className }) => (
    <RichTextContent className={className} align={align} spacing={spacing}>
      {children}
    </RichTextContent>
  ),
)
