import React, { memo, useMemo } from "react"

import { withSearchList } from "./withSearchList"
import { SearchProductCard } from "../../Product/Card/SearchProduct/SearchProductCard"
import {
  CalloutButtons,
  CalloutContent,
  CalloutWrapper,
} from "./SearchListStyles"
import { Image } from "../../Image/Image"
import { useWindowSize } from "../../../hooks/useWindowSize"
import { Button, Buttons } from "../../TextButtonsGrid/TextButtonsGridStyles"
import { H2, H4, H5, LargeBody } from "../../Styled/Text"
import { Link } from "gatsby"

const SearchListComponent = ({
  product,
  prices,
  collectionsObjForBreadCrumbs,
  handleClick,
  hideQuickView,
}): JSX.Element => {
  const price = useMemo(
    () =>
      prices?.find(
        price => price?.id == `gid://shopify/Product/${product?.id}`,
      ),
    [prices, product?.id],
  )

  return (
    <div key={product.id} onClick={handleClick}>
      <SearchProductCard
        product={product}
        hideQuickView={hideQuickView || product.hideQuickView}
        collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
        price={price}
        isPriceLoaded={!!price}
      />
    </div>
  )
}

export const SearchList = withSearchList(memo(SearchListComponent))

const SearchListCalloutComponent = ({ callout }): JSX.Element => {
  const { windowSize } = useWindowSize()
  const mediaToShow = useMemo(
    () =>
      !windowSize.width
        ? "unknown"
        : windowSize.width <= 1024
        ? callout?.mobileImage || callout?.desktopImage
        : callout?.desktopImage,
    [windowSize, callout],
  )

  const parsedButtons = useMemo(() => {
    if (callout?.link) return null
    if (!callout?.links) return null
    return callout?.links.map(link => ({
      key: link?.key,
      link: {
        isExternal: false,
        external: false,
        url: link?.url,
        title: link?.content,
      },
    }))
  }, [callout])

  return (
    <CalloutWrapper
      key={callout?.id}
      as={callout?.link ? Link : "div"}
      backgroundColor={callout?.backgroundColor}
      className={`mlg:col-span-${callout?.displayColumns} mlg:row-span-${callout?.displayRows}`}
      to={callout?.link}
      visibility={callout?.visibility}
    >
      {mediaToShow && <Image fullHeight={true} image={{ src: mediaToShow }} />}
      <CalloutContent textColor={callout?.textColor}>
        {callout?.title && <H5>{callout?.title}</H5>}
        {callout?.content && <LargeBody>{callout?.content}</LargeBody>}
        {parsedButtons && (
          <CalloutButtons>
            {parsedButtons.map(link => (
              <Button key={link?.key} linkOverride={link?.link} />
            ))}
          </CalloutButtons>
        )}
      </CalloutContent>
    </CalloutWrapper>
  )
}

export const Callout = memo(SearchListCalloutComponent)
