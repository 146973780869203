import React from "react"
import BlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import tw, { styled, css } from "twin.macro"

import { useRoutes } from "../../hooks/useRoutes"
import { useCore } from "../../hooks/useCore"
import { ImageVideo } from "../ImageVideo/ImageVideo"
import { Table } from "../Table/Table"
import { Accordion } from "../Accordion/Accordion"
import {
  H1,
  H2,
  H3,
  H4,
  H5,
  H6,
  SubTitle,
  XXLargeBody,
  XLargeBody,
  LargeBody,
  Body,
  SmallBody,
  TinyBody,
} from "../Styled/Text"
import { StyledOl, StyledUl, StyledLi } from "../Styled/List"
import { RichText } from "./RichText"

const CustomLink = styled.span`
  ${({ fontColor }) =>
    fontColor
      ? css`
          color: ${fontColor};
        `
      : null}
`
const CustomImage = tw.img`mx-auto`
const CustomAlignRightSpan = tw.span`block md:text-right`
const CustomAlignCenterSpan = tw.span`block text-center`

const Components = {
  h1: H1,
  h2: H2,
  h3: H3,
  h4: H4,
  h5: H5,
  h6: H6,
  subtitle: SubTitle,
  xxLargeBody: XXLargeBody,
  xLargeBody: XLargeBody,
  largeBody: LargeBody,
  normal: Body,
  smallBody: SmallBody,
  tinyBody: TinyBody,
}

const BlockComponents = props => {
  const { style } = props.node || {}

  const Component = Components[style]

  return Component ? (
    <Component>{props.children}</Component>
  ) : (
    BlockContent.defaultSerializers.types.block(props)
  )
}

export const withRichText = Component => ({
  name = "RichText",
  children,
  ...props
}) => {
  const { linkResolver } = useRoutes()

  const {
    helpers: { handleize },
  } = useCore()

  const serializers = {
    marks: {
      link: ({ children, mark }) => {
        const link = linkResolver(mark.link)
        const { color } = mark
        return (
          <CustomLink
            as={link?.url && (link?.isExternal || link?.external ? `a` : Link)}
            href={(link?.isExternal || link?.external) && link?.url}
            target={link?.external && `_blank`}
            title={link?.title}
            to={!link?.isExternal && link?.url}
            fontColor={color?.hex}
          >
            {children}
          </CustomLink>
        )
      },
      alignCenter: ({ children }) => {
        return <CustomAlignCenterSpan>{children}</CustomAlignCenterSpan>
      },
      alignRight: ({ children }) => {
        return <CustomAlignRightSpan>{children}</CustomAlignRightSpan>
      },
      color: ({ children, mark }) => {
        return (
          <span style={{ color: `${mark?.hex ? mark?.hex : "inherit"}` }}>
            {children}
          </span>
        )
      },
    },
    list: ({ children, type }) => {
      return type === `number` ? (
        <StyledOl>{children}</StyledOl>
      ) : (
        <StyledUl>{children}</StyledUl>
      )
    },
    listItem: ({ children, node }) => {
      return (
        <StyledLi level={node?.level} listItem={node?.listItem}>
          {children}
        </StyledLi>
      )
    },
    types: {
      code: ({ node }) => (
        <div dangerouslySetInnerHTML={{ __html: node.code }} />
      ),
      imageVideoResponsive: ({ node }) => <ImageVideo imageVideo={node} />,
      customImage: ({ node }) => (
        <CustomImage src={node?.asset?.url} alt={node?.alt} />
      ),
      block: BlockComponents,
      customPdf: ({ node }) => {
        
        const string = node?.asset?.originalFilename?.split(`.`)?.[0] ?? ""
        
        const name = `${handleize(
          typeof string === "string" ? string : "",
        )}.${node?.asset?.extension}`
        const link = (
          <a
            className={"underline"}
            href={`${node?.asset?.url}/${name}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            {node?.title ?? node?.asset?.originalFilename}
          </a>
        )
        
        if (node?.alignment === "center") {
          return (
            <div className={"text-center"}>
              {link}
            </div>
          )
        } else if (node?.alignment === "right") {
          return (
            <div className={"text-right"}>
              {link}
            </div>
          )
        }
        
        return link
        
      },
      table: ({ node }) => <Table chart={node} />,
      accordion: ({ node }) => (
        <Accordion layout={"faq"} title={node.question}>
          <RichText spacing={"default"}>{node.answer}</RichText>
        </Accordion>
      ),
    },
  }

  Component.displayName = name
  return (
    <Component {...props}>
      <BlockContent blocks={children || []} serializers={serializers} />
    </Component>
  )
}
