import React from "react"

import { withPaymentIcons } from "./withPaymentIcons"
import { List, ListItem, PaymentIcon } from "./PaymentIconsStyles"

export const PaymentIcons = withPaymentIcons(({ icons }) => (
  <List>
    {icons?.map(icon => (
      <ListItem key={icon?.title}>
        <PaymentIcon name={icon?.icon} />
      </ListItem>
    ))}
  </List>
))
