import { useStaticQuery, graphql } from "gatsby"

export const useTracking = () =>
  useStaticQuery(graphql`
    query TRACKING_SANITY_SETTINGS {
      tracking: sanitySettingTracking {
        googleTagManagerId
        googleSiteVerify
        facebookDomainVerify
        facebookAppId
      }
    }
  `)
