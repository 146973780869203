import React from "react"
import { withQuickViewSwatches } from "./withQuickViewSwatches"
import {
  Outer,
  Inner,
  Text,
  StyledSwatch,
} from "../Detail/DetailSwatchesStyles"

export const QuickViewSwatches = withQuickViewSwatches(
  ({ swatches, activeProductHandle, setActiveProductHandle, colour }) => (
    <Outer>
      <Inner>
        {swatches?.map(({ id, title, handle, swatchImage }) => (
          <StyledSwatch
            key={id}
            title={title}
            image={swatchImage}
            active={activeProductHandle === handle}
            onClick={() => setActiveProductHandle(handle)}
          />
        ))}
      </Inner>
      {colour ? <Text>{colour}</Text> : null}
    </Outer>
  ),
)
