import React from "react"

export const withCounter = Component => ({
  name = "Counter",
  number,
  maxNumber,
  label,
  color,
}) => {
  const numbers = []
  const sNumber = number.toString()
  const diff = maxNumber - sNumber.length

  for (let i = 0; i < sNumber.length; i++) {
    numbers.push(sNumber.charAt(i))
  }

  for (let d = 0; d < diff; d++) {
    numbers.unshift("0")
  }

  Component.displayName = name
  return (
    <Component
      numbers={numbers}
      label={label}
      width={`${maxNumber}`}
      color={color}
    />
  )
}
