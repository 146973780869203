import tw, { styled } from "twin.macro"
import { RichText } from "../../RichText/RichText"
import { Table } from "../../Table/Table"

export const Wrapper = tw.div`
  pt-1-8 pb-1-2
`

export const Title = tw.h3`
  text-20 leading-1.5 uppercase tracking-10 font-semibold text-center mb-2-4 md:mb-3-2
`

export const Description = styled(RichText)`
  ${tw`pb-2-4 border-b border-navy mb-2-4 md:mb-3-2`}
`

export const Tables = tw.div`
  flex flex-col md:flex-row
`

export const StyledTable = styled(Table)`
  ${tw`md:mr-3 mb-3-2 md:mb-0`}
  :last-of-type {
    ${tw`mr-0 mb-0`}
  }
`
