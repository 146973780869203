import tw, { styled } from "twin.macro"
import { StyledContainer } from "../../Styled/Container"

export const Outer = tw.div`
  relative z-50
`

export const BorderWrapper = tw.div`
  md:border-b border-grey-lightest
`

export const Wrapper = styled(StyledContainer)`
  ${tw`bg-white relative`}
`

export const Results = styled.div`
  ${tw`md:border-t-0 border-grey-lightest absolute z-50 w-full max-h-full-vh overflow-y-auto`}
  max-height: calc(100vh - 94px);
`
