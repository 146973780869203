import { useCallback } from "react"
import { useRoutes } from "./useRoutes"

export const useNavigation = () => {
  const { linkResolver } = useRoutes()

  const mapItems = useCallback(
    items =>
      items?.map(item => {
        const type = item?._type?.replace("navigation", "").toLowerCase()
        switch (type) {
          case "sub":
          case "subimages":
            return {
              ...item,
              type,
              title: linkResolver(item.link).title,
              url: linkResolver(item.link).url,
              external: linkResolver(item.link)?.external || false,
              isExternal: linkResolver(item.link)?.isExternal,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
            break
          case "mega":
          case "subheroimages":
            return {
              ...item,
              type,
              shopAll: linkResolver(item?.shopAll),
              title: linkResolver(item.link).title,
              url: linkResolver(item.link).url,
              external: linkResolver(item.link)?.external || false,
              isExternal: linkResolver(item.link)?.isExternal,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
            break
          case "image":
          case "twocolimage":
          case "imagecardhero":
            return {
              type,
              ...item,
            }
            break
          case "link":
            return {
              ...item,
              type,
              title: item?.title,
              url: linkResolver(item)?.url,
              external: linkResolver(item)?.external || false,
              isExternal: linkResolver(item.link)?.isExternal,
              items: item?.items?.length > 0 ? mapItems(item.items) : [],
            }
          default:
            throw new Error("Unhandled navigation type")
        }
      }),
    [linkResolver],
  )

  return {
    mapItems,
  }
}
