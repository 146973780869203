import React from "react"

import { withLoading } from "./withLoading"
import { Placeholder, Spinner } from "./LoadingStyles"

export const Loading = withLoading(({ placeholders, notEmpty }) =>
<div className="search-results">
  {!notEmpty
    ? placeholders.map((item, index) => (
        <Placeholder
          key={`${item}_${index}`}
          fluid={{
            aspectRatio: 1 / 1.25,
            src: "",
            srcSet: "",
            sizes: "",
          }}
        />
      ))
    : null
  }
</div>
)

export const LoadingSpinner = () => (
  <div className="spinner-wrapper">
   <Spinner />
  </div>
)
