import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../hooks/useFunctions"
import { useRoutes } from "../../hooks/useRoutes"

export const withNewsletterForm = Component => ({
  name = "NewsletterForm",
  setActiveSubscribe,
}) => {
  const { newsletterData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_NEWSLETTER {
      newsletterData: sanitySettingNewsletter {
        additionalTitle
        additionalDescription
        additionalPlaceholder
        additionalSubmitButtonText
        additionalTermsText
        additionalTerms: _rawAdditionalTerms(resolveReferences: { maxDepth: 2 })
        additionalPrivacy: _rawAdditionalPrivacy(
          resolveReferences: { maxDepth: 2 }
        )
        additionalCloseButtonText
        additionalSuccessTitle
        additionalSuccessDescription
        additionalSuccessCloseButtonText
      }
    }
  `)

  const {
    additionalTitle: title,
    additionalDescription: description,
    additionalPlaceholder: placeholder,
    additionalSubmitButtonText: submitButtonText,
    additionalTermsText: termsText,
    additionalTerms,
    additionalPrivacy,
    additionalCloseButtonText: closeButtonText,
    additionalSuccessTitle: successTitle,
    additionalSuccessDescription: successDescription,
    additionalSuccessCloseButtonText: successCloseButtonText,
  } = newsletterData || {}

  const { customerSubscribe, errors } = useFunctions()

  const { linkResolver } = useRoutes()

  const [success, setSuccess] = useState(false)
  const [email, setEmail] = useState("")

  const handleSubmit = async event => {
    event.preventDefault()
    const { customer } = await customerSubscribe(email)
    if (customer?.id) {
      setSuccess(true)
    }
  }

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleClose = () => {
    setActiveSubscribe()
  }

  const terms = linkResolver(additionalTerms)
  const privacy = linkResolver(additionalPrivacy)

  Component.displayName = name
  return (
    <Component
      success={success}
      email={email}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      handleClose={handleClose}
      errors={errors}
      title={title}
      description={description}
      placeholder={placeholder}
      submitButtonText={submitButtonText}
      termsText={termsText}
      terms={terms}
      privacy={privacy}
      closeButtonText={closeButtonText}
      successTitle={successTitle}
      successDescription={successDescription}
      successCloseButtonText={successCloseButtonText}
    />
  )
}
