import { useCallback } from "react"

export const useWindowLoad = () => {
  const waitForGlobal = useCallback((key, callback) => {
    if (typeof window !== `undefined`) {
      if (window[key]) {
        callback()
      } else {
        setTimeout(() => {
          waitForGlobal(key, callback)
        }, 100)
      }
    }
  }, [])

  return { waitForGlobal }
}
