import React from "react"
import { Link } from "gatsby"

import { withWishlistWidget } from "./withWishlistWidget"
import { Wrapper, Count, StyledIcon } from "./WishlistWidgetStyles"

export const WishlistWidget = withWishlistWidget(
  ({ wishlistUrl, count }): JSX.Element => (
    <Wrapper as={Link} to={wishlistUrl}>
      <StyledIcon name={"wishlist"} />
      {count > 0 ? <Count>{count}</Count> : null}
    </Wrapper>
  ),
)
