import * as localQueries from "../graphql/queries"
import * as mutations from "../graphql/mutations"
import { useCore } from "./useCore"

export const useQueries = () => {
  const {
    graphql: { queries },
  } = useCore()
  return { localQueries, queries, mutations }
}
