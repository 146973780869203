import React from "react"

import { Container, Bg, ContentWrapper, Content } from "./DrawerStyles"

export const Drawer = ({ active, handleClose, children }) => (
  <Container active={active ? "true" : "false"}>
    <Bg active={active ? "true" : "false"} onClick={handleClose} />
    <ContentWrapper active={active ? "true" : "false"}>
      <Content>{children}</Content>
    </ContentWrapper>
  </Container>
)
