import tw from "twin.macro"

export const Outer = tw.div`
  py-1-6
`

export const Inner = tw.div`
  flex flex-row items-end
`

export const Apply = tw.button`
  border h-4-9 px-1-2 md:w-11 rounded ml-0-8 text-14 leading-1.2 py-0-6 uppercase transition-all duration-200 bg-navy text-white
`

export const Error = tw.div`
  text-12 uppercase mt-1-6 text-red
`
