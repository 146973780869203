import tw, { styled } from "twin.macro"

const styles = {
  default: {
    headings: tw`font-extrabold uppercase`,
    lightHeadings: tw`font-bold uppercase`,
    text: tw`font-normal`,
  },
  align: {
    left: tw`text-left`,
    center: tw`text-center`,
    right: tw`text-right`,
    "md:right": tw`text-left md:text-right`,
  },
  colour: {
    white: tw`text-white`,
    navy: tw`text-navy`,
    black: tw`text-black`,
    red: tw`text-red`,
  },
  spacing: {
    default: tw`mb-1-6`,
  },
}

export const H1 = styled.h1<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-50 leading-none md:text-70 tracking-10 md:leading-1.07`}
`
export const H2 = styled.h2<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-28 md:text-60 tracking-10 leading-none`}
`
export const H3 = styled.h3<TextProps>`
  ${styles.default.headings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-28 leading-1.28 md:text-50 md:leading-none tracking-10 leading-none`}
`
export const H4 = styled.h4<TextProps>`
  ${styles.default.lightHeadings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 tracking-10 leading-1.25 md:text-35 md:leading-1.28`}
`

export const H5 = styled.h5<TextProps>`
  ${styles.default.lightHeadings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 md:text-28 tracking-10 leading-1.28`}
`

export const H6 = styled.h6<TextProps>`
  ${styles.default.lightHeadings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 tracking-10 leading-1.25 md:text-25 md:leading-1.4`}
`

export const SubTitle = styled.h5<TextProps>`
  ${styles.default.lightHeadings}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 md:text-16 tracking-10 leading-1.71 md:leading-1.25`}
`

export const XXLargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-20 leading-1.7 md:text-25 md:leading-1.4`}
`

export const XLargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 leading-1.62 md:text-20 md:leading-1.7`}
`

export const LargeBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 leading-1.62 md:text-18 md:leading-1.44`}
`
export const Body = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-16 leading-1.62`}
`

export const SmallBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 leading-1.71`}
`

export const TinyBody = styled.p<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-12 leading-1.33 tracking-5`}
`

export const RichTextContent = styled.div<TextProps>`
  ${styles.default.text}
  ${({ align }) => styles.align[align]}
  ${({ colour }) => colour && styles.colour[colour]}
  ${({ spacing }) => spacing && styles.spacing[spacing]}
  ${tw`text-14 leading-none`}
  > *, > div > * {
    ${tw`pb-0-8 last:pb-0`}
  }
`

type TextProps = {
  align?: "left" | "center" | "right" | "md:right"
  colour?: "white" | "black" | "red"
  spacing?: "default"
}
