import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCheckout, useCheckoutContext } from "../../../../hooks/useCheckout"
import { useShopify } from "../../../../hooks/useShopify"
import configDefault from "../../../../../config.default"
import { useApp } from "../../../../hooks/useApp"

export const withSummary =
  Component =>
  ({ name = "Summary" }) => {
    const { checkout } = useCheckoutContext()

    const { removeDiscountCode } = useCheckout()
    const { setDiscountCode } = useApp()

    const { formatMoney } = useShopify()

    const { cart } = useStaticQuery(graphql`
      query SANITY_PAGE_CART_SUMMARY {
        cart: sanityPageCart {
          freeShippingThreshold
          additionalSubtotalText
          additionalDiscountText
          additionalShippingText
          additionalShippingPlaceholderText
          additionalTotalText
        }
      }
    `)

    const {
      additionalSubtotalText,
      additionalDiscountText,
      additionalShippingText,
      additionalShippingPlaceholderText,
      additionalTotalText,
      freeShippingThreshold,
    } = cart || {}

    const amountDue = formatMoney(checkout?.paymentDue?.amount)

    const subTotal = formatMoney(checkout?.lineItemsSubtotalPrice?.amount)

    const shippingText = checkout?.shippingLine?.price
      ? checkout?.shippingLine?.price !== "0.0"
        ? formatMoney(checkout?.shippingLine?.price)
        : additionalShippingPlaceholderText
      : additionalShippingPlaceholderText

    const removeGSTDiscount = checkout?.discountApplications?.filter(
      discount =>
        discount.__typename === "ScriptDiscountApplication" &&
        discount?.title ===
          configDefault.settings.international.GST_REMOVED_MESSAGE,
    )
    const removeGSTDiscountAmount = removeGSTDiscount?.reduce(
      (prev, current) => prev + parseFloat(current?.value?.amount),
      0,
    )

    const removeGSTDiscountAmountFormatted = formatMoney(
      removeGSTDiscountAmount,
    )
    const removeGSTDiscountMessage = removeGSTDiscount.length
      ? removeGSTDiscount[0]?.title
      : null

    const discountApplied =
      parseFloat(checkout?.lineItemsSubtotalPrice?.amount) -
      parseFloat(checkout?.subtotalPrice?.amount) -
      removeGSTDiscountAmount

    const formattedDiscountApplied = formatMoney(discountApplied)

    const appliedDiscountCode = checkout?.discountApplications?.find(
      discount => discount.code,
    )?.code

    const handleDiscountCodeRemove = () => {
      removeDiscountCode()
      setDiscountCode("")
    }

    const currencyCode = React.useMemo(() => {
      return checkout?.currencyCode
    }, [checkout])

    const shippingApplied = parseFloat(checkout?.shippingLine?.price)
    const shippingAppliedFormatted = formatMoney(shippingApplied)
    const freeShippingAmount =
      freeShippingThreshold - checkout?.paymentDue?.amount
    const freeShippingMessage =
      freeShippingAmount < 0 ? `Free` : additionalShippingPlaceholderText

    Component.displayName = name
    return (
      <Component
        amountDue={amountDue}
        subTotal={subTotal}
        shippingText={shippingText}
        discountApplied={discountApplied}
        formattedDiscountApplied={formattedDiscountApplied}
        appliedDiscountCode={appliedDiscountCode}
        handleDiscountCodeRemove={handleDiscountCodeRemove}
        currencyCode={currencyCode}
        additionalSubtotalText={additionalSubtotalText}
        additionalDiscountText={additionalDiscountText}
        additionalShippingText={additionalShippingText}
        additionalShippingPlaceholderText={additionalShippingPlaceholderText}
        additionalTotalText={additionalTotalText}
        removeGSTDiscount={removeGSTDiscount}
        removeGSTDiscountAmountFormatted={removeGSTDiscountAmountFormatted}
        removeGSTDiscountMessage={removeGSTDiscountMessage}
        shippingApplied={shippingApplied}
        shippingAppliedFormatted={shippingAppliedFormatted}
        freeShippingMessage={freeShippingMessage}
      />
    )
  }
