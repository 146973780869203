import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useLanguages } from "./useLanguages"
import { useOrganisation } from "./useOrganisation"
import { useScripts } from "./useScripts"
import { useTracking } from "./useTracking"

export const useMeta = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const { helpers } = useCore()
  const { social, tracking } = useTracking()
  const { scripts } = useScripts()
  const { languages } = useLanguages()
  const { organisation } = useOrganisation()

  const mapping = {
    page: helpers.schemaWebSite,
    blog: helpers.schemaBlog,
    article: helpers.schemaArticle,
    product: helpers.schemaProduct,
    collection: helpers.schemaWebSite,
    search: helpers.schemaSearch,
    store: helpers.schemaStore,
  }

  const formatTitle = (title, siteName, separator) => {
    if (separator?.length && title?.length && siteName?.length && !title.includes("|")) {
      return `${title} ${separator} ${siteName}`
    }
    return title
  }
  
  const getData = ({ breadcrumbs, data, language, routes, template, url }) =>
    {
      const schema = helpers.schemaData({ breadcrumbs, data, global: undefined, language, organisation, routes, social, template, tracking, url }, mapping)
      const shopify = data?.document?.shopify || data?.collection?.shopify || data?.product?.shopify
      const raw = JSON.parse(shopify?.raw || "{}")
      const shopifySeoDescription = raw?.metafields?.find(({ key }) => key === "description_tag")?.value
      const shopifySeoTitle = raw?.metafields?.find(({ key }) => key === "title_tag")?.value
  
      const title = shopifySeoTitle || shopify?.title || data?.title || schema.title
  
      if (shopify) {
        schema.title = formatTitle(title, organisation?.title, organisation?.separator)
        schema.description = shopifySeoDescription || shopify?.description || schema.description
      }
  
      return schema
    }

  const getTags = data => helpers.metaTags(data)

  const getLanguages = route =>
    languages?.hrefLangs?.map(({ _key, language, region, url }, index) => ({
      key: _key,
      primary: !index,
      href: `${url || organisation?.url}${route || ``}`,
      hrefLang: `${language}-${region}`,
      language: `${language}_${region}`,
      rel: `alternate`,
    }))

  const getSchemas = data => {
    const schemas = [helpers.schemaOrg(data), helpers.schemaContent(data), helpers.schemaBreadcrumbs(data)]
    const contentSchema = schemas[1]
    try {
      if (!contentSchema?.image?.url || contentSchema?.image?.url === `undefined`) {
        contentSchema.image.url = data?.document?.featuredImage?.originalSrc
      }
    } catch {
      // nothing
    }
    const filtered = schemas?.filter(
      schema => schema[`@type`] !== `Organization` || data?.url === routes?.HOMEPAGE
    )
    return filtered
  }
    

  const getTracking = () =>
  helpers?.isDomReady
    ? [
        helpers.renderTrackingScripts(scripts?.trackingHeader?.code || "", { id: "tracking", placement: "head" }),
        helpers.renderTrackingScripts(scripts?.trackingBody?.code || "", { id: "tracking", placement: "body" }),
        helpers.renderTrackingScripts(scripts?.trackingFooter?.code || "", { id: "tracking", placement: "foot" }),
      ]
    : []

  return {
    getData,
    getLanguages,
    getSchemas,
    getTags,
    getTracking,
  }
}
