import React, { useCallback, useEffect, useState } from "react"
import { navigate } from "gatsby"

import { useShopify } from "../../../../hooks/useShopify"
import { getAdditionalText } from "../../../../utils/getAdditionalText"
import { useApp } from "../../../../hooks/useApp"
import { useSearch } from "@usereactify/search"

export const withInstantSearchResults =
  Component =>
  ({ name = "InstantSearchResults", data }) => {
    const {
      template: {
        global: {
          additionalCollectionsTitle,
          additionalProductsTitle,
          additionalViewAllResultsText,
        },
      },
    } = getAdditionalText()

    const {
      config: {
        settings: { routes },
      },
    } = useApp()

    const { searchQuery } = useSearch()

    const { imageNormaliser, getProducts } = useShopify()

    const [items, setItems] = useState([])

    const populateItems = useCallback(async () => {
      const initialItems = data?.slice(0, 4)?.map(product => ({
        ...product,
        images: product?.images?.map(image => imageNormaliser(image, 1500)),
      }))

      const handles = initialItems?.map(item => item.handle)

      const products = await getProducts({
        handles,
        firstVariants: 99,
      })

      const mergedItems = initialItems?.map(item => {
        const product = products.find(product => product?.id?.includes(item.id))

        return {
          item,
          product,
        }
      })

      const newItems = mergedItems?.map(merged => {
        return {
          ...merged.item,
          presentment_price_ranges: undefined,
          variants: merged?.item?.variants?.map(variant => {
            const productVariant = merged?.product?.variants?.find(
              productVariant => productVariant.id?.includes(variant.id),
            )

            if (productVariant) {
              return {
                ...variant,
                presentment_prices: productVariant?.priceV2
                  ? undefined
                  : variant?.presentment_prices,
                price: undefined,
                priceV2: productVariant?.priceV2,
              }
            }

            return variant
          }),
        }
      })

      setItems(newItems)
    }, [location, data])

    // useTraceUpdate({ nik: {}, items, imageNormaliser, populateItems, data, getProducts })

    useEffect(() => {
      populateItems()
    }, [populateItems])

    const collections = data
      ?.reduce((acc, item) => acc.concat(item.collections), [])
      ?.reduce(
        (acc, item) =>
          acc.some(collection => collection.handle === item.handle)
            ? acc
            : acc.concat(item),
        [],
      )
      ?.map(({ id, title, handle }) => ({
        title,
        id,
        url: `${routes.COLLECTION}/${handle}`,
      }))
      ?.filter(({ title }) => !title.toLowerCase().includes("orderly"))

    const handleSubmit = () => navigate(`${routes.SEARCH}?q=${searchQuery}`)

    Component.displayName = name
    return (
      <Component
        items={items}
        collections={collections}
        handleSubmit={handleSubmit}
        additionalCollectionsTitle={additionalCollectionsTitle}
        additionalProductsTitle={additionalProductsTitle}
        additionalViewAllResultsText={additionalViewAllResultsText}
      />
    )
  }
