import React from "react"

import { withCartItemPrice } from "./withCartItemPrice"
import { Wrapper, PriceTag, Outer } from "./CartItemPriceStyles"

export const CartItemPrice = withCartItemPrice(
  ({ isOnSale, percentage, price, compareAtPrice, layout, isFree }) =>
    isFree ? (
      <PriceTag layout={layout}>$0.00</PriceTag>
    ) : (
      <Outer layout={layout}>
        <Wrapper>
          {isOnSale ? (
            <>
              <PriceTag strike layout={layout}>
                ${compareAtPrice}
              </PriceTag>
              &nbsp;
            </>
          ) : null}

          <PriceTag sale={isOnSale} layout={layout}>
            ${price}
          </PriceTag>
        </Wrapper>
        {percentage?.length > 0 && (
          <PriceTag sale layout={layout}>
            &nbsp;({percentage}% OFF)
          </PriceTag>
        )}
      </Outer>
    ),
)
