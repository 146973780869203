import React from "react"

export const withMenu = Component => ({
  name = "Menu",
  isMenuOpen,
  handleClick,
}) => {
  Component.displayName = name
  return <Component isMenuOpen={isMenuOpen} handleClick={handleClick} />
}
