import tw, { styled } from "twin.macro"

export const List = styled.ul`
  ${tw`md:px-10 list-none w-full flex flex-row items-center`}
`

export const ListItem = styled.div`
  ${tw`text-12 lg:text-14 font-bold tracking-5 lg:tracking-10 leading-1.67 lg:leading-1.42 pt-1-8 pb-1-5 flex items-center justify-center ml-auto mr-auto uppercase border-b-3`}
  ${({ active }) => (active ? tw`border-navy` : tw`border-transparent`)}
`

export const ListItemWrapper = styled.li`
  ${tw`text-12 lg:text-14 font-bold tracking-5 lg:tracking-10 leading-1.67 lg:leading-1.42 cursor-pointer flex items-center justify-center flex-grow ml-auto mr-auto uppercase`}
  :first-of-type {
    ${tw`ml-0`}
  }
  :last-of-type {
    ${tw`mr-0`}
  }
`
