import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNewsletter } from "../../hooks/useNewsletter"

export const withNewsletter = Component => ({ name = "Newsletter", path }) => {
  const { activeSubscribe, hideSubscribe } = useNewsletter(path)

  const { newsletter } = useStaticQuery(graphql`
    query SANITY_SETTINGS_NEWSLETTER_IMAGE {
      newsletter: sanitySettingNewsletter {
        featuredImage: _rawFeaturedImage(resolveReferences: { maxDepth: 3 })
        imagePosition
      }
    }
  `)
  const { featuredImage, imagePosition } = newsletter || {}

  Component.displayName = name
  return (
    <Component
      activeSubscribe={activeSubscribe}
      setActiveSubscribe={hideSubscribe}
      featuredImage={featuredImage}
      imagePosition={imagePosition}
    />
  )
}
