import React from "react"

import { withInstantSearchForm } from "./withInstantSearchForm"
import {
  InputWrapper,
  Form,
  Close,
  Search,
} from "./InstantSearchFormStyles"
import { Icon } from "../../../Icon/Icon"


export const InstantSearchForm = withInstantSearchForm(
  ({
    value,
    inputRef,
    handleSubmit,
    handleChange,
    handleClose,
    additionalInstantSearchPlaceholderText,
  }): JSX.Element => (
    <>
      <InputWrapper>
        <Search>
          <Icon name={"search"} width={20} height={20} />
        </Search>
        <Form onSubmit={handleSubmit}>
          <input
            ref={inputRef}
            name="q"
            value={value}
            onChange={handleChange}
            placeholder={additionalInstantSearchPlaceholderText}
          />
        </Form>
        <Close onClick={handleClose}>
          <Icon name={"cross"} width={12} height={12} />
        </Close>
      </InputWrapper>
    </>
  ),
)
