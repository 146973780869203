import tw, { styled } from "twin.macro"
import { Icon } from "../../Icon/Icon"

export const List = styled.div`
  ${tw`flex flex-row items-center mlg:justify-center py-1-6`}
`

export const ListItem = tw.div`block mx-0-8 text-white text-opacity-70`

export const PaymentIcon = styled(Icon)`
  > svg {
    fill: currentColor;
  }
`