import React from "react"
import { Link } from "gatsby"

import { withNavigationTerms } from "./withNavigationTerms"
import { Wrapper, List, ListItem } from "./NavigationTermsStyles"

export const NavigationTerms = withNavigationTerms(({ items }) => (
  <Wrapper>
    <List>
      {items?.map(item => (
        <ListItem key={item?.title}>
          <Link to={item?.url}>{item?.title}</Link>
        </ListItem>
      ))}
    </List>
  </Wrapper>
))
