import React from "react"

import { withMenu } from "./withMenu"
import { MenuButton, Text, StyledIcon } from "./MenuStyles"

export const Menu = withMenu(({ text, isMenuOpen, handleClick }) => (
  <MenuButton onClick={handleClick}>
    <StyledIcon
      name={isMenuOpen ? "cross" : "menu"}
      width={"14"}
      height={"14"}
    />
    <Text>{text}</Text>
  </MenuButton>
))
