import React from "react"

import {
  Modal,
  ModalOpenButton,
  ModalContentsWithLayout,
} from "../../Modal/Modal"
import { Icon } from "../../Icon/Icon"
import { withQuickView } from "./withQuickView"
import { QuickViewButton, QuickViewButtonDot } from "./QuickViewStyles"
import { QuickViewContent } from "./Content/QuickViewContent"

export const QuickView = withQuickView(
  ({ handle, parentProductHandle, additionalQuickViewText, layout }) => (
    <Modal>
      <ModalOpenButton>
        <QuickViewButton className="group" layout={layout}>
          {layout === "catalogue" && <QuickViewButtonDot />}
          {layout === "card" &&
            <>
              <Icon name="quickView" />
              <span>&nbsp;{additionalQuickViewText}</span>
            </>
          }
        </QuickViewButton>
      </ModalOpenButton>
      <ModalContentsWithLayout layout="quickView">
        <QuickViewContent
          handle={handle}
          parentProductHandle={parentProductHandle}
        />
      </ModalContentsWithLayout>
    </Modal>
  ),
)
