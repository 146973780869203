import React from "react"

import { withSummary } from "./withSummary"
import {
  Wrapper,
  Details,
  Detail,
  Text,
  Divider,
  DiscountCode,
  DiscountCodeClose,
} from "./SummaryStyles"
import { Icon } from "../../../Icon/Icon"

export const Summary = withSummary(
  ({
    subTotal,
    shippingText,
    discountApplied,
    formattedDiscountApplied,
    amountDue,
    appliedDiscountCode,
    handleDiscountCodeRemove,
    currencyCode,
    additionalSubtotalText,
    additionalDiscountText,
    additionalShippingText,
    additionalShippingPlaceholderText,
    additionalTotalText,
    removeGSTDiscountAmountFormatted,
    removeGSTDiscountMessage,
    shippingApplied,
    shippingAppliedFormatted,
    freeShippingMessage
  }) => (
    <Wrapper>
      <Details>
        <Detail>
          <Text>{additionalSubtotalText}</Text>
          <Text>{subTotal}</Text>
        </Detail>
        {discountApplied > 0 ? (
          <Detail>
            <Text as={"div"} discount={"true"} className={"group"}>
              {additionalDiscountText}&nbsp;
              {appliedDiscountCode && (
                <DiscountCode>
                  {appliedDiscountCode}
                  <DiscountCodeClose onClick={handleDiscountCodeRemove}>
                    <Icon name={"cross"} width={8} height={8} />
                  </DiscountCodeClose>
                </DiscountCode>
              )}
            </Text>
            <Text discount={"true"}>-&nbsp;{formattedDiscountApplied}</Text>
          </Detail>
        ) : null}

        {removeGSTDiscountMessage && (
          <Detail>
            <Text as={"div"} className={"group"} grey={"true"}>
              {removeGSTDiscountMessage}&nbsp;
            </Text>
            <Text grey={"true"}>
              -&nbsp;{removeGSTDiscountAmountFormatted}
            </Text>
          </Detail>
        )}

        <Detail>
          <Text>{shippingApplied ? 'Shipping' : additionalShippingText}</Text>
          <Text grey={shippingApplied ? "false" : "true"}>{shippingApplied ? shippingAppliedFormatted : freeShippingMessage}</Text>
        </Detail>
      </Details>
      <Divider />
      <Detail>
        <Text>{additionalTotalText}</Text>
        <span className={"inline"}>
          <Text grey={"true"}>{currencyCode}&nbsp;&nbsp;&nbsp;</Text>
          <Text total={"true"}>{amountDue}</Text>
        </span>
      </Detail>
    </Wrapper>
  ),
)
