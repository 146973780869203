import { useCallback } from "react"
import { getGatsbyImageData } from "@dotdev/gatsby-source-sanity"

import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useImage = () => {
  const {
    config: {
      services: { sanity },
    },
  } = useApp()
  const {
    helpers: { handleize },
  } = useCore()

  const filename = ({ alt, asset }) =>
    `${handleize(asset?.originalFilename?.split(`.`)?.[0] || alt)}.${
      asset?.extension
    }`

  const withFilename = (
    fluidImage = {},
    name = `image.jpg`,
    properties = [`src`, `srcWebp`, `srcSet`, `srcSetWebp`],
  ) => ({
    ...fluidImage,
    ...properties?.reduce(
      (o, key) => ({
        ...o,
        [key]: fluidImage[key]?.split(`?`)?.join(`/${name}?`),
      }),
      {},
    ),
  })

  const getGatsbyImage = useCallback(
    (image: any, options: any = null) =>
      image?.asset?.id || image?.asset?._id
        ? {
            alt: image?.alt,
            dimensions: image?.asset?.metadata?.dimensions,
            ...withFilename(getGatsbyImageData(image, options || {}, sanity)?.images?.fallback, filename(image)),
          }
        : image,
    [filename, sanity, withFilename]
  )

  const getResponsiveImage = (
    image,
    options = { desktop: { maxWidth: 2000 }, mobile: { maxWidth: 600 } },
  ) => {
    const mobileImage = image?.mobile?.asset?.url
      ? image?.mobile
      : image?.desktop
    return {
      desktop: getGatsbyImage(
        image?.desktop,
        options?.desktop || options || {},
      ),
      desktopVideo: image?.desktopVideo,
      desktopAlt: image?.desktop?.alt,
      mobile: getGatsbyImage(
        mobileImage,
        options?.mobile || options?.desktop || options || {},
      ),
      mobileVideo: image?.mobileVideo,
      mobileAlt: image?.mobile?.alt ?? image?.desktop?.alt,
    }
  }

  return { getGatsbyImage, getResponsiveImage }
}
