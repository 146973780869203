import React from "react"

import { withCheckout } from "./withCheckout"
import { Outer, Inner, FreeShippingCallToAction } from "./CheckoutDrawerStyles"
import { StyledButton } from "../../../Styled/Button"

export const CheckoutDrawer = withCheckout(
  ({
    loading,
    handleCheckout,
    freeShippingAmount,
    freeShippingCallToAction,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
    additionalKeepShoppingButton,
    handleClose,
  }): JSX.Element => (
    <Outer>
      <Inner>
        <StyledButton
          size={"primary"}
          onClick={handleClose}
          colour={"navyBorderedWithNavyText"}
          wide
        >
          {additionalKeepShoppingButton}
        </StyledButton>
        <StyledButton size={"primary"} onClick={handleCheckout} wide>
          {loading ? additionalCheckoutButtonLoading : additionalCheckoutButton}
        </StyledButton>
      </Inner>
      {freeShippingAmount > 0 ? (
        <FreeShippingCallToAction>
          {freeShippingCallToAction}
        </FreeShippingCallToAction>
      ) : null}
    </Outer>
  ),
)
