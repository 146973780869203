import { useStaticQuery, graphql } from "gatsby"

export const useScripts = () =>
  useStaticQuery(graphql`
    query SCRIPTS_SANITY_SETTINGS {
      scripts: sanitySettingScripts {
        trackingHeader {
          code
        }
        trackingBody {
          code
        }
        trackingFooter {
          code
        }
      }
    }
  `)
