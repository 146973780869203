import tw, { styled } from "twin.macro"

const styles = {
  default: tw`block max-w-full select-none`,
}

export const StyledIcon = styled.span`
  ${styles.default}
  > svg {
    fill: transparent;
  }
`
