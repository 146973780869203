import React, { useEffect, useState } from "react"

import { useCheckoutContext } from "../../../hooks/useCheckout"
import { useLocation } from "../../../hooks/useLocation"

export const withPrice =
  Component =>
  ({ name = "Price", variant, layout }) => {
    const { checkout } = useCheckoutContext()

    const { location } = useLocation()

    const [price, setPrice] = useState("")
    const [compareAtPrice, setCompareAtPrice] = useState("")

    useEffect(() => {
      if (checkout?.currencyCode) {
        const price =
          variant?.priceV2?.amount || variant?.priceV2 || variant?.price || 0
        const compareAtPrice =
          variant?.compareAtPriceV2?.amount ||
          variant?.compareAtPriceV2 ||
          variant?.compareAtPrice ||
          variant?.compare_at_price ||
          0

        setPrice(price)
        setCompareAtPrice(compareAtPrice)
      }
    }, [checkout?.currencyCode, variant])

    const formattedPrice = React.useMemo(() => {
      return price ? Number(price).toFixed(2) : ""
    }, [price])

    const formattedComparedAtPrice = React.useMemo(() => {
      return compareAtPrice ? Number(compareAtPrice).toFixed(2) : ""
    }, [compareAtPrice])

    const isOnSale = React.useMemo(() => {
      return Number(formattedComparedAtPrice) > Number(formattedPrice)
    }, [formattedComparedAtPrice, formattedPrice])

    const percentage = React.useMemo(() => {
      return isOnSale
        ? ((1 - Number(price) / Number(compareAtPrice)) * 100).toFixed(0)
        : undefined
    }, [isOnSale, price, compareAtPrice])

    Component.displayName = name
    return (
      <Component
        isOnSale={isOnSale}
        percentage={percentage}
        price={formattedPrice}
        compareAtPrice={formattedComparedAtPrice}
        layout={layout}
      />
    )
  }
