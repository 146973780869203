import React from "react"

import { Icon } from "../../Icon/Icon"
import { withSellingPoints } from "./withSellingPoints"
import { Wrapper, Point, Title, IconWrapper, Bg } from "./SellingPointsStyles"
import { Link } from "../../Link/Link"

export const SellingPoints = withSellingPoints(({ sellingPoints }) => (
  <Bg>
    <Wrapper width={"mlg"}>
      {sellingPoints?.map(point => (
        <Point key={point.title} hiddenOnMobile={point?.hidden}>
          <IconWrapper>
            <Icon name={point?.icon} title={point?.title} />
          </IconWrapper>
          <Title>
            <Link
              title={point?.title}
              url={point?.url}
              external={point?.external}
              isExternal={point?.isExternal}
            />
          </Title>
        </Point>
      ))}
    </Wrapper>
  </Bg>
))
