import React from "react"

import { Link } from "../../../../../../Link/Link"
import { ListItem, SubListItem } from "./DropdownSubListStyles"
import { NavigationItemsImageCard } from "../../../../../../NavigationItems/ImageCard/NavigationItemsImageCard"

export const DropdownSubList = ({ item }) => (
  <>
    <ListItem>
      <Link
        title={item?.title}
        url={item?.url}
        external={item?.external}
        isExternal={item.isExternal}
      />
    </ListItem>
    {item?.items?.length > 0 ? (
      <div>
        {item.items.map((item, idx) => (
          <SubListItem key={idx}>
            {item?.type === "image" ? (
              <NavigationItemsImageCard item={item} />
            ) : (
              <Link
                title={item?.title}
                url={item?.url}
                external={item?.external}
                trackingPrefix={"navigation_header_desktop_level_2"}
                isExternal={item?.isExternal}
                color={item?.link?.color || item?.color}
              />
            )}
          </SubListItem>
        ))}
      </div>
    ) : null}
  </>
)
