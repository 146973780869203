import tw, { styled, css } from "twin.macro"

import { Body } from "./Text"

export const StyledForm = tw.form`block w-full`

export const StyledFormErrors = tw(Body)`py-0-8 text-red`

export const StyledLabel = tw.label`
  text-14 leading-1.71 block mb-0-8
`

export const StyledInput = styled.input<InputProps>`
  ${tw`block appearance-none outline-none w-full border bg-transparent border-navy rounded border-opacity-25 text-16 font-normal leading-none px-1-6 py-1-2 transition-all duration-200`}
  ${({ spacing }) => (spacing ? tw`mb-1-6` : tw`mb-0`)}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ errors }) => errors && tw`border-red text-red`}
  ${css`
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      ${tw`m-0`}
      -webkit-appearance: none;
    }
    &[type="number"] {
      -moz-appearance: textfield;
    }
    &::placeholder {
      ${tw`opacity-100 uppercase text-16 md:text-12 tracking-10`}
    }
  `}
`

export const StyledTextArea = styled.textarea<TextareaProps>`
  ${tw`min-h-12 block appearance-none outline-none w-full border border-navy rounded border-opacity-25 p-1-6 resize-none text-16 leading-1.28 transition-all duration-200 bg-white`}
  ${({ disabled }) => disabled && tw`pointer-events-none opacity-50`}
  ${({ spacing }) => (spacing ? tw`mb-2-4 md:mb-3-2` : tw`mb-0`)}
  ${css`
    &::placeholder {
      ${tw`opacity-100 uppercase text-16 md:text-12 tracking-10`}
    }
  `}
`

export const StyledSelect = styled.select<SelectProps>`
  ${({ layout }) => {
    if (layout === "productVariant")
      return tw`py-1 px-1-6 text-16 md:text-14 leading-1.12 md:leading-1.28 rounded-5`
    if (layout === "cardVariant")
      return tw`py-1-5 px-1-6 md:text-14 text-16 leading-none md:leading-1.28 rounded-2`
    else return tw`px-0-8 py-0-5 rounded-2 leading-none text-16`
  }}
  ${tw`block appearance-none outline-none w-full bg-transparent border cursor-pointer`}
`

export const StyledDropdownSelect = styled.select<DropdownSelectProps>`
  ${tw`block appearance-none outline-none w-full bg-transparent rounded border border-navy border-opacity-25 cursor-pointer`}
  ${({ layout }) => {
    switch (layout) {
      case "productQuantity":
        return tw`text-18 leading-1.44 pr-3 pl-2 py-1-2 mr-1-6`
      case "pageSize":
        return tw`pr-3-2 pl-1-2 py-1 text-14 leading-1.42`
      case "form":
        return tw`px-1-6 pt-1-5 pb-1-6 text-16 leading-none`
      case "sortBy":
        return tw`px-1-2 pt-1-1 pb-1-2 border-opacity-100`
      case "address":
        return tw`px-1-6 pt-1-1 pb-1-2 text-16 leading-1.5`
      default:
        break
    }
  }}
  ${({ spacing }) => (spacing ? tw`mb-1-6` : tw`mb-0`)}
  -webkit-appearance: none;
`

export const StyledSelectOption = tw.option``

export const StyledSelectWrapper = styled.div<SelectWrapperProps>`
  ${({ spacing }) => (spacing === "true" ? tw`mb-0-8` : tw`mb-0`)}
  ${tw`relative w-full`}
`

export const StyledInputWrapper = styled.div<InputWrapperProps>`
  ${tw`w-full relative transition-all duration-200`}
  ${({ loading }) => loading && tw`opacity-50 pointer-events-none`}
`

export const StyledInputIconWrapper = styled.button`
  ${tw`absolute bottom-0 top-0 right-1-2 h-full flex items-center justify-center hover:translate-x-0-4 transform duration-300`}
  ${({ onClick, type }) =>
    !onClick && type !== `submit` && tw`pointer-events-none`}
`

type InputProps = {
  spacing?: boolean
  disabled?: boolean
  errors?: boolean
}

type TextareaProps = {
  spacing?: "true" | "false"
  disabled?: boolean
  errors?: boolean
}

type InputWrapperProps = {
  loading?: boolean
}

type SelectProps = {
  layout?: "productVariant" | "cardVariant"
}

type DropdownSelectProps = {
  layout: "sortBy" | "form" | "address"
}

type SelectWrapperProps = {
  spacing?: boolean
}
