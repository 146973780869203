import React from "react"

import { AdditionalPayments } from "../../AdditionalPayments/AdditionalPayments"
import { AddToCart } from "../../AddToCart/AddToCart"
import { Badge } from "../../Badge/Badge"
import { Message } from "../../Message/Message"
import { Price } from "../../Price/Price"
import { SizeGuide } from "../../SizeGuide/SizeGuide"
import { QuickViewSwatches as Swatches } from "../../Swatches/QuickView/QuickViewSwatches"
import { Variants } from "../../Variants/Variants"
import { Wishlist } from "../../Wishlist/Wishlist"
import {
  TitleWrapper,
  Title,
  PriceWrapper,
  BadgeWrapper,
  Divider,
  VariantsWrapper,
  WishlistWrapper,
} from "../FormStyles"
import { withQuickViewForm } from "./withQuickViewForm"

export const QuickViewForm = withQuickViewForm(
  ({
    tags,
    title,
    activeProduct,
    selectedVariant,
    defaultVariant,
    selectedOptions,
    multipleVariants,
    handleVariant,
    products,
    activeProductHandle,
    setActiveProductHandle,
    isOnSale,
  }) => (
    <div>
      <Badge tags={tags} layout={"product"} isOnSale={isOnSale} />
      <TitleWrapper>
        <Title>{title}</Title>
        <WishlistWrapper>
          <Wishlist product={activeProduct} selectedVariant={selectedVariant} />
        </WishlistWrapper>
      </TitleWrapper>
      <PriceWrapper>
        <Price variant={selectedVariant || defaultVariant} layout={"product"} />
      </PriceWrapper>
      <BadgeWrapper>
        <Message tags={tags} layout={"product"} />
      </BadgeWrapper>
      <AdditionalPayments variant={selectedVariant || defaultVariant} />
      <Divider />
      <Swatches
        products={products}
        activeProductHandle={activeProductHandle}
        setActiveProductHandle={setActiveProductHandle}
      />
      {multipleVariants ? (
        <VariantsWrapper>
          <Variants
            handleVariant={handleVariant}
            product={activeProduct}
            selectedOptions={selectedOptions}
          />
          <SizeGuide tags={tags} />
        </VariantsWrapper>
      ) : null}
      <AddToCart
        product={activeProduct}
        selectedVariant={selectedVariant}
        layout={"product"}
        showQuantitySelector
      />
    </div>
  ),
)
