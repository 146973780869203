import { useCallback } from "react"

export const useDotdigital = () => {
  const identifyUser = useCallback(customer => {
    if (window && window.dmPt) {
      window.dmPt("identify", `${customer?.email}`)
    }
  }, [])

  const trackWebBehavior = useCallback(() => {
    if (window && window.dmPt) {
      window.dmPt("create", "DM-SAMPLE-01")
      window.dmPt("track")
    }
  }, [])

  return { identifyUser, trackWebBehavior }
}
