import tw, {styled} from "twin.macro"
import { Icon } from "../../Icon/Icon"

export const Outer = tw.div`mb-2-4`

export const Inner = tw.div`flex flex-row items-center`

export const Text = tw.p`text-13 leading-1.84`

export const PaymentIcon = styled(Icon)`
  > svg {
    fill: currentColor;
  }
`