import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  pt-1-9 px-2-4 relative
`

export const Close = styled.button`
  ${tw`flex flex-row items-center text-14 leading-1.42`}
  svg {
    ${tw`ml-1-2`}
  }
`

export const Header = tw.div`
  flex flex-row justify-between items-start
`

export const Title = tw.h2`font-bold text-20 leading-1.25 tracking-2 mb-0-8 uppercase`

export const Count = tw.p`text-14 leading-1.78 mb-1-9`

export const Divider = tw.hr`border-t border-navy border-opacity-20`

export const LineItems = tw.ul`w-full border-b border-navy border-opacity-20`
