import { useState, useCallback } from "react"

import { useCore } from "./useCore"
import { useLocation } from "./useLocation"
import { useApp } from "./useApp"
import { useCustomerContext } from "./useCustomer"

export const useFunctions = () => {
  const {
    helpers: { decodeBase64 },
  } = useCore()
  const { shopifyStore } = useLocation()
  const {
    config: {
      services: { functions },
    },
  } = useApp()

  const { customer, setCustomer } = useCustomerContext()

  const [loading, setLoading] = useState(false)
  const [errors, setErrors] = useState([])
  
  const requestGatsby = useCallback(async (endpoint, data) => {
    return fetch(`/api/${endpoint}`, {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
        "Access-Control-Allow-Methods": "POST, GET, OPTIONS",
        "Access-Control-Allow-Headers": "Content-Type",
        "Access-Control-Max-Age": "3600",
      },
      body: JSON.stringify(data),
      // body: data,
    }).then(res => res.json())
    
  }, [])
  
  const request = useCallback(
    async (endpoint, data) => {
      setLoading(true)
      setErrors([])
      const baseEndpoint =
        "development" === process.env.NODE_ENV
          ? "http://localhost:5001"
          : functions?.endpoint

      return fetch(`${baseEndpoint}${endpoint}?shop=${shopifyStore}`, {
        method: "post",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then(res => res.json())
        .then(result => {
          setLoading(false)
          if (result.status == "error") {
            setErrors(result?.body)
          }
          return result
        })
        .catch(error => {
          console.error(error)
          setLoading(false)
          return error
        })
    },
    [setLoading, setErrors, shopifyStore, functions],
  )

  const customerSubscribe = useCallback(
    async (email, tags = []) => {
      const { customer } = await request("/customerSubscribe", { email, tags })
      return { customer }
    },
    [request],
  )

  const customerTags = useCallback(
    async tags => {
      const id = decodeBase64(customer?.id)
      const { userErrors } = await request("/customerTags", { id, tags })
      if (!userErrors?.length)
        setCustomer(prevState => ({ ...prevState, tags }))
    },
    [customer, decodeBase64, request, setCustomer],
  )

  const customerMultipass = useCallback(
    async firebaseToken => {
      const { multipassToken } = await request("/customerMultipass", {
        firebaseToken,
      })
      return { multipassToken }
    },
    [request],
  )

  const checkoutMultipass = useCallback(
    async (customerEmail, checkoutId, webUrl) => {
      if (customerEmail) {
        const response = await request("/checkoutMultipass", {
          customerEmail,
          checkoutId,
          webUrl,
        })
        const url =
          response?.status !== "error" && response?.includes("https://")
            ? response
            : webUrl
        window.location.replace(url)
      } else {
        window.location.replace(webUrl)
      }
    },
    [request],
  )

  const formSubmission = useCallback(
    async (form, data, ipData, userAgent) => {
      const id: string = form?.id
      const type: string = form?.types[
        data?.type?.toLowerCase()
      ]?.title.toLowerCase()
      const subject: string =
        form?.subject || `Form submission from ${form?.title}`
      const { status, body } = await request("/formSubmission", {
        id,
        type,
        subject,
        data,
        ipData,
        userAgent,
      })
      return { status, body }
    },
    [request],
  )

  const backInStock = useCallback(
    async (email, variant, product, url) => {
      const { success } = await request("/backInStock", {
        email,
        variant,
        product,
        url,
      })
      return success
    },
    [request],
  )
  
  const getGiftcard = useCallback(
    async args => {
      const { number, pin } = args

      // const resp = await request("/getGiftcard", { number, pin })
      const resp = await requestGatsby("getGiftcard", { number, pin })

      return resp
    },
    [requestGatsby]
  )
  
  return {
    getGiftcard,
    customerSubscribe,
    customerTags,
    customerMultipass,
    checkoutMultipass,
    formSubmission,
    backInStock,
    loading,
    errors,
  }
}
