import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNavigation } from "../../../../../../hooks/useNavigation"
import { useApp } from "../../../../../../hooks/useApp"
import { useCustomerContext } from "../../../../../../hooks/useCustomer"
import { getAdditionalText } from "../../../../../../utils/getAdditionalText"

export const withNavigationHeaderMobileUtils = Component => ({
  name = "NavigationHeaderMobileUtils",
}) => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { customer } = useCustomerContext()

  const { mapItems } = useNavigation()

  const {
    template: {
      global: { additionalFindAStoreText, additionalMyAccountText },
    },
  } = getAdditionalText()

  const { menus } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_MOBILE_UTILS {
      menus: sanitySettingMenus {
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  `)

  const { footer } = menus || {}

  const items = useMemo(() => mapItems(footer?.items), [
    footer?.items,
    mapItems,
  ])

  Component.displayName = name
  return (
    <Component
      items={items}
      accountUrl={customer ? routes.DASHBOARD : routes.LOGIN}
      storeUrl={routes.STORE}
      additionalFindAStoreText={additionalFindAStoreText}
      additionalMyAccountText={additionalMyAccountText}
    />
  )
}
