import React, { useCallback } from "react"
import { useApp } from "../../../../hooks/useApp"
import { useWindowSize } from "../../../../hooks/useWindowSize"

export const withCardSwatches = Component => ({
  name = "CardSwatches",
  products,
  defaultProductHandle,
  activeProductHandle,
  setActiveProductHandle,
  setHoverActiveProductHandle,
}) => {
  const {
    config: {
      settings: {
        tags: { swatchColor },
      },
    },
  } = useApp()

  const { windowSize } = useWindowSize()

  const { width } = windowSize

  const isMobile = width < 768

  const getSwatchColour = useCallback(
    tags => {
      const found = tags?.find(tag => {
        const ret = tag.includes(swatchColor)
        return ret
      })
      const split = found?.split(":")
      const ret = split?.[split?.length-1]
      return ret?.replace(/\//g, "")
    },
    [swatchColor],
  )

  const swatches = products.map(product => {
    return {
      ...product,
      swatchImage: `https://res.cloudinary.com/propel-group/RB%20SELLARS%20Swatch/${getSwatchColour(
        product.tags,
      )}`,
    }
  })

  Component.displayName = name
  return (
    <Component
      swatches={swatches}
      defaultProductHandle={defaultProductHandle}
      activeProductHandle={activeProductHandle}
      setActiveProductHandle={setActiveProductHandle}
      setHoverActiveProductHandle={setHoverActiveProductHandle}
      isMobile={isMobile}
    />
  )
}
