import React, { useMemo } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useNavigation } from "../../../hooks/useNavigation"

export const withNavigationTerms = Component => ({ name = "NavigationTerms" }) => {
  const { mapItems } = useNavigation()

  const { menus } = useStaticQuery(graphql`
    query SANITY_SETTINGS_TERMS_MENU {
      menus: sanitySettingMenus {
        terms {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  `)

  const { terms } = menus || {}

  const items = useMemo(() => mapItems(terms?.items), [terms?.items, mapItems])

  Component.displayName = name
  return <Component items={items} />
}
