import React from "react"

import { withFreeGiftPicker } from "./withFreeGiftPicker"
import { Wrapper, CardList, CardWrapper } from "./FreeGiftPickerStyles"
import { StyledButton } from "../Styled/Button"
import { Modal, ModalOpenButton, ModalContentsWithLayout } from "../Modal/Modal"
import { FreeGiftCard } from "../Product/Card/FreeGift/FreeGiftCard"
import { RichText } from "../RichText/RichText"

export const FreeGiftPicker = withFreeGiftPicker(({ items, description }) => {

  return (
    <Modal>
      <Wrapper>
        <ModalOpenButton>
          <StyledButton wide size="primary">
            <span>Select your gift here</span>
          </StyledButton>
        </ModalOpenButton>
      </Wrapper>
      <ModalContentsWithLayout layout={"freeGift"}>
        <RichText>{description}</RichText>
        <CardList>
          {items.map(product => {
            return (
              <CardWrapper key={product.id}>
                <FreeGiftCard product={product} />
              </CardWrapper>
            )
          })}
        </CardList>
      </ModalContentsWithLayout>
    </Modal>
  )
})
