import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  relative w-full mb-0-8
`

export const VideoCoverImage = tw.div`
  relative
`

export const IconWrapper = styled.button`
  ${tw`absolute inset-0 w-full h-full flex items-center justify-center focus:outline-none`}
  span {
    ${tw`z-10`}
  }
`
