import React from "react"

import { withAddToCart } from "./withAddToCart"
import { Wrapper, InnerWrapper, Message, StyledSelect } from "./AddToCartStyles"
import { BackInStock } from "../BackInStock/BackInStock"
import { StyledButton } from "../../Styled/Button"

export const AddToCart = withAddToCart(
  ({
    handleAddToCart,
    loading,
    selectedVariant,
    message,
    buttonText,
    isRestock,
    isOutOfStock,
    restockFormActive,
    setRestockFormActive,
    variantId,
    productId,
    url,
    showQuantitySelector,
    quantityOptions,
    handleQuantityChange,
    layout,
    quantity,
  }): JSX.Element => (
    <>
      <Wrapper>
        {message && layout !== "wishlist" ? (
          <Message highlight={isOutOfStock || isRestock}>{message}</Message>
        ) : null}
        <InnerWrapper>
          {showQuantitySelector && (
            <StyledSelect
              value={quantity}
              options={quantityOptions}
              onChange={handleQuantityChange}
              layout={"productQuantity"}
            />
          )}
          <StyledButton
            wide
            size="primary"
            onClick={
              isRestock ? () => setRestockFormActive(true) : handleAddToCart
            }
            disabled={!selectedVariant || loading || isOutOfStock}
          >
            {buttonText}
          </StyledButton>
        </InnerWrapper>
      </Wrapper>
      <BackInStock
        restockFormActive={restockFormActive}
        setRestockFormActive={setRestockFormActive}
        variantId={variantId}
        productId={productId}
        url={url}
      />
    </>
  ),
)
