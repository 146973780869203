import React from "react"
import { Link } from "../../Link/Link"

import { withAnnouncement } from "./withAnnouncement"
import { Outer, Inner, Item, Content } from "./AnnouncementStyles"

export const Announcement = withAnnouncement(({ items, active, className }) =>
  items?.length > 0 ? (
    <Outer className={className}>
      <Inner>
        {items?.map((item, index) => (
          <Item key={item?.title} active={active === index}>
            {item?.link?.url ? (
              <Link
                url={item?.link?.url}
                title={item?.title}
                external={item?.link?.url}
                isExternal={item?.link?.isExternal}
                color={item?.color}
              />
            ) : (
              <Content color={item?.color?.hex}>{item?.title}</Content>
            )}
          </Item>
        ))}
      </Inner>
    </Outer>
  ) : null,
)
