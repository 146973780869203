import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { withButtonLink } from "./withButtonLink"
import { StyledButton, UnderlinedButton } from "../Styled/Button"

// Navy fill with white text
// White fill with navy text
// Red fill with white text (sale)
// White fill with red text (sale)
// White border with white text
// White border with white text and navy hover
// Black border with black text

export const ButtonLink = withButtonLink(
  ({ className, link, style, size, wide }) =>
    style?.includes("underlinedLink") ? (
      <UnderlinedButton
        className={className}
        as={
          link?.url && (link?.isExternal || link?.external ? `a` : GatsbyLink)
        }
        href={(link?.isExternal || link?.external) && link?.url}
        target={link?.external && `_blank`}
        title={link?.title}
        to={!link?.isExternal && link?.url}
        colour={style}
        size={size}
      >
        <span>{link?.title}</span>
      </UnderlinedButton>
    ) : (
      <StyledButton
        className={className}
        as={link?.url && (link?.isExternal ? `a` : GatsbyLink)}
        href={link?.isExternal && link?.url}
        target={link?.external && `_blank`}
        title={link?.title}
        to={!link?.isExternal && link?.url}
        colour={style}
        size={size}
        wide={wide}
      >
        {link?.title}
      </StyledButton>
    ),
)
