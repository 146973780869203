import React, { useState } from "react"

export const ShopContext: any = React.createContext({})

export const ShopProvider = ({ children }): JSX.Element => {
  const [shop, setShop] = useState(false)

  const values = {
    shop,
    setShop,
  }

  return <ShopContext.Provider value={{ ...values }}>{children}</ShopContext.Provider>
}
