import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const MenuButton = tw.button`
  flex flex-row items-center mlg:hidden
`

export const Text = tw.p`
  text-12 font-bold tracking-10 leading-2 hidden mlg:block mr-3-2 uppercase
`

export const StyledIcon = styled(Icon)`
  ${tw`mr-1-6`}
`
