import tw, { styled, css } from "twin.macro"

export const Outer = tw.div`
  relative
`

export const Inner = styled.div`
  ${tw`absolute inset-0 w-full h-full flex justify-center items-center`}
`

export const Content = styled.div`
  ${({ top, left, mobileTop, mobileLeft }) => {
    if (top || left || mobileTop || mobileLeft) {
      return tw`absolute transform`
    }
  }}

  ${({ top, mobileTop }) => {
    if (!top && mobileTop) {
      return css`
        @media screen and (max-width: 768px) {
          top: ${mobileTop}%;
        }
      `
    }
    if (top && mobileTop) {
      return css`
        @media screen and (max-width: 768px) {
          top: ${mobileTop}%;
        }
        @media screen and (min-width: 769px) {
          top: ${top}%;
        }
      `
    }
    if (top && !mobileTop) {
      return css`
        top: ${top}%;
      `
    }
    return null
  }}

  ${({ top, mobileTop }) => {
    if (!top && mobileTop) {
      return tw`-translate-y-1/2 md:translate-y-0`
    }
    if (top) {
      return tw`-translate-y-1/2`
    }

    return null
  }}

  ${({ left, mobileLeft }) => {
    if (!left && mobileLeft) {
      return css`
        @media screen and (max-width: 768px) {
          left: ${mobileLeft}%;
        }
      `
    }
    if (left && mobileLeft) {
      return css`
        @media screen and (max-width: 768px) {
          left: ${mobileLeft}%;
        }
        @media screen and (min-width: 769px) {
          left: ${left}%;
        }
      `
    }
    if (left && !mobileLeft) {
      return css`
        left: ${left}%;
      `
    }
    return null
  }}

  ${({ left, mobileLeft }) => {
    if (left) {
      return tw`-translate-x-1/2`
    }
    if (!left && mobileLeft) {
      return tw`-translate-x-1/2 md:translate-x-0`
    }
    return null
  }}
`
