import tw, { styled } from "twin.macro"
import { StyledContainer } from "../../../Styled/Container"

export const StyledNavigationHeaderDesktop = styled.nav`
  ${tw`flex items-center justify-center w-full transition-all duration-300 flex-row bg-white`}
`

export const Container = styled(StyledContainer)`
  ${tw`relative flex flex-row justify-between max-w-full`}
`

export const SearchWrapper = tw.div`
  py-1-6 ml-1 pr-4 lg:pr-6 pl-1 lg:pr-2 border-l border-r border-grey-lightest flex items-center
`

export const BorderWrapper = tw.div`hidden mlg:block mlg:border-b mlg:border-grey-lightest`
