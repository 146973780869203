import gql from "graphql-tag"

export const IMAGE_FRAGMENT = gql`
  fragment ImageFragment on Image {
    id
    altText
    originalSrc
    transformedSrc
  }
`
