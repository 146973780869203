import tw, { styled } from "twin.macro"

export const ResultsWrapper = styled.div`
  ${tw`relative mb-2`}

  .instant-search-results {
    ${tw`grid grid-cols-2 gap-1`}
  }

  @media (min-width: 769px) {
    .instant-search-results {
      ${tw`grid-cols-5 gap-2`} 
    }
  }
`
export const Wrapper = tw.div`
  md:px-4 pt-2 pb-4 flex-1 flex flex-col justify-between
`