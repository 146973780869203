import React from "react"

import { useApp } from "../../../hooks/useApp"

export const withInstant = Component => ({ name = "Instant" }: any) => {

  const {
    config: {
      services: {
        reactify: { index },
        shopify: { defaultShopName }
      },
    },
  } = useApp()

  Component.displayName = name
  return <Component index={index} shopifyPermanentDomain={`${defaultShopName}.myshopify.com`} />
}
