import React from "react"

import { withCounter } from "./withCounter"
import {
  CounterWrapper,
  CounterNumberRow,
  CounterNumberContainer,
  CounterNumber,
  CounterNumberText,
  CounterLabel,
} from "../AnnouncementStyles"

export const Counter = withCounter(
  ({ numbers, label, width, color }): JSX.Element => (
    <CounterWrapper width={width}>
      <CounterNumberRow>
        {numbers.map((number, index) => (
          <CounterNumberContainer key={index}>
            <CounterNumber>
              <CounterNumberText color={color}>{number}</CounterNumberText>
            </CounterNumber>
          </CounterNumberContainer>
        ))}
      </CounterNumberRow>

      <CounterLabel colour={"white"} color={color}>
        {label}
      </CounterLabel>
    </CounterWrapper>
  ),
)
