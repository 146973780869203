import tw, { styled } from "twin.macro"

export const List = tw.ul`
  flex flex-col px-2-4 md:px-7-5
`

export const ListItem = tw.li`
  font-bold text-16 leading-1.25 tracking-10 py-2-4 uppercase border-b border-grey-lightest
`

export const SubListButton = styled.button`
  ${tw`font-bold text-16 leading-1.25 tracking-10 uppercase`}
  color: ${({ color }) => color};
`

export const SubList = tw.ul`
  mt-0-2 mb-0-4
`

export const SubItem = tw.li`
  font-normal text-18 leading-2
`
