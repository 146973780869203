import React from "react"

import { withNavigationHeaderMobile } from "./withNavigationHeaderMobile"
import {
  Wrapper,
  StyledNavigationHeaderMobile,
} from "./NavigationHeaderMobileStyles"
import { NavigationHeaderMobileMain } from "./Main/NavigationHeaderMobileMain"

export const NavigationHeaderMobile = withNavigationHeaderMobile(
  ({ active }) => (
    <Wrapper>
      <StyledNavigationHeaderMobile active={active}>
        <NavigationHeaderMobileMain />
      </StyledNavigationHeaderMobile>
    </Wrapper>
  ),
)
