import React, { useCallback, useEffect, useState } from "react"

import { useApp } from "../../../hooks/useApp"
import { useShopify } from "../../../hooks/useShopify"
import { useCore } from "../../../hooks/useCore"
import { animateScrollTo, position } from "../../../utils/animateScrollTo"
import { useResults } from "@usereactify/search"

export const withSearchList =
  Component =>
  ({
    name = "SearchList",
    product: rawProduct,
    isCollection,
    collectionHandle,
    collectionsObjForBreadCrumbs,
    size,
    prices,
    hideQuickView,
  }) => {
    const {
      helpers: { storage },
    } = useCore()
    const {
      config: {
        settings: { keys },
      },
      globalState,
    } = useApp()
    const { imageNormaliser } = useShopify()

    const resultsHook = useResults()
    const [, dispatch] = globalState
    const product = {
      ...rawProduct,
      images: rawProduct?.images?.map(image => imageNormaliser(image, 500)),
      hideQuickView: rawProduct?.tags.some(tag => tag === "manual-order" || tag === "bundle-container"),
    }

    useEffect(() => {
      if (isCollection && resultsHook?.resultStats?.numberOfResults !== undefined) {
        dispatch({
          type: "SET_RESULTS_STATS",
          payload: {
            currentPage: resultsHook.resultStats?.currentPage,
            numberOfResults: resultsHook.resultStats?.numberOfResults,
            pageSize: size,
          },
        })
      }
    }, [
      isCollection,
      size,
      resultsHook?.resultStats?.currentPage,
      resultsHook?.resultStats?.numberOfResults,
      dispatch,
    ]) 

    useEffect(() => {
      if (!isCollection && resultsHook?.resultStats?.numberOfResults !== undefined) {
        dispatch({
          type: "SET_SEARCH_RESULTS",
          payload: resultsHook.resultStats.numberOfResults,
        })
      }
    }, [resultsHook?.resultStats?.numberOfResults, isCollection, dispatch]) 

     useEffect(() => {
      if (isCollection) {
        const lastScrollString = storage.get(keys?.collection_scroll_position)
        try {
          const lastScroll = JSON.parse(lastScrollString)
          const currentTime = new Date().getTime()

          if (
            lastScroll?.collectionHandle === collectionHandle &&
            currentTime - lastScroll.clickedTime < 30000
          ) {
            animateScrollTo(lastScroll?.position)
          }
        } catch (error) {
          throw new Error("last scroll position object parse error")
        }
      }
    }, [
      collectionHandle,
      isCollection,
      keys?.collection_scroll_position,
      storage,
    ]) 

    const handleClick = () => {
      const currentPosition = position()
      const clickedTime = new Date().getTime()

      storage.set(
        keys?.collection_scroll_position,
        JSON.stringify({
          collectionHandle,
          position: currentPosition,
          clickedTime,
        }),
      )
    }

     const notEmpty =
      !resultsHook?.resultStats?.numberOfResults && resultsHook?.resultStats?.numberOfResults !== 0

    Component.displayName = name

    return (
      <Component
        prices={prices}
        product={product}
        size={size}
        notEmpty={notEmpty}
        handleClick={handleClick}
        collectionsObjForBreadCrumbs={collectionsObjForBreadCrumbs}
        hideQuickView={hideQuickView}
      />
    )
  }
