import tw, { styled } from "twin.macro"

export const Clickable = styled.div<ClickAbleProps>`
  ${tw`block`}
  ${({ onClick }) => (onClick ? tw`cursor-pointer` : null)}
  ${({ stretch }) => (stretch === "true" ? tw`h-full` : null)}
`

type ClickAbleProps = {
  stretch: "true" | "false"
}
