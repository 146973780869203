import React, { useEffect, useState } from "react"

import { useCheckoutContext } from "../../../../hooks/useCheckout"

export const withCartItemPrice = Component => ({
  name = "CartItemPrice",
  variant,
  discountAllocationsAmount,
  layout,
}) => {
  const { checkout } = useCheckoutContext()

  const [price, setPrice] = useState("")
  const [compareAtPrice, setCompareAtPrice] = useState("")

  useEffect(() => {
    if (checkout?.currencyCode) {
      const price =
        discountAllocationsAmount > 0
          ? variant?.priceV2?.amount - discountAllocationsAmount
          : variant?.priceV2?.amount
      const compareAtPrice = parseFloat(variant?.compareAtPriceV2?.amount) > 0
        ? variant?.compareAtPriceV2?.amount
        : discountAllocationsAmount > 0
        ? variant?.priceV2?.amount
        : 0
      setPrice(price)
      setCompareAtPrice(compareAtPrice)
    }
  }, [checkout?.currencyCode, discountAllocationsAmount, variant])

  const formattedPrice = price ? Number(price).toFixed(2) : ""
  const formattedComparedAtPrice = compareAtPrice
    ? Number(compareAtPrice).toFixed(2)
    : ""

  const isOnSale = Number(formattedComparedAtPrice) > Number(formattedPrice)

  const percentage = isOnSale
    ? ((1 - Number(price) / Number(compareAtPrice)) * 100).toFixed(0)
    : undefined

  const isFree = percentage === "100"

  Component.displayName = name
  return (
    <Component
      isOnSale={isOnSale}
      price={formattedPrice}
      compareAtPrice={formattedComparedAtPrice}
      percentage={percentage}
      layout={layout}
      isFree={isFree}
    />
  )
}
