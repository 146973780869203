import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"

import { useFunctions } from "../../../hooks/useFunctions"

export const withBackInStock = Component => ({ name = "BackInStock", restockFormActive, setRestockFormActive, variantId, productId, url }) => {
  const { backInStock, loading } = useFunctions()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_BACK_IN_STOCK {
      productTemplateData: sanityTemplateProduct {
        restockTitle
        restockDescription
        restockSubmittingButtonText
        restockSubmitButtonText
        restockSuccessTitle
        restockSuccessDescription
      }
    }
  `)

  const { restockTitle, restockDescription, restockSubmittingButtonText, restockSubmitButtonText, restockSuccessTitle, restockSuccessDescription } =
    productTemplateData || {}

  const [email, setEmail] = useState("")
  const [sent, setSent] = useState(false)

  const handleChange = ({ target: { value } }) => {
    setEmail(value)
  }

  const handleSubmit = async event => {
    event.preventDefault()

    const success = await backInStock(email, variantId, productId, url)

    if (success) {
      setSent(true)
    }
  }

  Component.displayName = name
  return (
    <Component
      restockFormActive={restockFormActive}
      setRestockFormActive={setRestockFormActive}
      sent={sent}
      loading={loading}
      handleSubmit={handleSubmit}
      email={email}
      handleChange={handleChange}
      restockTitle={restockTitle}
      restockDescription={restockDescription}
      restockSubmitButtonText={restockSubmitButtonText}
      restockSubmittingButtonText={restockSubmittingButtonText}
      restockSuccessTitle={restockSuccessTitle}
      restockSuccessDescription={restockSuccessDescription}
    />
  )
}
