import React, { useState, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

export const withAnnouncement = Component => ({
  name = "Announcement",
  global,
  className,
}) => {
  const { announcementsData, announcementsDataRegional } = useStaticQuery(graphql`
    query SANITY_SETTINGS_HEADER_ANNOUNCEMENTS {
      announcementsData: sanitySettingAnnouncement {
        duration
        headerAnnouncements {
          title
          color {
            hex
          }
          link: _rawLink(resolveReferences: { maxDepth: 2 })
        }
      }
      announcementsDataRegional: sanitySettingAnnouncementRegional {
        duration
        headerAnnouncements {
          title
          color {
            hex
          }
          link: _rawLink(resolveReferences: { maxDepth: 2 })
        }
      }
    }
  `)
  
  
  const data = global ? announcementsDataRegional : announcementsData
    
  const { duration = 10, headerAnnouncements: items } = data || {}

  const [active, setActive] = useState(0)
  const waiting = duration || 10

  const length = items?.length - 1

  useEffect(() => {
    if (length) {
      const interval = setInterval(() => {
        setActive(active => (active === length ? 0 : active + 1))
      }, waiting * 1000)
      return () => clearInterval(interval)
    }
  }, [waiting, length])

  Component.displayName = name
  return <Component items={items} active={active} className={className} />
}
