import tw, { styled, css } from "twin.macro"
import { Buttons } from "../../TextButtonsGrid/TextButtonsGridStyles"

export const Wrapper = tw.div`
  grid grid-cols-2 gap-x-1-5 gap-y-3 mlg:grid-cols-3 mlg:gap-x-3 mlg:gap-y-4
`

export const CalloutWrapper = styled.div`
  ${tw`relative`}
  min-height: 370px;
  background-color: ${({ backgroundColor }) => backgroundColor};
  img {
    position: relative!important;
  }

  @media (max-width: 768px) {
    // max-height: 370px;
    // grid-column: span 2;
    ${({ visibility }) => visibility === "desktop" && tw`hidden`}
    ${({ visibility }) => visibility === "all" || visibility === "mobile" && tw`block`}
  }

  @media (min-width: 769px) {
    ${({ visibility }) => visibility === "mobile" && tw`hidden`}
    ${({ visibility }) => visibility === "all" || visibility === "desktop" && tw`block`}

    ${({ cols, rows}) =>
    css`
        grid-column: auto / span ${cols};
        grid-row: auto / span ${rows};
    `}
  }
`

export const CalloutContent = styled.div`
  ${tw`absolute inset-0 w-full h-full flex flex-col items-center justify-center gap-2 px-1`}
  color: ${({ textColor }) => textColor};
`

export const CalloutButtons = styled(Buttons)`
${tw`flex-col md:flex-row gap-1 md:gap-2`}
a {
  ${tw`w-full`}
}
}
`