import React from "react"

import { Column, Row, Scroll, Table as Wrapper } from "./TableStyles"

export const Table = ({ chart, layout = "", className = "" }) =>
  chart ? (
    <Scroll className={className}>
      <Wrapper>
        {chart?.rows?.map((row, index) => (
          <Row key={`${row?.key}_${index}`}>
            {row?.cells?.map((column, index) => (
              <Column
                cols={row?.cells?.length}
                key={`${column}_${index}`}
                layout={layout}
              >
                {<div dangerouslySetInnerHTML={{__html: column}}></div> || (
                  <div dangerouslySetInnerHTML={{ __html: `&nbsp;` }} />
                )}
              </Column>
            ))}
          </Row>
        ))}
      </Wrapper>
    </Scroll>
  ) : null
