import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  flex flex-row items-start
`

export const PriceTag = styled.p`
  ${({ layout, percentage }) => {
    if (layout === "product") {
      return !percentage
        ? tw`font-bold text-16 md:text-25 leading-1.25 md:leading-1.4 tracking-10`
        : tw`font-semibold text-14 leading-1.71 tracking-10 mt-0-4`
    }
    if (layout === "card") return tw`font-medium text-14 leading-1.4 tracking-5`
    if (layout === "cart")
      return tw`text-14 md:text-18 leading-1.78 md:leading-1.56 `
    if (layout === "drawer") return tw`text-14 leading-1.78 text-right`
  }}
  ${({ sale }) => (sale ? tw`text-red` : null)}
  ${({ strike }) => (strike ? tw`line-through mr-0-2 text-grey` : null)}
`

export const Outer = styled.div`
  ${tw`flex`}
  ${({ layout }) =>
    layout === "drawer" || layout === "product" || layout === "card"
      ? tw`flex-col`
      : `flex-row`}
  ${({ layout }) => (layout === "card" ? tw`md:flex-row` : null)}
`
