import React from "react"

import { withCookies } from "./withCookies"
import { Outer, Inner, Close, Content } from "./CookiesStyles"
import { RichText } from "../RichText/RichText"
import { StyledButton } from "../Styled/Button"

export const Cookies = withCookies(
  ({
    cookies,
    handleHide,
    active,
    additionalCookieAcceptButtonText,
  }): JSX.Element =>
    active ? (
      <Outer>
        <Inner width="mlg">
          <Content>
            <RichText>{cookies}</RichText>
          </Content>
          <Close>
            <StyledButton size="tertiary" onClick={() => handleHide()}>
              {additionalCookieAcceptButtonText}
            </StyledButton>
          </Close>
        </Inner>
      </Outer>
    ) : null,
)
