import React from "react"
import { Link } from "gatsby"

import { withCartEmpty } from "./withCartEmpty"
import { Wrapper, Title } from "./CartEmptyStyles"
import { UnderlinedButton } from "../../Styled/Button"

export const CartDrawerEmpty = withCartEmpty(
  ({ continueLink, emptyDrawerMessage, handleClose }) => (
    <Wrapper>
      <Title>{emptyDrawerMessage}</Title>
      <div>
        <UnderlinedButton
          size="tertiary"
          as={Link}
          to={continueLink.url}
          onClick={handleClose}
        >
          <span>{continueLink.title}</span>
        </UnderlinedButton>
      </div>
    </Wrapper>
  ),
)
