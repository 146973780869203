import tw, { styled } from "twin.macro"

export const Form = styled.form`
  ${tw`flex-1 md:border-l md:border-r border-grey-lightest`}
  input {
    ${tw`w-full pl-3 h-5-6 md:pl-5 border-0 bg-white text-16`}
    :focus {
      ${tw`bg-white`}
    }
    ::placeholder {
      ${tw`text-grey opacity-100`}
    }
  }
`

export const InputWrapper = styled.div`
  ${tw`relative flex flex-row`}
`

export const Close = tw.button`
  absolute top-2-1 right-3-2 md:right-4-3
`

export const Search = tw.div`
  absolute top-2 left-0-4 md:left-2-4
`

export const Hidden = tw.div`
  hidden
`
