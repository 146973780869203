import tw, { styled } from "twin.macro"
import { TinyBody } from "../Styled/Text"

export const TextLengthLimitationMessage = styled(TinyBody)`
  ${tw`-mt-1-2 mb-1-6 text-grey`}
`

export const ErrorMessage = styled(TinyBody)`
  ${tw`-mt-1-2 mb-1-6 text-red`}
`
