import { useState, useEffect, useCallback, useContext, useMemo } from "react"
import { navigate } from "gatsby"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useRoutes } from "./useRoutes"
import { useShopify } from "./useShopify"
import { WishlistContext } from "../providers/wishlist"
import { useQueries } from "./useQueries"
import { useLocation } from "./useLocation"

export const useWishlistContext = () => {
  const wishlistData: any = useContext(WishlistContext)

  return { ...wishlistData }
}


export const useWishlist = () => {
  const {
    helpers: { storage, encodeBase64, decodeBase64 },
  } = useCore()

  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const {
    config: {
      app,
      settings: { keys, routes, params, constraints: { productMetafields } },
    },
  } = useApp()
  const { location } = useLocation()
  const { getUrlParameter } = useRoutes()
  const { wishlist, setWishlist } = useWishlistContext()
  const { client, productNormaliser } = useShopify()
  const [loading, setLoading] = useState(false)
  const [shareUrl, setShareUrl] = useState(null)
  const [sharedWishlist, setSharedWishlist] = useState([])
  const savedWishlist = storage.get(keys?.wishlist)
  const allowed = useMemo(
    () => [
      "id",
      "selectedSku",
      "selectedTitle",
      "handle",
      "title",
      "vendor",
      "image",
      "images",
      "tags",
      "metafields",
    ],
    [],
  )

  const filterData = useCallback(
    (allowed, data) =>
      Object.keys(data)
        .filter(key => allowed.includes(key))
        .reduce((obj, key) => {
          obj[key] = data[key]
          return obj
        }, {}),
    [],
  )


  const formatData = useCallback(
    data => ({
      ...filterData(allowed, data),
    }),
    [filterData, allowed],
  )



  const getWishlist = useCallback(
    async items => {
      const { data: wishlistLiveData } = await client.query({
        query: GET_PRODUCTS_BY_HANDLE(items?.map(product => product?.handle)),
        variables: {
          countryCode: location,
          firstImages: 15,
          firstMedia: 15,
          firstVariants: 30,
          firstCollections: 1,
          metafieldIdentifiers: productMetafields,
        },
      })
      setWishlist(
        items
          ?.filter(
            item =>
              item?.handle ===
              wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`]
                ?.handle,
          )
          ?.map(item => ({
            ...item,
            ...productNormaliser(
              wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`],
            ),
          })),
      )
    },
    [client, GET_PRODUCTS_BY_HANDLE, productMetafields, setWishlist, productNormaliser, location],
  )

  // initialize wishlist, only run once when first load
  useEffect(() => {
    if (savedWishlist?.length) {
      setWishlist(savedWishlist)
      getWishlist(savedWishlist)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [savedWishlist?.length])

  useEffect(() => {
    storage.set(keys?.wishlist, wishlist || [])
  }, [wishlist, keys?.wishlist, storage])

  const getSharedWishlist = useCallback(async () => {
    const shared = getUrlParameter(params?.wishlist)

    const items = decodeBase64(shared)
      .split("|")
      .map(item => ({
        handle: item.split(":")[0],
        selectedSku: item.split(":")[1],
      }))

    const { data: wishlistLiveData } = await client.query({
      query: GET_PRODUCTS_BY_HANDLE(items?.map(product => product?.handle)),
      variables: {
        countryCode: location,
        firstImages: 15,
        firstMedia: 15,
        firstVariants: 30,
        firstCollections: 1,
        metafieldIdentifiers: productMetafields,
      },
    })

    setSharedWishlist(
      items?.map(item => ({
        ...item,
        ...productNormaliser(
          wishlistLiveData[`product${item?.handle?.replace(/-/g, "")}`],
        ),
      })),
    )
  }, [
    decodeBase64,
    client,
    location,
    productMetafields,
    GET_PRODUCTS_BY_HANDLE,
    setSharedWishlist,
    productNormaliser,
    getUrlParameter,
    params?.wishlist,
  ])

  const addToWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist(prevState =>
        wishlist?.length ? [...prevState, mappedData] : [mappedData],
      )
      setLoading(false)
    },
    [setLoading, setWishlist, wishlist, formatData],
  )

  const deleteFromWishlist = useCallback(
    async handle => {
      setLoading(true)
      await setWishlist(prevState =>
        prevState.filter(item => item?.handle !== handle),
      )
      setLoading(false)
    },
    [setLoading, setWishlist],
  )

  const existsInWishlist = useCallback(
    handle => wishlist.some(item => item?.handle === handle),
    [wishlist],
  )

  const updateWishlist = useCallback(
    async data => {
      setLoading(true)
      const mappedData = formatData(data)
      await setWishlist(prevState => [
        ...prevState.filter(item => item?.id !== data?.id),
        mappedData,
      ])
      setLoading(false)
    },
    [setLoading, setWishlist, formatData],
  )

  const shareWishlist = useCallback(() => {
    setLoading(true)
    const string = wishlist
      ?.map(item => `${item.handle}:${item.selectedSku}`)
      .join("|")
    const url = `${app?.url}${routes.SAVED}?id=${encodeBase64(string)}`
    setShareUrl(url)
    setLoading(false)
    return url
  }, [encodeBase64, setLoading, setShareUrl, wishlist, app?.url, routes.SAVED])

  const saveWishlist = useCallback(() => {
    setLoading(true)
    setWishlist(sharedWishlist)
    setSharedWishlist([])
    navigate(`${routes.SAVED}`, { replace: true })
    setLoading(false)
  }, [setLoading, setWishlist, setSharedWishlist, sharedWishlist, routes])

  return {
    wishlist,
    savedWishlist,
    sharedWishlist,
    getWishlist,
    getSharedWishlist,
    addToWishlist,
    deleteFromWishlist,
    updateWishlist,
    shareWishlist,
    saveWishlist,
    existsInWishlist,
    shareUrl,
    loading,
  }
}
