import React from "react"
import {
  StyledSelectWrapper,
  StyledDropdownSelect,
  StyledSelectOption,
  StyledInputIconWrapper,
  StyledLabel,
} from "../Styled/Form"
import { Icon } from "../Icon/Icon"

export const Select = ({
  options = [],
  label = "",
  defaultOption = "",
  className = "",
  ...props
}) => (
  <div className={className}>
    {label?.length > 0 && <StyledLabel>{label}</StyledLabel>}
    <StyledSelectWrapper>
      <StyledDropdownSelect {...props}>
        {defaultOption?.length > 0 && (
          <StyledSelectOption disabled value={""} selected>
            {defaultOption}
          </StyledSelectOption>
        )}
        {options?.map(option => (
          <StyledSelectOption key={option?.label} value={option?.value}>
            {option?.label}
          </StyledSelectOption>
        ))}
      </StyledDropdownSelect>
      <StyledInputIconWrapper>
        <Icon name={"chevron"} width={"12"} height={"12"} />
      </StyledInputIconWrapper>
    </StyledSelectWrapper>
  </div>
)
