import React from "react"

import { useBadge } from "../../../hooks/useBadge"

export const withBadge =
  Component =>
  ({
    name = "Badge",
    children,
    tags,
    layout,
    isOnSale = false,
    isSizeSoldOut = false,
  }) => {
    const { message, colour } = useBadge(tags)

    Component.displayName = name
    return (
      <Component
        message={message}
        layout={layout}
        isSizeSoldOut={isSizeSoldOut}
        isOnSale={isOnSale}
        colour={colour}
      >
        {children}
      </Component>
    )
  }
