import React from "react"

import { Icon } from "../../Icon/Icon"
import { withBrand } from "./withBrand"
import { Wrapper } from "./BrandStyles"

export const Brand = withBrand(({ url, width, height }) => (
  <Wrapper to={url} title={"RB Sellars homepage"}>
    <Icon
      name={"logo"}
      width={width}
      height={height}
      title={"RB Sellars brand logo"}
    />
  </Wrapper>
))
