import React from "react"

import { withFooter } from "./withFooter"
import {
  Bg,
  Top,
  Left,
  Right,
  Bottom,
  BorderWrapper,
  BottomLeft,
} from "./FooterStyles"
import { NavigationFooter } from "../Navigation/Footer/NavigationFooter"
import { SellingPoints } from "./SellingPoints/SellingPoints"
import { Subscribe } from "../Subscribe/Subscribe"
import { SocialMedia } from "../SocialMedia/SocialMedia"
import { HeadOffice } from "./HeadOffice/HeadOffice"
import { NavigationTerms } from "../Navigation/Terms/NavigationTerms"
import { PaymentIcons } from "./PaymentIcons/PaymentIcons"
import { FooterCopyright } from "./Copyright/FooterCopyright"

export const Footer = withFooter(() => (
  <>
    <SellingPoints />
    <Bg>
      <BorderWrapper>
        <Top>
          <Left>
            <NavigationFooter />
            <HeadOffice />
          </Left>
          <Right>
            <Subscribe />
            <SocialMedia />
          </Right>
        </Top>
      </BorderWrapper>
      <Bottom>
        <BottomLeft>
          <NavigationTerms />
          <FooterCopyright />
        </BottomLeft>
        <PaymentIcons />
      </Bottom>
    </Bg>
  </>
))
