import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useWishlistContext } from "../../../hooks/useWishlist"

export const withWishlistWidget = Component =>
  memo(({ name = "WishlistWidget" }: any) => {
    const {
      config: {
        settings: { routes },
      },
    } = useApp()
    const { count } = useWishlistContext()

    const wishlistUrl = routes?.WISHLIST

    Component.displayName = name
    return useMemo(() => <Component count={count} wishlistUrl={wishlistUrl} />, [wishlistUrl, count])
  })
