import React from "react"

import { useImage } from "../../hooks/useImage"
import { useRoutes } from "../../hooks/useRoutes"

export const withImageVideo =
  Component =>
  ({
    name = "ImageVideo",
    imageVideo = {},
    ratio = undefined,
    fullHeight = false,
  }) => {
    const { getResponsiveImage } = useImage()

    const { linkResolver } = useRoutes()

    const resolvedLink = linkResolver(imageVideo?.link)

    const {
      desktop,
      desktopAlt,
      desktopVideo,
      mobile,
      mobileAlt,
      mobileVideo,
    } = getResponsiveImage(imageVideo)

    Component.displayName = name
    return (
      <Component
        link={resolvedLink}
        desktop={desktop}
        desktopAlt={desktopAlt}
        desktopVideo={desktopVideo}
        mobile={mobile}
        mobileAlt={mobileAlt}
        mobileVideo={mobileVideo}
        ratio={ratio}
        autoplay={imageVideo?.autoplay}
        controls={imageVideo?.controls}
        fullHeight={fullHeight}
      />
    )
  }
