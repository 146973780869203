import React from "react"

import { StyledInput, StyledInputWrapper, StyledLabel } from "../Styled/Form"
import { withTextField } from "./withTextField"
import { TextLengthLimitationMessage, ErrorMessage } from "./TextFieldStyles"

export const TextField = withTextField(
  ({ label, name, value, limit, lengthLimitMessage, errors, ...props }) => {
    
    return (
      <StyledInputWrapper>
        <StyledLabel htmlFor={name}>{label}</StyledLabel>
        <StyledInput id={name} name={name} value={value} maxLength={limit} {...props} />
        {Boolean(limit) && Boolean(lengthLimitMessage) ? (
          <TextLengthLimitationMessage>
            {lengthLimitMessage.replace(
              "${amount}",
              (limit - value.length).toString(),
            )}
          </TextLengthLimitationMessage>
        ) : null}
        {typeof errors === 'string' && (
          <ErrorMessage>
            {errors}
          </ErrorMessage>
        )}
      </StyledInputWrapper>
    )
  }
)
