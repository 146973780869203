import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`mx-2-4 flex flex-col py-5 text-left`}
  div {
    ${tw`flex justify-center`}
  }
  a {
    ${tw`inline-block px-2-8`}
  }
`

export const Title = tw.p`
  text-16 leading-1.25 tracking-10 uppercase text-grey font-bold mb-3-2 text-center
`

export const Message = tw.p`
  text-center md:text-left text-20 leading-1.25 mb-3-2
`
