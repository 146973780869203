import React from "react"

import { withAnnouncement } from "./withAnnouncement"
import { AnnouncementBar } from "./AnnouncementBar"
import { CountdownBar } from "./CountdownBar"

export const Announcement = withAnnouncement(
  ({
    active,
    items,
    show,
    isAnnouncementHidden,
    displayCountdown,
    countdownItem,
    stickyCountdown,
    days,
    hours,
    minutes,
    seconds,
    backgroundColour
  }): JSX.Element =>
    displayCountdown ? (
      <CountdownBar
        show={show}
        countdownItem={countdownItem}
        stickyCountdown={stickyCountdown}
        days={days}
        hours={hours}
        minutes={minutes}
        seconds={seconds}
      />
    ) : (
      <AnnouncementBar
        show={show}
        items={items}
        active={active}
        isAnnouncementHidden={isAnnouncementHidden}
        backgroundColour={backgroundColour}
      />
    ),
)
