import React from "react"
import { withFreeGiftAddToCart } from "./withFreeGiftAddToCart"

import {
  StyledSelectWrapper,
  StyledSelect,
  StyledSelectOption,
  StyledInputIconWrapper,
} from "../../../../Styled/Form"
import { Icon } from "../../../../Icon/Icon"
import { Variants } from "./FreeGiftAddToCartStyles"
import { StyledButton } from "../../../../Styled/Button"

export const FreeGiftAddToCart = withFreeGiftAddToCart(
  ({
    addFreeGiftToCart,
    options,
    selectedOptions,
    activeVariant,
    handleVariant,
    SIZE,
    isOutOfStock,
    buttonText,
    loading,
  }) => (
    <>
      <Variants>
        {options?.map((option, index) =>
          option?.values.length > 1 || option?.name === SIZE ? (
            <StyledSelectWrapper key={option?.name?.toString() + index}>
              <StyledSelect
                layout={"cardVariant"}
                value={
                  selectedOptions?.find(({ name }) => name === option?.name)
                    ?.value || ""
                }
                onChange={({ target: { value } }) =>
                  handleVariant({ name: option.name, value })
                }
              >
                <StyledSelectOption disabled value={""} selected>
                  {option?.name}
                </StyledSelectOption>
                {option?.values?.map((value, index) => (
                  <StyledSelectOption
                    key={value?.toString() + index}
                    value={value}
                  >
                    {value}
                  </StyledSelectOption>
                ))}
              </StyledSelect>
              <StyledInputIconWrapper>
                <Icon
                  name={"chevron"}
                  className={`mb-0-2 mr-0-8`}
                  width={"12"}
                  height={"12"}
                />
              </StyledInputIconWrapper>
            </StyledSelectWrapper>
          ) : null,
        )}
      </Variants>
      <StyledButton
        wide
        size="primary"
        onClick={() => addFreeGiftToCart(activeVariant)}
        disabled={!activeVariant || isOutOfStock || loading}
      >
        {loading ? "Adding" : buttonText}
      </StyledButton>
    </>
  ),
)
