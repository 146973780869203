import tw, { styled } from "twin.macro"
import { LargeBody, TinyBody } from "../Styled/Text"
import { StyledButton } from "../Styled/Button"

export const Outer = styled.section`
  ${tw`w-full h-0 overflow-hidden transition-all duration-300 z-50`}
  background-color: ${({ backgroundColour }) => backgroundColour?.hex};
  ${({ active, countdown }) =>
    active && !countdown ? tw`h-3` : active && countdown ? tw`h-9` : null}
    ${({ countdown }) => (countdown ? tw`bg-beige` : '')}
`

export const Inner = tw.div`
  h-full relative flex flex-col items-center justify-around md:justify-center py-1
`

export const Wrapper = tw.div`
  flex items-center justify-center
`

export const Item = styled.div`
  ${tw`absolute inset-0 h-full flex items-center justify-center text-center transform transition-all duration-300 text-12 font-bold text-white uppercase tracking-15`}
  ${({ active }) =>
    active
      ? tw`opacity-100 visible translate-y-0`
      : tw`opacity-0 invisible -translate-y-6`}
`

export const Close = tw.button`
  absolute right-2-4 md:right-7-5 top-0 bottom-0 px-0-4 flex items-center text-white
`

export const Content = styled.p`
  color: ${({ color }) => (color ? color : undefined)};
`

export const HeadingWrapper = tw.div`
  flex flex-col items-center justify-center
`
export const Heading = styled(LargeBody)`
  ${tw`text-12 md:text-16 tracking-10 font-extrabold`}
  color: ${({ color }) => (color ? color : undefined)};
`
export const Subheading = styled(LargeBody)`
  ${tw`text-12 md:text-16 tracking-10`}
  color: ${({ color }) => (color ? color : undefined)};
`
export const CountdownWrapper = tw.div`
  flex flex-col min-w-10 items-center justify-center md:mx-2 md:px-2
`
export const CountdownInner = tw.div`
  flex w-full items-center justify-center mx-1
`
export const CountdownDivider = styled(LargeBody)`
  ${tw`sm:px-0-4 md:px-0-6 -mt-2-4 font-bold`}
  color: ${({ color }) => (color ? color : undefined)};
`
export const CounterWrapper = styled.div`
  ${tw`flex flex-col md:px-0-2 items-center justify-center`}
  ${({ width }) => (width === "3" ? tw`min-w-6 md:w-9` : tw`min-w-4 md:w-6`)}
`
export const CounterNumberRow = tw.div`flex flex-row w-full items-center justify-center`
export const CounterNumberContainer = tw.div`
  relative w-full h-2 md:h-3-2 bg-white rounded mx-0-2
`
export const CounterNumber = tw.div`
  absolute inset-0 w-full flex items-center justify-center text-center
`
export const CounterNumberText = styled(LargeBody)`
  ${tw`font-bold`}
  color: ${({ color }) => (color ? color : undefined)};
`
export const CounterLabel = styled(TinyBody)`
  ${tw`text-10 md:text-12 mt-0-6`}
  color: ${({ color }) => (color ? color : undefined)};
`

export const ButtonWrapper = styled.div`
  ${tw`h-full items-center justify-center`}
  ${({ desktop }) => (desktop ? tw`hidden md:flex` : tw`flex md:hidden`)}
`
export const Button = styled(StyledButton)`
  ${tw`py-0-2 md:py-1-6`}
  color: ${({ customColor }) => (customColor ? customColor : undefined)};
  border-color: ${({ customColor }) => (customColor ? customColor : undefined)};
`
