import { useState, useCallback, useEffect } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useRoutes } from "./useRoutes"
import { useLocation } from "./useLocation"

export const useAnnouncement = (global) => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
    globalState,
  } = useApp()
  
  const { location } = useLocation()

  const { linkResolver } = useRoutes()

  const { announcementsData, announcementsDataRegional } = useStaticQuery(graphql`
    query SANITY_SETTINGS_ANNOUNCEMENTS {
      announcementsData: sanitySettingAnnouncement {
        duration
        expiry
        announcements {
          title
          color {
            hex
          }
          region
          link: _rawLink(resolveReferences: { maxDepth: 2 })
        }
        backgroundColour {
          hex
        }
        footerCookies {
          cookiesContent: _rawCookiesContent(resolveReferences: { maxDepth: 6 })
        }
        countdowns {
          heading
          subheading
          color {
            hex
          }
          expireDateTime
          button: _rawButton(resolveReferences: { maxDepth: 2 })
        }
        enableCountdown
        stickyCountdown
        days
        hours
        minutes
        seconds
      }
      announcementsDataRegional: sanitySettingAnnouncementRegional {
        duration
        expiry
        announcements {
          title
          color {
            hex
          }
          region
          link: _rawLink(resolveReferences: { maxDepth: 2 })
        }
        backgroundColour {
          hex
        }
        footerCookies {
          cookiesContent: _rawCookiesContent(resolveReferences: { maxDepth: 6 })
        }
        countdowns {
          heading
          subheading
          color {
            hex
          }
          expireDateTime
          button: _rawButton(resolveReferences: { maxDepth: 2 })
        }
        enableCountdown
        stickyCountdown
        days
        hours
        minutes
        seconds
      }
    }
  `)
  
  const data = global ? announcementsDataRegional : announcementsData
  
  const {
    announcements,
    expiry = 7,
    duration = 10,
    footerCookies,
    countdowns = [],
    enableCountdown = false,
    backgroundColour
  } = data || {}
  
  const [active, setActive] = useState(0)

  const [{ activeSearch }, dispatch] = globalState

  const items =
    announcements?.filter(announcement => {
      if (global && announcement?.region !== "global" && location?.toLowerCase() !== announcement.region?.toLowerCase()) {
        return false
      } 
      return true
    })?.map(announcement => ({
      ...announcement,
      link: linkResolver(announcement.link),
    })) || []
  const waiting = duration || 10
  const length = items?.length - 1
  const cookies = footerCookies?.cookiesContent

  const countdownItem =
    countdowns?.length > 0
      ? {
          ...countdowns[0],
          button: linkResolver(countdowns[0].button),
          expireDateTime: countdowns[0].expireDateTime
            ? new Date(countdowns[0].expireDateTime)
            : Date.now(),
        }
      : null
      
  const displayCountdown = enableCountdown && !!countdownItem

  useEffect(() => {
    if (length && !activeSearch) {
      const interval = setInterval(() => {
        setActive(active => (active === length ? 0 : active + 1))
      }, waiting * 1000)
      return () => clearInterval(interval)
    }
  }, [waiting, length, activeSearch])

  const hide = useCallback(() => {
    storage.set(keys?.announcement, "hidden", expiry)
    dispatch({
      type: "HIDE_ANNOUNCEMENT",
    })
  }, [dispatch, storage, keys?.announcement, expiry])

  const hideCookies = useCallback(() => {
    storage.set(keys?.cookies, "hidden", expiry)
    dispatch({
      type: "HIDE_COOKIES",
    })
  }, [dispatch, storage, keys?.cookies, expiry])

  return {
    active,
    items,
    hide,
    cookies,
    hideCookies,
    countdownItem,
    displayCountdown,
    backgroundColour
  }
}
