import React, { memo, useMemo } from "react"

import { useApp } from "../../../hooks/useApp"
import { useCustomerAccessToken, useCustomerContext } from "../../../hooks/useCustomer"

export const withAccountWidget = Component =>
  memo(({ name = "AccountWidget" }: any) => {
    const {
      config: {
        settings: { routes },
      },
    } = useApp()

    const { customer } = useCustomerContext()

    const { getCustomer } = useCustomerAccessToken()

    if (!customer) getCustomer()

    const accountLink = routes?.DASHBOARD
    const loginLink = routes?.LOGIN

    Component.displayName = name
    return useMemo(() => <Component customer={customer} accountLink={accountLink} loginLink={loginLink} />, [customer, accountLink, loginLink])
  })
