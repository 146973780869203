import tw, { styled } from "twin.macro"

export const EmailForm = styled.form`
  ${tw`py-4-8 px-3-6 w-full bg-white`}
  button {
    ${tw`mt-0-8`}
  }
`

export const Title = tw.h3`
  text-20 leading-1.25 tracking-2 mb-1-6 uppercase font-bold max-w-22
`

export const Description = tw.p`
  text-14 leading-1.78 mb-2-4
`
