import React, { useState } from "react"
import { Helmet } from "react-helmet"
import { Script } from "gatsby"

import { withMeta } from "./withMeta"

export const Meta = withMeta(
  ({
    languages,
    metadata,
    metatags,
    schemas,
    facebookDomainVerify,
  }): JSX.Element => {
    const [loaded, setLoaded] = useState(false)
    return (
      <>
        <Helmet
          htmlAttributes={{ lang: metadata?.lang || "en" }}
          title={metadata?.title}
          meta={metatags}
        >
          <link href={metadata?.siteURL} rel="home" />
          <link href={metadata?.canonical} rel="canonical" />
          <meta name="description" content={metadata?.description} />
          {languages?.map(({ key, href, hrefLang, rel }, index) => (
            <link
              key={key?.toString() + index}
              href={href}
              hrefLang={hrefLang}
              rel={rel}
            />
          ))}
          {schemas.map((schema, index) => (
            <script type="application/ld+json" key={index}>
              {JSON.stringify(schema)}
            </script>
          ))}
          {facebookDomainVerify?.length > 0 && (
            <meta
              name="facebook-domain-verification"
              content={facebookDomainVerify}
            />
          )}
          <meta
            name="oke:subscriber_id"
            content="21156a86-5afd-49c2-bb02-cc604a0a29e3"
          />
        </Helmet>
        <Script
          async
          src="https://d3hw6dc1ow8pp2.cloudfront.net/reviews-widget-plus/js/okendo-reviews.js"
        />
        <Script
          defer
          src="https://d3hw6dc1ow8pp2.cloudfront.net/referrals/js/referrals-api.js"
          onLoad={() => setLoaded(true)}
        />
        {loaded && (
          <Script
            id="okeReferralSettings"
            type="application/json"
          >{`{"subscriberId":"21156a86-5afd-49c2-bb02-cc604a0a29e3"}`}</Script>
        )}

        <Script
          defer
          src="https://www.gstatic.com/firebasejs/8.2.3/firebase-app.js"
        />
        <Script src="https://www.gstatic.com/firebasejs/8.2.3/firebase-auth.js" />
        <Script
          defer
          src="https://www.gstatic.com/firebasejs/8.2.3/firebase-firestore.js"
        />
        <Script defer src="https://api.seeda.io/js/js?id=rbsellars" />
      </>
    )
  },
)
