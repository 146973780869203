import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useRoutes } from "../../../hooks/useRoutes"
import { useApp } from "../../../hooks/useApp"

export const withCartEmpty = Component => ({ name = "CartEmpty" }) => {
  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_EMPTY {
      cart: sanityPageCart {
        continueShopping: _rawContinueShopping(
          resolveReferences: { maxDepth: 2 }
        )
        emptyCartMessage
        emptyDrawerMessage
      }
    }
  `)

  const { continueShopping, emptyCartMessage, emptyDrawerMessage } = cart || {}

  const { globalState } = useApp()

  const [, dispatch] = globalState

  const handleClose = useCallback(() => dispatch({ type: "HIDE_CART" }), [
    dispatch,
  ])

  const { linkResolver } = useRoutes()

  const continueLink = linkResolver(continueShopping)

  Component.displayName = name
  return (
    <Component
      continueLink={continueLink}
      emptyCartMessage={emptyCartMessage}
      emptyDrawerMessage={emptyDrawerMessage}
      handleClose={handleClose}
    />
  )
}
