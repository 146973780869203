import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`flex-1 border-grey`

export const Grid = tw.div`
  grid grid-cols-8 gap-0-8
`

export const Variant = styled.button`
  ${tw`border border-navy rounded-5 py-1`}
  ${({ active }) => {
    if (active) {
      return tw`bg-navy text-white`
    } else {
      return tw`bg-transparent text-navy`
    }
  }}
  ${({ outOfStock }) => {
    if (outOfStock) {
      return tw`border-navy text-navy text-opacity-30 border-opacity-30 cursor-not-allowed`
    }
  }}
  ${({ active, outOfStock }) => {
    if (active && outOfStock) {
      return tw`bg-navy bg-opacity-20 border-navy border-opacity-20 text-navy`
    }
  }}
`
