import React from "react"

import { Icon } from "../Icon/Icon"
import { withSocialMedia } from "./withSocialMedia"
import { Wrapper, Title, List, ListItem } from "./SocialMediaStyles"

export const SocialMedia = withSocialMedia(({ title, items, hideTitle }) => (
  <Wrapper>
    {hideTitle ? null : <Title>{title}</Title>}
    <List>
      {items?.map(item => (
        <ListItem key={item?.title}>
          <a
            href={item?.url}
            rel="noopener noreferrer"
            target="_blank"
            title={item?.title}
          >
            <Icon
              name={item?.title?.toLowerCase()}
              height={30}
              width={30}
              title={item?.title}
            />
          </a>
        </ListItem>
      ))}
    </List>
  </Wrapper>
))
