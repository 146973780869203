import React from "react"
import { useRoutes } from "../../hooks/useRoutes"

export const withButtonLink = Component => ({
  name = "ButtonLink",
  style = "",
  size = "",
  link: originalLink,
  linkOverride = null,
  className = "",
  wide = false,
}) => {
  const { linkResolver } = useRoutes()
  const link =  linkOverride ? linkOverride : linkResolver(originalLink)
  
  Component.displayName = name
  return (
    <Component
      style={style}
      link={link}
      size={size}
      className={className}
      wide={wide}
    />
  )
}
