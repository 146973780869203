import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

export const Outer = tw.section`
  fixed inset-x-0 bottom-0 bg-white h-auto md:min-h-3 md:overflow-hidden z-50
`

export const Inner = styled(StyledContainer)`
  ${tw`h-full relative flex flex-col md:flex-row items-start md:items-center justify-center py-1`}
`

export const Close = tw.div`
  md:ml-3 px-0-4 w-1/3
`

export const Content = tw.div`
  block mb-1-6
`
