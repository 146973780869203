import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useApp } from "../../../../../hooks/useApp"
import { useCart } from "../../../../../hooks/useCart"
import { useShopifyVariants } from "../../../../../hooks/useShopify"

export const withFreeGiftAddToCart = Component => ({
  name = "FreeGiftAddToCart",
  product,
}) => {
  const {
    config: {
      settings: {
        constraints: { SIZE },
      },
    },
  } = useApp()

  const { addFreeGiftToCart, loading } = useCart()

  const { activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: product,
  })

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_ADD_FREE_GIFT_CARD {
      productTemplateData: sanityTemplateProduct {
        selectSizeButtonText
        addToCartButtonText
        soldOutButtonText
      }
    }
  `)

  const { selectSizeButtonText, addToCartButtonText, soldOutButtonText } =
    productTemplateData || {}

  const isOutOfStock = activeVariant && !activeVariant?.availableForSale

  const getText = () => {
    if (!activeVariant) return selectSizeButtonText
    if (isOutOfStock) return soldOutButtonText
    return addToCartButtonText
  }

  const buttonText = getText()

  Component.displayName = name

  return (
    <Component
      addFreeGiftToCart={addFreeGiftToCart}
      product={product}
      options={product.options}
      activeVariant={activeVariant}
      selectedOptions={selectedOptions}
      handleVariant={handleVariant}
      SIZE={SIZE}
      isOutOfStock={isOutOfStock}
      buttonText={buttonText}
      loading={loading}
    />
  )
}
