import tw, { styled } from "twin.macro"

import { StyledButton, UnderlinedButton } from "../Styled/Button"

export const Content = styled.div`
  ${tw`text-navy`}
  ${({ imagePosition }) => {
    switch (imagePosition) {
      case "top":
        return tw`flex flex-col`
      case "bottom":
        return tw`flex flex-col-reverse`
      case "left":
        return tw`flex flex-row`
      case "right":
        return tw`flex flex-row-reverse`
      default:
        break
    }
  }}
`

export const ImageWrapper = tw.div`
  hidden md:block w-full
`

export const Wrapper = tw.div`
  flex items-center justify-center bg-white
`

export const Form = tw.form`m-4`

export const Success = tw.div`pt-6 pb-4 px-4 md:px-6`

export const Title = tw.h2`
  text-14 leading-1.71 mb-1-6
`

export const Description = tw.p`
  text-20 leading-1.5 md:text-28 md:leading-1.28 uppercase mb-2-4 md:mb-3-2 font-bold
`

export const Terms = tw.p`
  text-12 leading-1.5 text-grey
`

export const Email = styled.input`
  ${tw`mb-1-6 border border-navy-light w-full rounded p-1-2 text-16`}
  ::placeholder {
    ${tw`text-navy`}
  }
`

export const Submit = styled(StyledButton)`
  ${tw`mb-1-2`}
`

export const Close = styled(UnderlinedButton)`
  ${tw`mt-3-2`}
`

export const Label = tw.label`
  text-14 leading-1.71 mb-0-8 block
`
