import React from "react"
import { Link } from "gatsby"
import Countdown from "react-countdown"

import { Counter } from "./Counter/Counter"
import {
  Outer,
  Inner,
  Wrapper,
  CountdownWrapper,
  CountdownInner,
  CountdownDivider,
  HeadingWrapper,
  Heading,
  Subheading,
  ButtonWrapper,
  Button,
} from "./AnnouncementStyles"

// Renderer callback with condition
const renderer = ({ locale, color }) => ({ days, hours, minutes, seconds }) => {
  return (
    <CountdownInner>
      <Counter number={days} maxNumber={2} label={locale.days} color={color} />
      <CountdownDivider colour={"white"} color={color}>
        {":"}
      </CountdownDivider>
      <Counter
        number={hours}
        maxNumber={2}
        label={locale.hours}
        color={color}
      />
      <CountdownDivider colour={"white"} color={color}>
        {":"}
      </CountdownDivider>
      <Counter
        number={minutes}
        maxNumber={2}
        label={locale.minutes}
        color={color}
      />
      <CountdownDivider colour={"white"} color={color}>
        {":"}
      </CountdownDivider>
      <Counter
        number={seconds}
        maxNumber={2}
        label={locale.seconds}
        color={color}
      />
    </CountdownInner>
  )
}

export const CountdownBar = ({
  show,
  countdownItem,
  stickyCountdown,
  days,
  hours,
  minutes,
  seconds,
}): JSX.Element => (
  <Outer active={stickyCountdown ? "true" : show} countdown>
    <Inner>
      <Wrapper>
        <HeadingWrapper>
          {countdownItem?.heading ? (
            <Heading colour={"white"} color={countdownItem.color?.hex}>
              {countdownItem.heading}
            </Heading>
          ) : null}
          {countdownItem?.subheading ? (
            <Subheading colour={"white"} color={countdownItem.color?.hex}>
              {countdownItem.subheading}
            </Subheading>
          ) : null}
        </HeadingWrapper>

        <CountdownWrapper>
          <Countdown
            date={countdownItem?.expireDateTime}
            renderer={renderer({
              locale: { days, hours, minutes, seconds },
              color: countdownItem.color?.hex,
            })}
          />
        </CountdownWrapper>

        <ButtonWrapper desktop>
          {countdownItem?.button ? (
            <Button
              as={
                countdownItem.button.url &&
                (countdownItem.button.isExternal ? `a` : Link)
              }
              href={countdownItem.button.isExternal && countdownItem.button.url}
              target={countdownItem.button.external && `_blank`}
              title={countdownItem.button.title}
              to={!countdownItem.button.isExternal && countdownItem.button.url}
              colour={"whiteBorderedWithWhiteText"}
              size={"primary"}
              customColor={countdownItem.color?.hex}
            >
              {countdownItem.button.title}
            </Button>
          ) : null}
        </ButtonWrapper>
      </Wrapper>

      {countdownItem?.button ? (
        <ButtonWrapper>
          <Button
            as={
              countdownItem.button.url &&
              (countdownItem.button.isExternal ? `a` : Link)
            }
            href={countdownItem.button.isExternal && countdownItem.button.url}
            target={countdownItem.button.external && `_blank`}
            title={countdownItem.button.title}
            to={!countdownItem.button.isExternal && countdownItem.button.url}
            colour={"whiteBorderedWithWhiteText"}
            size={"primary"}
            customColor={countdownItem.color?.hex}
          >
            {countdownItem.button.title}
          </Button>
        </ButtonWrapper>
      ) : null}
    </Inner>
  </Outer>
)
