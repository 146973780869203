import tw, { styled } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`w-full`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
`

export const Desktop = styled.div`
  ${tw`hidden md:block`}
  ${({ fullHeight }) => fullHeight && tw`h-full`}
`

export const Mobile = tw.div`
  block md:hidden
`
