import gql from "graphql-tag"

import { CHECKOUT_FRAGMENT } from "../fragments/checkoutFragment"
import { CHECKOUT_USER_ERROR_FRAGMENT } from "../fragments/checkoutUserErrorFragment"

export const CHECKOUT_LINE_ITEM_ADD = gql`
  mutation checkoutLineItemsAdd(
    $lineItems: [CheckoutLineItemInput!]!
    $checkoutId: ID!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutLineItemsAdd(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`
export const CHECKOUT_LINE_ITEMS_REMOVE = gql`
  mutation checkoutLineItemsRemove($checkoutId: ID!, $lineItemIds: [ID!]!, $countryCode: CountryCode!) {
    checkoutLineItemsRemove(
      checkoutId: $checkoutId
      lineItemIds: $lineItemIds
    ) @inContext(country: $countryCode) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_CREATE = gql`
  mutation CHECKOUT_CREATE(
    $input: CheckoutCreateInput!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutCreate(input: $input) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_LINE_ITEMS_REPLACE = gql`
  mutation checkoutLineItemsReplace(
    $checkoutId: ID!
    $lineItems: [CheckoutLineItemInput!]!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutLineItemsReplace(lineItems: $lineItems, checkoutId: $checkoutId) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      userErrors {
        code
        field
        message
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
`

export const CHECKOUT_ATTRIBUTES_UPDATE = gql`
  mutation checkoutAttributesUpdateV2(
    $checkoutId: ID!
    $input: CheckoutAttributesUpdateV2Input!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutAttributesUpdateV2(checkoutId: $checkoutId, input: $input) {
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
      checkout {
        ...CustomizedCheckoutFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_SHIPPING_ADDRESS_UPDATE = gql`
  mutation checkoutShippingAddressUpdateV2(
    $shippingAddress: MailingAddressInput!
    $checkoutId: ID!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutShippingAddressUpdateV2(
      shippingAddress: $shippingAddress
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_EMAIL_UPDATE = gql`
  mutation checkoutEmailUpdateV2($email: String!, $checkoutId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    checkoutEmailUpdateV2(email: $email, checkoutId: $checkoutId) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_DISCOUNT_APPLY = gql`
  mutation checkoutDiscountCodeApplyV2(
    $discountCode: String!
    $checkoutId: ID!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutDiscountCodeApplyV2(
      discountCode: $discountCode
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_GIFTCARDS_APPEND = gql`
  mutation checkoutGiftCardsAppend(
    $giftCardCodes: [String!]!
    $checkoutId: ID!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutGiftCardsAppend(
      giftCardCodes: $giftCardCodes
      checkoutId: $checkoutId
    ) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_CUSTOMER_ASSOCIATE = gql`
  mutation checkoutCustomerAssociateV2(
    $checkoutId: ID!
    $customerAccessToken: String!
    $countryCode: CountryCode!
  ) @inContext(country: $countryCode) {
    checkoutCustomerAssociateV2(
      checkoutId: $checkoutId
      customerAccessToken: $customerAccessToken
    ) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_CUSTOMER_DISASSOCIATE = gql`
  mutation checkoutCustomerDisassociateV2($checkoutId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    checkoutCustomerDisassociateV2(checkoutId: $checkoutId) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`

export const CHECKOUT_DISCOUNT_CODE_REMOVE = gql`
  mutation checkoutDiscountCodeRemove($checkoutId: ID!, $countryCode: CountryCode!) @inContext(country: $countryCode) {
    checkoutDiscountCodeRemove(checkoutId: $checkoutId) {
      checkout {
        ...CustomizedCheckoutFragment
      }
      checkoutUserErrors {
        ...CheckoutUserErrorFragment
      }
    }
  }
  ${CHECKOUT_FRAGMENT}
  ${CHECKOUT_USER_ERROR_FRAGMENT}
`
