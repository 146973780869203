import React from "react"

import { withNavigationHeaderDesktop } from "./withNavigationHeaderDesktop"
import { NavigationHeaderDesktopMain } from "./Main/NavigationHeaderDesktopMain"
import {
  StyledNavigationHeaderDesktop,
  BorderWrapper,
  Container,
} from "./NavigationHeaderDesktopStyles"

export const NavigationHeaderDesktop = withNavigationHeaderDesktop(
  () => (
    <BorderWrapper>
      <Container>
        <StyledNavigationHeaderDesktop as={"nav"}>
          <NavigationHeaderDesktopMain />
        </StyledNavigationHeaderDesktop>
      </Container>
    </BorderWrapper>
  ),
)
