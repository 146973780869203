import React, { useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCart } from "../../../hooks/useCart"
import { useCore } from "../../../hooks/useCore"
import { useApp } from "../../../hooks/useApp"
import { useWishlist } from "../../../hooks/useWishlist"

export const withAddToCart = Component => ({
  name = "AddToCart",
  selectedVariant,
  product,
  layout,
  showQuantitySelector = false,
}) => {
  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const {
    config: {
      settings: {
        routes: { PRODUCT },
        keys: { preOrderKey, preOrderValue },
      },
    },
  } = useApp()

  const { addToCart, loading } = useCart()

  const { deleteFromWishlist } = useWishlist()

  const [quantity, setQuantity] = useState(1)

  const { productTemplateData, wishlistCard } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_ADD_TO_CART_AND_SANITY_PAGE_ACCOUNT_WISHLIST_CARD {
      productTemplateData: sanityTemplateProduct {
        selectSizeButtonText
        addToCartButtonText
        lowStockThreshold
        lowStockMessage
        preOrderButtonText
        backInStockButtonText
        backInStockMessage
        soldOutButtonText
        soldOutMessage
        settingsProductQuantitySelector
      }
      wishlistCard: sanityPageAccountWishlist {
        moveToCartButtonText
      }
    }
  `)

  const {
    selectSizeButtonText,
    addToCartButtonText,
    lowStockThreshold,
    lowStockMessage,
    preOrderButtonText,
    backInStockButtonText,
    backInStockMessage,
    soldOutButtonText,
    soldOutMessage,
    settingsProductQuantitySelector,
  } = productTemplateData || {}

  const { moveToCartButtonText } = wishlistCard || {}

  const [restockFormActive, setRestockFormActive] = useState(false)

  //PreOrder data which Reactify Pre-Order App injected into metafield
  const preOrder = product?.metafields?.find(
    ({ key, namespace }) => namespace === "reactify" && key === "preorder",
  )?.value
    ? JSON.parse(
        product?.metafields?.find(
          ({ key, namespace }) =>
            namespace === "reactify" && key === "preorder",
        )?.value,
      )
    : ""

  // Display Restock form or not when variant is out of stock, supported by Shopify App - "alert me! RESTOCK alerts"

  const displayRestock = () => {
    if(!product?.metafields?.length) {
      return false
    }

    return !(product?.metafields?.find(
      ({ key, namespace }) => namespace === "da-restock" && key === "hide",
    )?.value === "false")
  }
  const isPreOrder =
    selectedVariant &&
    selectedVariant?.quantityAvailable === 0 &&
    selectedVariant?.availableForSale &&
    !!preOrder

  const isRestock =
    selectedVariant && !selectedVariant?.availableForSale && displayRestock()

  const isOutOfStock =
    selectedVariant && !selectedVariant?.availableForSale && !displayRestock()

  const url = `${PRODUCT}/${product?.handle}`

  const addButtonText =
    layout === "wishlist" ? moveToCartButtonText : addToCartButtonText

  const getText = () => {
    if (
      selectedVariant?.quantityAvailable > 0 &&
      selectedVariant?.quantityAvailable < lowStockThreshold
    )
      return {
        message: lowStockMessage.replace(
          "${amount}",
          selectedVariant?.quantityAvailable,
        ),
        buttonText: addButtonText,
      }
    if (isPreOrder)
      return {
        message:
          preOrder?.[decodeShopifyId(selectedVariant?.id, "ProductVariant")]
            ?.message ?? preOrder?.all?.message,
        buttonText: preOrderButtonText,
      }
    if (isRestock)
      return { message: backInStockMessage, buttonText: backInStockButtonText }
    if (isOutOfStock)
      return { message: soldOutMessage, buttonText: soldOutButtonText }
    if (!selectedVariant) return { buttonText: selectSizeButtonText }
    return { buttonText: addButtonText }
  }

  const { message, buttonText } = getText()

  const handleAddToCart = async () => {
    isPreOrder
      ? await addToCart(
          product,
          selectedVariant,
          showQuantitySelector ? quantity : 1,
          [
            {
              key: preOrderKey,
              value: preOrderValue,
            },
          ],
        )
      : await addToCart(
          product,
          selectedVariant,
          showQuantitySelector ? quantity : 1,
        )
    if (layout === "wishlist") {
      deleteFromWishlist(product?.handle)
    }
    setQuantity(1)
  }

  const availableQuantity = selectedVariant?.quantityAvailable > settingsProductQuantitySelector ? settingsProductQuantitySelector : (selectedVariant?.quantityAvailable >= 0 ? selectedVariant?.quantityAvailable : settingsProductQuantitySelector) || settingsProductQuantitySelector

  const quantityOptions = Array.from(
    new Array(availableQuantity).keys(),
  ).map(key => ({ label: key + 1, value: key + 1 }))

  const handleQuantityChange = e => {
    setQuantity(Number(e.target.value))
  }

  Component.displayName = name
  return (
    <Component
      handleAddToCart={handleAddToCart}
      loading={loading}
      selectedVariant={selectedVariant}
      message={message}
      buttonText={buttonText}
      isPreOrder={isPreOrder}
      isRestock={isRestock}
      isOutOfStock={isOutOfStock}
      restockFormActive={restockFormActive}
      setRestockFormActive={setRestockFormActive}
      variantId={decodeShopifyId(selectedVariant?.id, "ProductVariant")}
      productId={decodeShopifyId(product?.id, "Product")}
      url={url}
      layout={layout}
      showQuantitySelector={showQuantitySelector}
      quantityOptions={quantityOptions}
      handleQuantityChange={handleQuantityChange}
      quantity={quantity}
    />
  )
}
