import tw, { styled } from "twin.macro"

export const TitleWrapper = tw.div`
  flex mb-0-8 items-start
`

export const Title = tw.h1`
  flex-1 font-bold text-16 md:text-25 leading-1.25 md:leading-1.4 tracking-10
`

export const PriceWrapper = tw.div`
  flex-none mb-0-8 text-grey
`

export const BadgeWrapper = tw.div`
  flex flex-row items-center mb-2-4
`

export const Divider = styled.hr`
  ${tw`text-navy text-opacity-60`}
  ${({ hideDesktop }) => (hideDesktop ? tw`md:hidden` : null)}
`

export const VariantsWrapper = tw.div`
  flex flex-col mb-1-8
`

export const WishlistWrapper = tw.div`
  p-0-4 md:p-1
`
