import React from "react"
import {
  Wrapper,
  ButtonWrapper,
  Divider,
  SubTitle,
  Collections,
  Collection,
} from "./InstantSearchResultsStyles"
import { UnderlinedButton } from "../../../Styled/Button"
import { RichText } from "../../../RichText/RichText"
import { Link } from "../../../Link/Link"
import { withInstantSearchNoResults } from "./withInstantSearchNoResults"

export const InstantSearchNoResults = withInstantSearchNoResults(
  ({
    noResults,
    popularCollections,
    noResultsLink,
    additionalPopularCollectionsTitle,
    additionalBrowseAllProducts,
  }) => {
    return (
      <Wrapper>
        <RichText>{noResults}</RichText>
        <Divider />
        <SubTitle>{additionalPopularCollectionsTitle}</SubTitle>
        <Collections>
          {popularCollections?.map(({ id, title, url }) => (
            <Collection key={id} to={url} title={title}>
              {title}
            </Collection>
          ))}
        </Collections>
        <ButtonWrapper>
          <UnderlinedButton size={"primary"}>
            <span>
              <Link
                title={additionalBrowseAllProducts}
                url={noResultsLink?.url}
                external={noResultsLink?.external}
                isExternal={noResultsLink.isExternal}
              />
            </span>
          </UnderlinedButton>
        </ButtonWrapper>
      </Wrapper>
    )
  },
)
