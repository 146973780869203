import gql from "graphql-tag"

export const APPLIED_GIFT_CARD_FRAGMENT = gql`
  fragment AppliedGiftCardFragment on AppliedGiftCard {
    amountUsedV2 {
      amount
      currencyCode
    }
    balanceV2 {
      amount
      currencyCode
    }
    presentmentAmountUsed {
      amount
      currencyCode
    }
    id
    lastCharacters
  }
`
