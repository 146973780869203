import React from "react"

import { useSocial } from "../../hooks/useSocial"
import { getAdditionalText } from "../../utils/getAdditionalText"

export const withSocialMedia = Component => ({
  name = "SocialMedia",
  hideTitle = false,
}) => {
  const { social } = useSocial()

  const { channels: items } = social || {}

  const {
    template: {
      global: { additionalSocialShareTitle },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      items={items}
      title={additionalSocialShareTitle}
      hideTitle={hideTitle}
    />
  )
}
