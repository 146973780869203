const configDefault = {
  app: {
    title: "RB Sellars",
    description:
      "RB Sellars is an Australian family owned brand inspired by community, a love of the land, and the real day-to-day needs of our customers.",
    url: "https://rbsellars.com.au/",
    logo: "static/images/logo.svg",
    themeColor: "#000000",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
  },
  theme: {
    logo: "static/images/logo.svg",
    themeColor: "#ffffff",
    themeBackground: "#FFFF04",
    themeDisplay: "minimal-ui",
    themeIcon: "static/images/icon.png",
    color: "#ffffff",
    background: "#FFFF04",
    display: "minimal-ui",
    icon: "static/images/icon.png",
  },
  stores: {
    "rb-sellars": {
      siteLocation: "AU",
      siteCountries: "AU",
      shopName: "rb-sellars",
      shopDomain: "checkout.rbsellars.com.au",
      accessToken: "f7e54a9354d8d53a349039f0a3105e69",
      sanityProjectId: "i5bdohow",
      sanityDataset: "production",
      searchIndex: "rb-sellars-primary",
    },
    "rb-sellars-stage": {
      siteLocation: "AU",
      siteCountries: "AU",
      shopName: "rb-sellars-stage",
      shopDomain: "rb-sellars-stage.myshopify.com",
      accessToken: "cf7fba4642e2b1ce398f8dba3615c386",
      sanityProjectId: "i5bdohow",
      sanityDataset: "staging",
      searchIndex: "rb-sellars-stage-primary",
    },
  },
  services: {
    shopify: {
      apiVersion: "2023-04",
      defaultShopName: process.env.GATSBY_SHOPIFY_DEFAULT_SHOP,
    },
    sanity: {
      projectId: "i5bdohow",
      token:
        "skQz9yDMgN991EEQrpneemuAhNYUKvjUobZQvEAGTvj7TePErv1iwFPs8PTtDO81tInor8m35M2pWjVNIlfrzYPNJpaRftiitZ1LgocS0anYyrwAWcWRcDNZUXCz8Ajf3oqlscij2QfUU15P1R59FXV0kvLDO9E2YExvqObcMUlWnZX1B6YS",
      dataset: process.env.GATSBY_SANITY_DEFAULT_DATASET,
    },
    functions: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint:
          "https://australia-southeast1-rbsellars-website.cloudfunctions.net",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint:
          "https://australia-southeast1-rbsellars-website-stage.cloudfunctions.net",
      }),
    },
    firebase: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        databaseURL: "https://rbsellars-website.firebaseio.com",
        apiKey: "AIzaSyAlWu5n1SlFXIjNGjpo4meI6V541Pcug1g",
        authDomain: "rbsellars-website.firebaseapp.com",
        projectId: "rbsellars-website",
        storageBucket: "rbsellars-website.appspot.com",
        messagingSenderId: "1090540235681",
        appId: "1:1090540235681:web:3f1910847c7d9444d1b9e0",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        region: "us-central1",
        databaseURL: "https://rbsellars-website-stage.firebaseio.com",
        apiKey: "AIzaSyCinwzjaw88y6J-DUwpOUBxAELYZCnZukw",
        authDomain: "rbsellars-website-stage.firebaseapp.com",
        projectId: "rbsellars-website-stage",
        storageBucket: "rbsellars-website-stage.appspot.com",
        messagingSenderId: "193747515802",
        appId: "1:193747515802:web:bbadba2cbbd055231ba223",
      }),
    },
    location: {
      serviceUrl: "https://ipapi.co/json/",
      countryFieldKey: "country",
      forceRegion: true,
    },
    reactify: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        endpoint: "https://api.search.reactify.app",
        searchUrl: "https://api.search.reactify.app/",
        searchConfig:
          "https://config.search.reactify.app?shop=rb-sellars.myshopify.com",
        searchFieldKey: "q",
        index: "rb-sellars-primary",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        searchUrl: "https://api.search.reactify.app/",
        endpoint: "https://api.search.reactify.app",
        searchConfig:
          "https://config.search.reactify.app?shop=rb-sellars-stage.myshopify.com",
        searchFieldKey: "q",
        index: "rb-sellars-stage-primary",
      }),
    },
    googleMaps: {
      apiKey: "AIzaSyA-6ePLILQrDkw8HGdVNRMvfAie5LZmIgs",
    },
    instagram: {
      url: "https://www.instagram.com/p",
    },
    giftcard: {
      ...("production" === process.env.GATSBY_ACTIVE_ENV && {
        // balance: 'getGiftcard',
        balance:
          "https://australia-southeast1-rb-sellars-dotapparel21-au.cloudfunctions.net/api-checkGiftcardBalance",
      }),
      ...("development" === process.env.GATSBY_ACTIVE_ENV && {
        // balance: "getGiftcard",
        balance:
          "https://australia-southeast1-rb-sellars-dotapparel21-au.cloudfunctions.net/api-checkGiftcardBalance",
      }),
    },
    brauz: {
      endpoint:
        "https://d3aq2u4yw77ivo.cloudfront.net/find-in-store/shopify/rbsellars/find-in-store.shopify.rbsellars.prod.js",
    },
  },
  queries: {
    products: {
      query: `allSanityProduct(filter: {shopify: {shopifyPublished: {eq: true}, shopifyDeleted: {ne: true}}}) { edges { node { tags, shopify { handle: shopifyHandle, shopifyRaw, deleted: shopifyDeleted }   } } }`,
      template: "templates/product.tsx",
      //hidden build PDP page for specific tag
      hidden: "sanity:hidden",
      devPageLimit: 300,
    },
    collections: {
      query: `allSanityCollection(filter: {shopify: {shopifyDeleted: {ne: true}}}) { edges { node { shopify {  handle: shopifyHandle, deleted: shopifyDeleted } } } }`,
      template: "templates/collection.tsx",
      hidden: "hidden",
      devPageLimit: 100,
    },
    articles: {
      query: `allSanityArticle { edges { node { _id handle: slug { current } } } }`,
      template: "templates/article.tsx",
      devPageLimit: 100,
    },
    stores: {
      query: `allSanityStore { edges { node { _id handle: slug { current } } } }`,
      template: "templates/store.tsx",
      devPageLimit: 100,
    },
    flexible: {
      query: `allSanityPageFlexible { edges { node { _id handle: slug { current } } } }`,
      template: "templates/flexible.tsx",
    },
    generic: {
      query: `allSanityPageGeneric { edges { node { _id handle: slug { current } } } }`,
      template: "templates/generic.tsx",
    },
    redirects: {
      query: `allSanityRedirect { edges { node { source type destination } } }`,
      devPageLimit: 0,
    },
    robots: {
      query: `allSanitySettingRobots { nodes { content { code } } }`,
    },
    careers: {
      query: `allSanityCareer { edges { node { _id handle: slug { current } } } }`,
      template: "templates/career.tsx",
    },
    catalogues: {
      query: `allSanityCatalogue { edges { node { _id handle: slug { current } } } }`,
      template: "templates/catalogue.tsx",
    },
  },
  settings: {
    keys: {
      shopify: "headless:shopify",
      country: "headless:country",
      location: "headless:location",
      checkout: "headless:checkout",
      discounts: "headless:discounts",
      customer: "headless:customer",
      wishlist: "headless:wishlist",
      announcement: "headless:announcement",
      cookies: "headless:cookies",
      maintenance: "headless:maintenance",
      newsletter: "headless:newsletter",
      password: "headless:password",
      geolocation: "headless:geolocation",
      collection_scroll_position: "headless:collection_scroll_position",
      catalogue_scroll_position: "headless:catalogue_scroll_position",
      preOrderKey: "Pre-order",
      preOrderValue: "Yes",
    },
    params: {
      continue: "return",
      customer: "key",
      giftcard: "redeem",
      variant: "variant",
      wishlist: "id",
      articlesFilter: "filter",
      articlesSortBy: "sortby",
      articlesPage: "page",
      checkoutRef: "ref",
      preview: "preview",
    },
    routes: {
      HOMEPAGE: "/",
      FLEXIBLE: "",
      GENERIC: "",
      PAGE: "",
      CART: "/cart",
      CONTACT: "/contact",
      SEARCH: "/search",
      PASSWORD: "/password",
      SAVED: "/wishlist",
      LOGIN: "/account/login",
      REGISTER: "/account/register",
      FORGOT: "/account/forgot",
      RESET: "/account/reset",
      ACTIVATE: "/account/activate",
      RETURNS: "/account/returns",
      DASHBOARD: "/account",
      ORDERS: "/account/orders",
      ADDRESSES: "/account/addresses",
      PREFERENCES: "/account/preferences",
      WISHLIST: "/wishlist",
      PRODUCT: "/products",
      COLLECTION: "/collections",
      ARTICLE: "/articles",
      STORE: "/stores",
      GIFTCARD: "/giftcard",
      CAREER: "/careers",
      CATALOGUE: "/edit",
    },
    tags: {
      badge: "badge",
      message: "message",
      siblings: "product_line",
      swatchColor: "swatch_colour",
      feature: "feature",
      productCode: "product_code",
      sizeGuide: "size_table",
    },
    constraints: {
      SIZE: "Size",
      COLOUR: "Colour",
      CARE: "careInstructions",
      DETAILS: "productDetails",
      productMetafields: [
        { key: "reactify", namespace: "pre-order" },
        { key: "careInstructions", namespace: "marketing" },
        { key: "fit", namespace: "marketing" },
        { key: "modelWearing", namespace: "marketing" },
        { key: "productDetails", namespace: "marketing" },
        { key: "hide", namespace: "da-restock" },
      ],
    },
    giftCardConstraints: {
      DIGITAL: "digital",
      PHYSICAL: "physical",
      CUSTOMAMOUNT: "customAmount",
      SENDER: "Sender",
      RECEIVER: "Recipient",
      RECEIVER_EMAIL: "Recipient_Email",
      MESSAGE: "Message",
      DELIVERY: "Delivery Date",
    },
    defaultRedirects: [
      { source: "/collection", destination: "/collections", type: 301 },
      { source: "/checkout", destination: "/cart", type: 301 },
      { source: "/home", destination: "/", type: 301 },
    ],
    clientPaths: [
      "/account/reset/*",
      "/account/activate/*",
      "/account/orders/*",
    ],
    sitemapExclusions: [
      "/offline-plugin-app-shell-fallback",
      "/dev-404-page",
      "/404",
      "/404.html",
      "/password",
      "/preview",
      "/account",
      "/account/login",
      "/account/forgot",
      "/account/activate",
      "/account/reset",
      "/account/orders",
      "/account/addresses",
      "/account/preferences",
      "/account/wishlist",
      "/wishlist",
      "/search",
      "/cart",
      "/cart/login",
    ],
    socialMedia: {
      sharing: {
        facebook: "https://www.facebook.com/sharer/sharer.php?u=",
        pinterest: "http://pinterest.com/pin/create/button/?url=",
      },
    },
    international: {
      GST_REMOVED_MESSAGE: "International Order - GST Removed",
    },
  },
}

module.exports = configDefault
