import React from "react"

import { withSizeGuide } from "./withSizeGuide"
import {
  Wrapper,
  Title,
  Description,
  Tables,
  StyledTable,
} from "./SizeGuideStyles"
import { UnderlinedButton } from "../../Styled/Button"
import {
  Modal,
  ModalOpenButton,
  ModalContentsWithLayout,
} from "../../Modal/Modal"

export const SizeGuide = withSizeGuide(({ showSizeGuide, sizeGuide }) =>
  showSizeGuide ? (
    <Modal>
      <Wrapper>
        <ModalOpenButton>
          <UnderlinedButton>
            <span>Size Guide</span>
          </UnderlinedButton>
        </ModalOpenButton>
      </Wrapper>
      <ModalContentsWithLayout layout={"sizeGuide"}>
        <Title>{sizeGuide.title}</Title>
        <Description>{sizeGuide.description}</Description>
        <Tables>
          {sizeGuide?.tables?.map(table => (
            <StyledTable key={table._key} chart={table.table} />
          ))}
        </Tables>
      </ModalContentsWithLayout>
    </Modal>
  ) : null,
)
