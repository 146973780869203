import tw, { styled } from "twin.macro"

export const Wrapper = tw.div`
  relative flex-1
`

export const StyledNavigationHeaderMobile = styled.nav`
  ${tw`absolute inset-0 w-full h-full flex flex-col overflow-y-scroll overflow-x-hidden mlg:hidden transform transition-all duration-300`}
  ${({ active }) =>
    active
      ? tw`pointer-events-auto visible`
      : tw`pointer-events-none invisible `}
`
