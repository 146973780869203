import tw, { styled } from "twin.macro"

import { PaymentIcons } from "./PaymentIcons/PaymentIcons"
import { StyledContainer } from "../Styled/Container"

export const Bg = styled.div`
  ${tw`bg-navy-new pt-4 pb-4 mlg:pb-6 text-white relative`}
`

export const BorderWrapper = tw.div`
  border-b border-grey-lightest border-opacity-30 mlg:border-b-0
`

export const Top = styled(StyledContainer)`
  ${tw`max-w-37-5 mlg:max-w-xl flex flex-col mlg:flex-row justify-between pb-3-6`}
`

export const Left = tw.div`
  hidden mlg:flex flex-row w-2/3
`

export const Right = tw.div`
  flex flex-col w-full mlg:w-1/3 mlg:justify-end mlg:max-w-1/2 flex-auto
`

export const Bottom = styled(StyledContainer)`
  ${tw`flex flex-col mlg:flex-row items-center justify-between pt-2-4`}
`

export const BottomLeft = tw.div`
  flex flex-col mlg:flex-row mlg:justify-start justify-center items-center
`

export const DesktopPaymentIcons = styled(PaymentIcons)`
  ${tw`hidden mlg:flex`}
`
