import React from "react"
import parse from "html-react-parser"
import BlockContent from "@sanity/block-content-to-react"
import { Link } from "gatsby"
import { ImageVideo } from "../components/ImageVideo/ImageVideo"

const renderCode = html => {
  const elementId = Math.random().toString(36).substring(9)

  return parse(html, {
    replace: domNode => {
      if (domNode.type === `script`) {
        const script = document.createElement(`script`)
        if (domNode.attribs.src) script.src = domNode.attribs.src
        if (domNode.children.length) script.innerHTML = domNode.children[0].data
        script.id = `code-${elementId}`
        script.className = `code-${elementId}`
        script.type = `text/javascript`
        script.async = true
        script.defer = true
        document.body.prepend(script)
        return <React.Fragment key={elementId} />
      }
      return domNode
    },
  })
}

const typeToUrlPrefix = {
  article: "",
  collection: "/collections",
  product: "/products",
}

const serializers = {
  marks: {
    document: ({ children, mark }) => {
      let targetUrl = mark?.document?.link || ""
      if (!targetUrl && mark?.document) {
        const documentType = mark?.document?.document?._type || ""
        const slug = mark?.document?.document?.slug?.current || mark?.document?.document?.shopify?.shopifyHandle || ""
        const urlPrefix = typeToUrlPrefix[documentType] || ""
        targetUrl = `${urlPrefix}/${slug}`
      }
      return (
        <Link to={targetUrl} title={mark?.document?.title}>
          {children}
        </Link>
      )
    },
    link: ({ children, mark }) => (
      <a href={mark?.link?.link} title={mark?.link?.title} target={mark?.link?.external ? "_blank" : ""} rel="noreferrer">
        {children}
      </a>
    ),
  },
  types: {
    code: ({ node }) => renderCode(node.code),
    image: ({ node }) => <img src={node?.asset?.url} />,
    imageVideoResponsive: ({ node }) => <ImageVideo imageVideo={node} />,
  },
}

export const sanityContent = content => (content ? <BlockContent className={"rte"} blocks={content || []} serializers={serializers} /> : content)
