import React from "react"
import { ReactifySearchProvider, Sensors } from "@usereactify/search"

import { withInstant } from "./withInstant"
import { Outer, BorderWrapper, Wrapper, Results } from "./InstantStyles"
import { InstantResults } from "./Results/InstantResults"
import { InstantSearchForm } from "./Form/InstantSearchForm"

export const Instant = withInstant(
  ({
    index,
    shopifyPermanentDomain,
  }): JSX.Element => (
    <ReactifySearchProvider
      index={index}
      shopifyPermanentDomain={shopifyPermanentDomain}
      mode="instant-search"
    >
      <Sensors />
      <Outer>
        <BorderWrapper>
          <Wrapper>
            <InstantSearchForm />
          </Wrapper>
        </BorderWrapper>
        <Results>
          <Wrapper>
            <InstantResults />
          </Wrapper>
        </Results>
      </Outer>
    </ReactifySearchProvider>
  ),
)
