import tw, { styled } from "twin.macro"
import { Icon } from "../../Icon/Icon"

export const Wrapper = tw.button`
  relative
`

export const StyledIcon = styled(Icon)`
  ${tw`ml-1-6`}
`

export const Count = tw.p`
  absolute -top-0-5 -right-0-5 border rounded-full w-1-5 h-1-5 bg-red border-red text-12 leading-1.25 text-white flex items-center justify-center
`
