import React from "react"

import { withDiscountCode } from "./withDiscountCode"
import { Outer, Inner, Apply, Error } from "./DiscountCodeStyles"
import { TextField } from "../../../TextField/TextField"

export const DiscountCode = withDiscountCode(
  ({
    discountCode,
    handleApply,
    handleChange,
    loading,
    error,
    additionalDiscountCodePlaceholder,
    additionalDiscountCodeSubmitingButtonText,
    additionalDiscountCodeSubmitButtonText,
    additionalDiscountCodeErrorMessage,
  }) => (
    <Outer>
      <Inner>
        <TextField
          type="text"
          value={discountCode}
          onChange={handleChange}
          placeholder={additionalDiscountCodePlaceholder}
          label={"Promo code"}
        />
        <Apply onClick={handleApply}>
          {loading
            ? additionalDiscountCodeSubmitingButtonText
            : additionalDiscountCodeSubmitButtonText}
        </Apply>
      </Inner>
      {error && <Error>{additionalDiscountCodeErrorMessage}</Error>}
    </Outer>
  ),
)
