import React from "react"

import { withAdditionalPayments } from "./withAdditionalPayments"
import { Outer, Inner, Text, PaymentIcon } from "./AdditionalPaymentsStyles"

export const AdditionalPayments = withAdditionalPayments(({ payments }) => (
  <Outer>
    {payments?.map(({ name, description, _key }) => (
      <Inner key={_key}>
        <Text>{description[0]}</Text>
        &nbsp;
        <PaymentIcon name={name} />
        &nbsp;
        <Text>{description[1]}</Text>
      </Inner>
    ))}
  </Outer>
))
