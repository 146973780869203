import React from "react"
import { Link } from "../Link/Link"

import { Outer, Inner, Wrapper, Item, Content } from "./AnnouncementStyles"

export const AnnouncementBar = ({
  active,
  items,
  show,
  backgroundColour,
  isAnnouncementHidden,
}): JSX.Element | null =>
  isAnnouncementHidden || items?.length === 0 ? null : (
    <Outer active={show} backgroundColour={backgroundColour}>
      <Inner>
        <Wrapper>
          {items?.map((item, index) => (
            <Item key={item?.title} active={active === index}>
              {item?.link?.url ? (
                <Link
                  url={item?.link?.url}
                  title={item?.title}
                  external={item?.link?.external}
                  isExternal={item?.link?.isExternal}
                  color={item?.color}
                />
              ) : (
                <Content color={item?.color?.hex}>{item?.title}</Content>
              )}
            </Item>
          ))}
        </Wrapper>
      </Inner>
    </Outer>
  )
