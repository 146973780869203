import { graphql, useStaticQuery } from "gatsby"
import { useEffect } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useNewsletter = path => {
  const {
    activeSubscribe,
    setActiveSubscribe,
    config: {
      settings: { keys, routes },
    },
  } = useApp()

  const {
    helpers: { storage },
  } = useCore()

  const { newsletter, newsletterTemplates } =  useStaticQuery(graphql`
    query NEWSLETTER_SANITY_SETTINGS {
      newsletter: sanitySettingNewsletter {
        enabled
        delay
        expiry
      }
      newsletterTemplates: sanitySettingNewsletter {
        templateHomepage
        templateCollection
        templateProduct
        templateArticle
      }      
    }`) 

  const { enabled, expiry, delay } = newsletter || {}

  const activeRoute =
    (enabled &&
      Object.entries(routes)
        ?.filter(
          ([route, url]) => !route?.toLowerCase()?.includes(`page`) && url,
        )
        ?.find(([, url]) => path?.includes(url))) ||
    (path === routes?.HOMEPAGE && [`HOMEPAGE`, routes.HOMEPAGE])

  const isActive =
    activeRoute &&
    newsletterTemplates?.[
      `template${
        activeRoute[0]?.charAt(0)?.toUpperCase() +
        activeRoute[0]?.slice(1)?.toLowerCase()
      }`
    ]

  useEffect(() => {
    if (isActive && !storage.get(keys?.newsletter) && !activeSubscribe) {
      setTimeout(() => setActiveSubscribe(true), (delay || 5) * 1000)
    }
  }, [
    activeSubscribe,
    delay,
    isActive,
    keys?.newsletter,
    setActiveSubscribe,
    storage,
  ])

  const hideSubscribe = () => {
    setActiveSubscribe(false)
    storage.set(keys?.newsletter, `hidden`, expiry)
  }

  return {
    activeSubscribe,
    hideSubscribe,
  }
}
