import { useCallback } from "react"

export const useHotjar = () => {
  const hotjarIdentifyUser = useCallback(customer => {
    new Promise((resolve)=>{
      resolve()
      return
      let attempts = 0
      // wait for hj to initialize
      const interval = setInterval(()=>{
        if (attempts > 19) {
          clearInterval(interval)
          r
        } else if (typeof window !== 'undefined' && window && window.hj) {
          clearInterval(interval)
          resolve()
          // Disabling Hotjar for now

          // window.hj("identify", `${customer?.email}`, {
          //   email: `${customer?.email}`,
          //   firstName: `${customer?.firstName}`,
          //   lastName: `${customer?.lastName}`,
          //   fullName: `${customer?.firstName} ${customer?.lastName}`,
          //   userId: `${customer?.id}`,
          // })
          // resolve()
        }
        attempts++
      }, 500)
    })
  }, [])

  const trackHotjarEvent = useCallback((eventName) => {
    new Promise((resolve)=>{
      let attempts = 0
      resolve()
      return
      // disabling hotjar for now
      // wait for hj to initialize
      const interval = setInterval(()=>{
        if (attempts > 19) {
          clearInterval(interval)
          resolve()
        } else if (typeof window !== 'undefined' && window && window.hj) {
          clearInterval(interval)
          
          // window.hj("event", eventName)
          // if (eventName.includes('_page')) {
          //   window.hj("event", 'page_view')
          // }
          
        }
        attempts++
      }, 500)
    })
  }, [])

  return { hotjarIdentifyUser, trackHotjarEvent }
}
