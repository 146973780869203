import { Link } from "gatsby"
import React from "react"

import { QuickViewImages as Images } from "../../Images/QuickView/QuickViewImages"
import { QuickViewForm as Form } from "../../Form/QuickView/QuickViewForm"
import {
  Wrapper,
  ImagesWrapper,
  DetailsWrapper,
  ViewFullDetails,
  Placeholder,
} from "./QuickViewContentStyles"
import { withQuickViewContent } from "./withQuickViewContent"
import { Spinner } from "../../../Spinner/Spinner"

export const QuickViewContent = withQuickViewContent(
  ({
    activeProduct,
    products,
    activeProductHandle,
    setActiveProductHandle,
    loading,
    quickViewError,
    url,
    additionalViewFullDetailsText,
  }) => {

    if (quickViewError) {
      return <Wrapper>
        {quickViewError}
      </Wrapper>
    }
    
    if (loading) {
      return <Placeholder>
        <Spinner />
      </Placeholder>
    }

    
    return <Wrapper>
      <ImagesWrapper>
        <Images media={activeProduct?.media} />
      </ImagesWrapper>
      <DetailsWrapper>
        <Form
          activeProduct={activeProduct}
          products={products}
          activeProductHandle={activeProductHandle}
          setActiveProductHandle={setActiveProductHandle}
        />
        <ViewFullDetails
          as={Link}
          to={url}
          wide
          size="primary"
          colour="navyBorderedWithNavyText"
        >
          {additionalViewFullDetailsText}
        </ViewFullDetails>
      </DetailsWrapper>
    </Wrapper>
  }
)
