import tw, { styled } from "twin.macro"

export const Wrapper = tw.span`
  flex flex-row items-center
`

export const PriceTag = styled.p`
  ${({ layout }) => {
    if (layout === "drawer") {
      return tw`text-14 leading-1.78 text-right`
    }
    if (layout === "cart") {
      return tw`text-14 leading-1.78 text-right font-semibold`
    }
  }}
  ${({ sale }) => (sale ? tw`text-red` : null)}
  ${({ strike }) => (strike ? tw`line-through mr-0-2 text-grey` : null)}
`

export const Outer = styled.div`
  ${tw`flex`}
  ${({ layout }) =>
    layout === "drawer" || layout === "cart" ? tw`flex-col` : `flex-row`}
`
