import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.span`
  ${tw` uppercase`}
  ${({ layout }) => {
    if (layout === "card") return tw`text-14 leading-1.28 tracking-5 mb-0-4`
    if (layout === "product") return tw`text-14 leading-1.71 tracking-10 mb-0-8 `
  }}
  ${({ colour, isOnSale }) => {
    if (isOnSale && !colour) {
      return tw`text-red font-semibold`
    }

    return css`color: ${colour};`
  }}  
  `
  
  // ${({ isOnSale }) => isOnSale ? tw`text-red font-semibold` : tw`text-navy text-opacity-60`}