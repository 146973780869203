import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useApp } from "../../../hooks/useApp"
import { useShopify } from "../../../hooks/useShopify"

export const withSizeGuide = Component => ({ name = "SizeGuide", tags }) => {
  const {
    config: {
      settings: { tags: configTags },
    },
  } = useApp()

  const { edgeNormaliser } = useShopify()

  const sizeGuideTag = tags?.filter(tag =>
    tag.includes(configTags.sizeGuide),
  )?.[0]

  const { sizeGuides } = useStaticQuery(graphql`
    query SANITY_SIZE_CHARTS {
      sizeGuides: allSanitySizeChart {
        edges {
          node {
            title
            description: _rawDescription(resolveReferences: { maxDepth: 2 })
            tables: _rawTables(resolveReferences: { maxDepth: 2 })
          }
        }
      }
    }
  `)

  const sizeGuideHandle = sizeGuideTag
    ? sizeGuideTag?.split(":")?.[1]
    : undefined

  const selectedSizeGuide = edgeNormaliser(sizeGuides)?.filter(
    guide => guide?.title === sizeGuideHandle,
  )?.[0]

  const showSizeGuide = Boolean(selectedSizeGuide)

  Component.displayName = name
  return (
    <Component showSizeGuide={showSizeGuide} sizeGuide={selectedSizeGuide} />
  )
}
