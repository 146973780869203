import React from "react"

import { withPrice } from "./withPrice"
import { Wrapper, PriceTag, Outer } from "./PriceStyles"

export const Price = withPrice(
  ({ isOnSale, percentage, price, compareAtPrice, layout }) => {
    return (
      <Outer layout={layout}>
        <Wrapper>
          {isOnSale ? (
            <>
              <PriceTag strike layout={layout}>
                ${compareAtPrice}
              </PriceTag>
              &nbsp;
            </>
          ) : null}

          <PriceTag sale={isOnSale} layout={layout}>
            ${price}
          </PriceTag>
        </Wrapper>
        {percentage?.length > 0 && (
          <PriceTag sale percentage layout={layout}>
            &nbsp;({percentage}% off)
          </PriceTag>
        )}
      </Outer>
    )
  },
)
