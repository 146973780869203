import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useCheckoutContext } from "../../../hooks/useCheckout"

export const withAdditionalPayments = Component => ({
  name = "AdditionalPayments",
  variant = null,
  overridePrice = null,
}) => {
  const { checkout } = useCheckoutContext()

  const { productTemplateData } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_PRODUCT_PAYMENTS {
      productTemplateData: sanityTemplateProduct {
        settingAdditionalPayments {
          name
          description
        }
      }
    }
  `)

  const { settingAdditionalPayments } = productTemplateData || {}

  const [price, setPrice] = useState(overridePrice || "")

  useEffect(() => {
    if (variant && checkout?.currencyCode) {
      setPrice(variant?.priceV2?.amount || variant?.priceV2 || 0)
    }
  }, [checkout?.currencyCode, variant])

  const formatPrice = price ? `$${(Number(price) / 4).toFixed(2)}` : ``

  const payments = settingAdditionalPayments?.map(payment => ({
    ...payment,
    description: payment.description
      .replace("${price}", formatPrice)
      .split("${icon}"),
  }))

  Component.displayName = name
  return <Component payments={payments} />
}
