import React, { useRef, useEffect } from "react"
import { useCore } from "../../hooks/useCore"


export const withStarRating = Component => ({ name = "StarRating", productId }) => {
  const widgetContainer = useRef(null)

  const initialiseReviewsWidget = () => {
    try {
      if (widgetContainer.current) {
        window.okeWidgetApi.initWidget(widgetContainer.current).catch(() => {
          // nothing
        })
      }
    } catch {
      // shhh
    }
  }

  let interval

  const {
    helpers: { decodeShopifyId },
  } = useCore()

  const decodeAlternate = (string) => {
    const decoded = decodeShopifyId(string, 'Product')
    try {
      if (!decoded && typeof Buffer !== undefined) {
        return Buffer.from(string, 'base64').toString().split('Product/')[1]
      } else {
        return decoded || string
      }
    } catch {
      return decoded || string
    }
  }

  useEffect(() => {
    let attempts = 0
    clearInterval(interval)
    interval = setInterval(() => {
      attempts++
      if (window.okeWidgetApi && widgetContainer.current) {
        initialiseReviewsWidget()
        clearInterval(interval)
      } else if (attempts > 30) {
        clearInterval(interval)
      }
    }, 500)
  }, [])

  if(!productId) return null; 

  const id = !productId.includes('gid://') ? decodeAlternate(productId) : productId.replace("gid://shopify/Product/", "")

  



  Component.displayName = name
  return <Component widgetContainer={widgetContainer} productId={id} />
}
