import React from "react"
import SwiperCore, { Navigation, Thumbs } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"

import { withQuickViewImages } from "./withQuickViewImages"
import { Prev, Next, PlaceHolder } from "../Mobile/MobileImagesStyles"
import { Wrapper, VideoCoverImage, IconWrapper } from "./QuickViewImagesStyles"
import { Image } from "../../../Image/Image"
import { Icon } from "../../../Icon/Icon"
import { Video } from "../../../Video/Video"

SwiperCore.use([Navigation, Thumbs])

export const QuickViewImages = withQuickViewImages(
  ({ media, thumbsSwiper, setThumbsSwiper }) => (
    <>
      <Wrapper>
        <Prev id={"quick-view-prev"} title={"previous image"}>
          <Icon name={"chevronLeft"} width={8} height={14} />
        </Prev>
        <Next id={"quick-view-next"} title={"next image"}>
          <Icon name={"chevronRight"} width={8} height={14} />
        </Next>

        <Swiper
          navigation={{
            prevEl: "#quick-view-prev",
            nextEl: "#quick-view-next",
          }}
          lazy={true}
          preloadImages={false}
          thumbs={{ swiper: thumbsSwiper }}
        >
          {media?.length > 0 ? (
            media.map(item => (
              <SwiperSlide key={item?.id}>
                {item?.image?.src ? (
                  <Image image={item?.image} ratio={"15/19"} />
                ) : (
                  <Video
                    url={item?.sources?.[1]?.url}
                    image={item?.previewImage}
                    ratio={"15/19"}
                    autoplay
                    loop
                  />
                )}
              </SwiperSlide>
            ))
          ) : (
            <PlaceHolder />
          )}
        </Swiper>
      </Wrapper>
      <Swiper
        onSwiper={setThumbsSwiper}
        spaceBetween={10}
        slidesPerView={3.5}
        freeMode={true}
        watchSlidesVisibility={true}
        watchSlidesProgress={true}
      >
        {media?.length > 0
          ? media.map(item => (
              <SwiperSlide key={item?.id}>
                {item?.image?.src ? (
                  <Image image={item?.image} ratio={"15/19"} />
                ) : (
                  <VideoCoverImage>
                    <Image image={item?.previewImage} ratio={"15/19"} />
                    <IconWrapper>
                      <Icon name="video" width="30" height="30" />
                    </IconWrapper>
                  </VideoCoverImage>
                )}
              </SwiperSlide>
            ))
          : null}
      </Swiper>
    </>
  ),
)
