
import { DotHeadless } from "@dotdev/dotheadless-app"

import { sanityContent } from "../helpers/sanity"

export const useCore = () => {
  return {
    ...DotHeadless,
    helpers: {
      ...DotHeadless.helpers,
      sanityContent,
    },
  }
}
