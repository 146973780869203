import React, { useEffect } from "react"

import { useShopifyProduct } from "../../hooks/useShopify"
import { useAnalytics } from "../../hooks/useAnalytics"
import { useMaintenance } from "../../hooks/useMaintenance"
import { useCheckoutContext } from "../../hooks/useCheckout"
import { useApp } from "../../hooks/useApp"
import { useDotdigital } from "../../hooks/useDotdigital"
import { useEmarsys } from "../../hooks/useEmarsys"
import { useCustomerContext } from "../../hooks/useCustomer"
import { usePrevious } from "../../hooks/usePrevious";
import { useOrganisation } from "../../hooks/useOrganisation"

export const withLayout = Component => ({
  name = "Layout",
  location,
  path,
  children,
  data,
  settings: { settings },
}: any) => {
  const { globalState } = useApp()
  const { trackWebBehavior } = useDotdigital()
  const {
    checkout: { currencyCode },
  } = useCheckoutContext()

  const {
    organisation: { title },
  } = useOrganisation()

  const { active, authorised } = useMaintenance(location)
  const { selectProduct } = useShopifyProduct()
  const { trackEvent } = useAnalytics()
  const {
    emarsysLoaded,
    trackPage
  } = useEmarsys()
  const { customer } = useCustomerContext()
  const { checkout } = useCheckoutContext()
  const prevCustomer = usePrevious(customer)
  const prevCheckout = usePrevious(checkout)

  useEffect(() => {
    selectProduct(data?.product, location?.pathname)

  }, [
    location?.pathname,
    currencyCode,
    data?.product,
    selectProduct,
  ])

  useEffect(() => {
    trackEvent()
    trackWebBehavior()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location?.pathname])

  // Emarsys Web tracking, the customer identify, cart and go are required to be fired on every page.
  useEffect(() => {
    if (emarsysLoaded && checkout) {
      trackEmarsys()
    }
  }, [emarsysLoaded])

  useEffect(() => {
    const logout = !customer && prevCustomer

    // Don't track if logging out, it will be tracked in the path change useEffect
    if (emarsysLoaded && !logout) {
      trackEmarsys()
    }
  }, [customer])

  useEffect(() => {
    if (emarsysLoaded && !prevCheckout && checkout) {
      trackEmarsys()
    }
  }, [checkout])

  useEffect(() => {
    const login = !customer && path === "/account/"

    // Don't track logging in, it will be tracked in the customer change useEffect
    if (emarsysLoaded && checkout && !login) {
      trackEmarsys()
    }
  }, [path])

  const [, dispatch] = globalState

  useEffect(() => {
    dispatch({
      type: "ROUTE_CHANGE",
    })
  }, [path, dispatch])

  const trackEmarsys = () => {
    trackPage(customer, checkout, data?.collection, data?.product?.shopify?.id)
  }

  const homepage = location?.pathname === "/"
  const routes = settings?.routes

  Component.displayName = name
  return active ? (
    <>{children}</>
  ) : (
    authorised && (
      <Component
        data={data}
        location={location}
        title={title}
        routes={routes}
        homepage={homepage}
      >
        {children}
      </Component>
    )
  )
}
