import React from "react"

import { useWindowSize } from "../../../hooks/useWindowSize"
import { useApp } from "../../../hooks/useApp"

export const withBrand = Component => ({ name = "Brand" }) => {
  const { windowSize } = useWindowSize()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const iconWidth = windowSize.width > 768 ? "300" : "180"
  const iconHeight = windowSize.width > 768 ? "80" : "40"

  const url = routes.HOMEPAGE

  Component.displayName = name
  return <Component url={url} width={iconWidth} height={iconHeight} />
}
