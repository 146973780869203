import React from "react"

import { useApp } from "../../../hooks/useApp"
import { getVariantBySelectedOptions } from "../../../hooks/useShopify"

export const withVariants = Component => ({
  name = "Variants",
  handleVariant,
  product,
  selectedOptions = null,
}) => {
  const {
    config: {
      settings: {
        constraints: { SIZE },
      },
    },
  } = useApp()

  const isVariantOutOfStock = option => {
    const variantSelectedOptions = [
      ...(selectedOptions?.filter(
        selectedOption => selectedOption.name !== option.name,
      ) || []),
      option,
    ]
    const variant = getVariantBySelectedOptions(
      variantSelectedOptions,
      product?.variants,
    )

    return !variant?.availableForSale
  }

  Component.displayName = name
  return (
    <Component
      handleVariant={handleVariant}
      options={product?.options}
      product={product}
      selectedOptions={selectedOptions}
      SIZE={SIZE}
      isVariantOutOfStock={isVariantOutOfStock}
    />
  )
}
