export default {
  template: {
    collection: {
      additionalChildrenCollectionsTitleText: "Shop by",
      additionalFilterProductsText: "Filter Products",
      additionalClearFilterText: "Clear",
      additionalAllOptionSelected: "All",
      additionalOptionSelected: "selected",
      additionalFilterAndSortText: "Filter & Sort",
      additionalFilterRefineText: "Refine",
      additionalClearAllFilterText: "Clear All",
      additionalApplyFiltersText: "Apply",
      additionalSortByPlaceholder: "Sort by",
      additionalQuickViewText: "Quick View",
      additionalViewFullDetailsText: "View Full Details",
      additionalPageSizeText: "Products per page",
    },
    global: {
      additionalFindAStoreText: "Find a store",
      additionalMyAccountText: "My account",
      additionalSearchText: "Search",
      additionalCollectionsTitle: "Categories",
      additionalProductsTitle: "Products",
      additionalViewAllResultsText: "View all results",
      additionalInstantSearchPlaceholderText: "Search for products",
      additionalPopularCollectionsTitle: "Popular Categories",
      additionalBrowseAllProducts: "Browse all products",
      additionalSocialShareTitle: "Follow us",
      additionalCookieAcceptButtonText: "Ok, Accept",
    },
    article: {
      additionalAuthorText: "Author",
      additionalContinueText: "Continue reading",
      additionalArticlesTitle: "Articles",
      additionalReadArticleText: "Read article",
    },
    career: {
      additionalIntroductionTitleText: "Introduction",
      additionalLocationTitleText: "Location",
      additionalSalaryTitleText: "Salary",
      additionalApplyButtonText: "Apply Now",
      additionalFullDescriptionTitleText: "Full job description",
    },
    product: {
      additionalProductCodeTitleText: "Product code",
    },
  },
  page: {
    account: {
      additionalLogoutButtonText: "Log Out",
    },
    search: {
      additionalSearchButtonText: "Search",
      additionalSearchPlaceholderText: "Try another search",
    },
    articles: {
      additionalSortByText: "Sort by",
      additionalFilterText: "Select a category",
      additionalPageSizeText: "Articles per page",
      additionalSortByPublishDateDescText: "Publish date new to old",
      additionalSortByPublishDateAscText: "Publish date old to new",
    },
    careers: {
      additionalCurrentVacanciesTitleText: "Current Vacancies",
      additionalReadFullDescription: "Read Full Description",
      additionalPositionTitleText: "Position",
      additionalWorkTypeTitleText: "Work Type",
      additionalLocationTitleText: "Location",
    },
  },
}
