import React from "react"
import { ApolloClient, ApolloProvider, ApolloConsumer, HttpLink, InMemoryCache } from "@apollo/client"

import { useLocation } from "../hooks/useLocation"

export const ShopifyProvider = ({ children, config, allStores }): JSX.Element => {
  const { shopifyStore: currentStore } = useLocation()

  const shopifyConfig = {
    ...config,
    ...(allStores[currentStore]?.shopName && { ...allStores[currentStore] }),
  }

  const client = new ApolloClient({
    link: new HttpLink({
      uri: `https://${shopifyConfig?.shopDomain}/api/${shopifyConfig?.apiVersion}/graphql.json`,
      headers: {
        "X-Shopify-Storefront-Access-Token": shopifyConfig?.accessToken,
      },
    }),
    cache: new InMemoryCache(),
  })

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export const withShopify = Component => (props): JSX.Element => <ApolloConsumer>{client => <Component {...props} shopify={client} />}</ApolloConsumer>
