import { useFirebase } from "./useFirebase"
import { graphql, useStaticQuery } from "gatsby"

export const useSale = () => {
  const fbResp = useFirebase()
  try {
    window.fbResp = {
      hmm: true,
      fbResp,
    }
  } catch {
    // nothing
  }

  const { settings } = useStaticQuery(graphql`
    query StaticPromotions {
      settings: sanitySettingPromotions {
        active
      }
    }
  `)

  const isDev =
    process.env.GATSBY_ACTIVE_ENV === "development" ||
    process.env.NODE_ENV === "development"

  const sale = { enabled: true, name: isDev ? settings?.active : "bundles" }

  const getSaleVisibility = (
    promotion = [],
    promotionVisibility: "hide" | "show",
  ): "hide" | "show" => {
    const sectionPromotions =
      promotion?.reduce(
        (result, { slug }) =>
          slug?.current ? [...result, slug.current] : result,
        [],
      ) || []
    const isPromotionActive =
      sale?.enabled &&
      typeof sale.name === "string" &&
      sectionPromotions.includes(sale.name.toLowerCase())

    // TODO new way of determining promotion currently disabled
    // const isPromotionActive = sectionPromotions.includes(settings?.active.toLowerCase())

    if (promotionVisibility === "show" && !isPromotionActive) return "hide"
    return isPromotionActive && promotionVisibility
      ? promotionVisibility
      : "show"
  }

  const getOnSale = () => {
    if (sale?.enabled && sale?.name) {
      return sale?.name
    }

    return "bundles"
  }

  return { getSaleVisibility, getOnSale }
}
