import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Wrapper = styled.div``

export const Colour = styled.div`
  ${tw`relative w-2 h-2 border border-transparent rounded-full`}
  ${({ image }) => {
    return css`
      background-image: url(${image});
      background-size: cover;
    `
  }}
  &:after {
    content: "";
    ${({ active }) =>
      active
        ? tw`absolute -top-0-4 -left-0-4 w-2-6 h-2-6 border border-grey rounded-full`
        : null}
  }
`
