import tw, { styled } from "twin.macro"

export const Outer = styled.section`
  ${tw`overflow-hidden transition-all duration-300 z-50 mx-2-4  md:mx-0 h-3-6 md:h-3 bg-white`}
`

export const Inner = tw.div`
  w-full h-full relative flex items-center
`

export const Item = styled.div`
  ${tw`absolute inset-0 h-full flex items-center justify-center text-center transform transition-all duration-300 text-10 md:text-12 font-bold uppercase tracking-15`}
  ${({ active }) =>
    active
      ? tw`opacity-100 visible translate-y-0`
      : tw`opacity-0 invisible -translate-y-6`}
`

export const Content = styled.p`
  color: ${({ color }) => color};
`
