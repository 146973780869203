import React from "react"
import { Link } from "gatsby"

import { Icon } from "../../Icon/Icon"
import { withFindAStore } from "./withFindAStore"
import { Wrapper } from "./FindAStoreStyles"

export const FindAStore = withFindAStore(({ title, url, showMobile }) => (
  <Wrapper as={Link} to={url} showMobile={showMobile}>
    <Icon name={`markerOutline`} />
    <span>{title}</span>
  </Wrapper>
))
