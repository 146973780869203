import React from "react"

import { withNavigationHeaderMobileMain } from "./withNavigationHeaderMobileMain"
import {
  List,
  ListItem,
  SubListButton,
} from "./NavigationHeaderMobileMainStyles"
import { Link } from "../../../../Link/Link"
import { NavigationHeaderMobileUtils } from "./Utils/NavigationHeaderMobileUtils"
import { NavigationHeaderMobileSubList } from "./SubList/NavigationHeaderMobileSubList"
export const NavigationHeaderMobileMain = withNavigationHeaderMobileMain(
  ({ items, activeItem, setActiveItem }) =>
    activeItem ? (
      <NavigationHeaderMobileSubList
        item={items.find(item => item._key === activeItem)}
        setActiveItem={setActiveItem}
      />
    ) : (
      <>
        <List>
          {items?.map(item => (
            <ListItem key={item._key}>
              {item?.items?.length > 0 ? (
                <SubListButton
                  onClick={() => setActiveItem(item._key)}
                  color={item?.link?.color?.hex}
                >
                  {item?.title}
                </SubListButton>
              ) : (
                <Link
                  title={item?.title}
                  url={item?.url}
                  trackingPrefix={"navigation_header_mobile_level_1"}
                  external={item?.external}
                  isExternal={item?.isExternal}
                  color={item?.color}
                />
              )}
            </ListItem>
          ))}
        </List>
        <NavigationHeaderMobileUtils />
      </>
    ),
)
