import tw, { styled } from "twin.macro"
import { RichText } from "../../RichText/RichText"

export const Outer = tw.div`relative`

export const Inner = styled.div`
  ${tw`absolute inset-0 w-full h-full flex justify-center items-center p-1`}
`

export const Content = styled(RichText)``
