import React from "react"
import { useShopifyVariants } from "../../../../hooks/useShopify"

export const withQuickViewForm = Component => ({
  name = "Form",
  activeProduct,
  products,
  activeProductHandle,
  setActiveProductHandle,
}) => {
  const { tags, title, variants } = activeProduct

  const { activeVariant, selectedOptions, handleVariant } = useShopifyVariants({
    product: activeProduct,
  })

  const multipleVariants = variants.length > 1

  const currentVariant = activeVariant ?? variants?.[0]

  const isOnSale =
    currentVariant?.compareAtPriceV2?.amount > currentVariant?.priceV2?.amount

  Component.displayName = name
  return (
    <Component
      tags={tags}
      title={title}
      activeProduct={activeProduct}
      selectedVariant={activeVariant}
      defaultVariant={variants?.[0]}
      selectedOptions={selectedOptions}
      multipleVariants={multipleVariants}
      handleVariant={handleVariant}
      products={products}
      activeProductHandle={activeProductHandle}
      setActiveProductHandle={setActiveProductHandle}
      isOnSale={isOnSale}
    />
  )
}
