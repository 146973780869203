import React from "react"
import { Link } from "gatsby"

import { Wrapper, Colour } from "./SwatchStyles"

export const Swatch = ({
  url,
  title,
  image,
  active,
  className = "",
  ...props
}) => {
  return (
    <Wrapper
      className={className}
      as={url?.length > 0 ? Link : null}
      to={url}
      title={title}
      {...props}
    >
      <Colour image={image} active={active} />
    </Wrapper>
  )
}
