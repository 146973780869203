import React from "react"
import { useRoutes } from "../../../hooks/useRoutes"

export const withNavigationItemsImageCard = Component => ({
  name = "NavigationItemsImageCard ",
  item,
}) => {
  const { linkResolver } = useRoutes()
  const { image, content } = item || {}
  const link = linkResolver(image?.link)

  Component.displayName = name
  return <Component image={image} content={content} link={link} />
}
