import React from "react"
import { navigate } from "gatsby"

import { getAdditionalText } from "../../../../utils/getAdditionalText"
import { useApp } from "../../../../hooks/useApp"
import { useSearch } from "@usereactify/search"

export const withInstantSearchResultsBottom = Component => ({
  name = "InstantSearchResultsBottom",
}) => {
  const {
    template: {
      global: {
        additionalViewAllResultsText,
      },
    },
  } = getAdditionalText()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { searchTerm } = useSearch()
  const handleSubmit = () => navigate(`${routes.SEARCH}?q=${searchTerm}`)

  Component.displayName = name
  return (
    <Component
      handleSubmit={handleSubmit}
      additionalViewAllResultsText={additionalViewAllResultsText}
    />
  )
}