import tw, { styled } from "twin.macro"

import { StyledContainer } from "../../Styled/Container"

export const Bg = tw.div`
  bg-white border-t border-b border-grey-lightest py-2-4
`

export const Wrapper = styled(StyledContainer)`
  ${tw`flex flex-row justify-between md:justify-around`}
`

export const Point = styled.div`
  ${tw`flex-col md:flex-row w-full items-center justify-start md:justify-center px-1-2 md:px-1-6`}
  ${({ hiddenOnMobile }) => (hiddenOnMobile ? tw`hidden md:flex` : tw`flex`)}
`

export const Title = styled.p`
  ${tw`text-center md:text-left text-12 md:leading-1.2 leading-1.33 tracking-5`}
`

export const IconWrapper = tw.div`
  md:mr-1 w-2-4 h-2-4 flex items-center justify-center
`
