import { useApp } from "./useApp"
import { useLocation } from "./useLocation"
import { useSale } from "./useSale"

export const useMessage = tags => {
  const {
    config: {
      settings: {
        tags: { message: messageTag },
      },
    },
  } = useApp()

  const { location } = useLocation()

  const { getOnSale } = useSale()

  const saleName = getOnSale()
  const saleNameSuffix = location === "NZ" ? `-${location?.toLowerCase()}` : ""

  const messageTagMatch = saleName + saleNameSuffix + ":"

  const messageTags = tags?.filter(tag => tag.includes(messageTag))

  const message = messageTags
    ?.find(tag => tag.includes(messageTagMatch))
    ?.split(":")?.[2]

  const colour = messageTags
    ?.find(tag => tag.includes(messageTagMatch))
    ?.split(":")?.[3]

  return {
    message,
    colour,
  }
}
