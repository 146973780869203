import React from "react"

import { Icon } from "../Icon/Icon"
import { AccordionAnimation } from "./AccordionAnimation"
import { withAccordion } from "./withAccordion"
import {
  Wrapper,
  TitleWrapper,
  InnerTitleWrapper,
  Title,
  Content,
  IconOuter,
  IconInner,
  PrefixIcon,
  IconWrapper,
} from "./AccordionStyles"
import { Image } from "../Image/Image"

export const Accordion = withAccordion(
  ({
    active,
    title,
    children,
    layout,
    onClick,
    handleActive,
    prefixIcon,
    subtitle,
  }) => {
    const Subtitle = subtitle
    return (
      <Wrapper layout={layout} onClick={onClick}>
        {prefixIcon ? (
          <PrefixIcon>
            <IconWrapper>
              <Image image={prefixIcon} ratio={"1/1"} />
            </IconWrapper>
          </PrefixIcon>
        ) : null}
        <TitleWrapper layout={layout} onClick={handleActive}>
          <InnerTitleWrapper>
            <Title layout={layout}>{title}</Title>
            {subtitle ? <Subtitle /> : null}
          </InnerTitleWrapper>
          <IconOuter>
            <IconInner active={active ? "false" : "true"}>
              <Icon name={layout === "bundleSize" ? "chevron" : "plus"} />
            </IconInner>
            <IconInner active={active ? "true" : "false"}>
              <Icon name={layout === "bundleSize" ? "chevronUp" : "minus"} />
            </IconInner>
          </IconOuter>
        </TitleWrapper>
        <AccordionAnimation active={active}>
          <Content layout={layout}>{children}</Content>
        </AccordionAnimation>
      </Wrapper>
    )
  },
)
