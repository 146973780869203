export const position = () => {
  return (
    document.documentElement.scrollTop ||
    document.body.parentNode.scrollTop ||
    document.body.scrollTop
  )
}

export const animateScrollTo = (to, callback = null, duration = 500) => {
  const inOutQuintic = function (t, b, c, d) {
    const ts = (t /= d) * t,
      tc = ts * t
    return b + c * (6 * tc * ts + -15 * ts * ts + 10 * tc)
  }

  const requestAnimFrame = (function () {
    return (
      window.requestAnimationFrame ||
      window.webkitRequestAnimationFrame ||
      window.mozRequestAnimationFrame ||
      function (callback) {
        window.setTimeout(callback, 1000 / 60)
      }
    )
  })()

  function move(amount) {
    document.documentElement.scrollTop = amount

    document.body.parentNode.scrollTop = amount
    document.body.scrollTop = amount
  }
  const start = position(),
    change = to - start,
    increment = 20

  let currentTime = 0

  const animateScroll = function () {
    // increment the time
    currentTime += increment
    // find the value with the quadratic in-out easing function
    const val = inOutQuintic(currentTime, start, change, duration)
    // move the document.body
    move(val)
    // do the animation unless its over
    if (currentTime < duration) {
      requestAnimFrame(animateScroll)
    } else {
      if (callback && typeof callback === "function") {
        // the animation is done so lets callback
        callback()
      }
    }
  }
  animateScroll()
}
