import React, { useEffect, useState } from "react"
import { useApp } from "../../../../hooks/useApp"
import { useQueries } from "../../../../hooks/useQueries"
import { useShopify } from "../../../../hooks/useShopify"
import { getAdditionalText } from "../../../../utils/getAdditionalText"
import { useEmarsys } from "../../../../hooks/useEmarsys"
import { useCustomerContext } from "../../../../hooks/useCustomer"
import { useCheckoutContext } from "../../../../hooks/useCheckout"
import { useCore } from "../../../../hooks/useCore"
import { useLocation } from "../../../../hooks/useLocation"

export const withQuickViewContent =
  Component =>
  ({ name = "QuickViewContent", handle, parentProductHandle }) => {
    const {
      helpers: { decodeShopifyId },
    } = useCore()
    const { location } = useLocation()
    const isGroupedProduct = parentProductHandle?.length > 0

    const [loading, setLoading] = useState(true)
    const [quickViewError, setQuickViewError] = useState(null)
    const [activeProductHandle, setActiveProductHandle] = useState(handle)

    const {
      template: {
        collection: { additionalViewFullDetailsText },
      },
    } = getAdditionalText()

    const {
      config: {
        settings: { routes },
      },
    } = useApp()

    const {
      localQueries: { GET_GROUPED_PRODUCT_BY_HANDLE },
    } = useQueries()

    const { customer } = useCustomerContext()
    const { checkout } = useCheckoutContext()
    const { trackPage } = useEmarsys()

    const { useLazyQuery, edgeNormaliser, productNormaliser } = useShopify()

    const [getProduct, { data, called, error }] = useLazyQuery(
      GET_GROUPED_PRODUCT_BY_HANDLE,
      {
        fetchPolicy: `cache-and-network`,
        nextFetchPolicy: "cache-first",
        variables: {
          countryCode: location,
          handle,
          parentQuery: `tag:'parent:${parentProductHandle}' -tag:'parent'`,
          firstMedia: 15,
          firstVariants: 30,
          firstCollections: 0,
          firstGroupedProducts: 20,
        },
      },
    )

    const item = productNormaliser(data?.product)

    const products = React.useMemo(() => {
      return isGroupedProduct
        ? edgeNormaliser(data?.grouped).map(product =>
            productNormaliser(product),
          )
        : item
        ? [item]
        : []
    }, [data, isGroupedProduct, item, edgeNormaliser, productNormaliser])

    useEffect(() => {
      if (!handle || called) return
      getProduct()
    }, [handle, called, getProduct])

    const state = React.useRef({})

    React.useEffect(() => {
      state.current = {
        customer,
        checkout,
        item,
        data,
        trackPage,
      }
    }, [customer, checkout, item, data, trackPage])

    useEffect(() => {
      if (loading && Boolean(item?.title)) {
        setQuickViewError(null)
        setLoading(false)
        state?.current?.trackPage(
          state?.current?.customer,
          state?.current?.checkout,
          null,
          decodeShopifyId(item?.id, "Product"),
        )
      } else if (data?.product === null) {
        setQuickViewError(
          "Something went wrong fetching the product. This product may be unavailable. Apologies for the inconvenience.",
        )
        setLoading(false)
      } else {
        setQuickViewError(null)
      }
    }, [item, loading, data])

    if (quickViewError) console.error(quickViewError)

    const url = `${routes.PRODUCT}/${activeProductHandle}`

    const productsHashTable = Object.fromEntries(
      products.map(product => [product.handle, product]),
    )

    // Adding a fallback if the product is a single colour one
    // if the parent can't be found, the item parse from the handle prop should be returned
    const activeProduct = productsHashTable[activeProductHandle] || item

     /**
     * Represents the size filter parameters obtained from the URL search query.
     * If the size filter parameter is not present in the URL, it defaults to an empty array.
     */
     const SizeFilterParams =
     new URLSearchParams(window.location.search).get('size') || "[]"

   /**
    * Filters available products based on selected size in RS
    * @param {Object} product - The product to filter.
    * @returns {boolean} - True if the product meets the criteria, false otherwise.
    */

   // Format the size filter parameters to uppercase
   const formatSizeFilterParams = JSON.parse(SizeFilterParams).map((filter) => {
     const splitFilter = filter.split("|");
     return splitFilter.length > 1 ? splitFilter[1].toUpperCase() : "";
   });

    // filter out sold out products
    const filteredProducts = products.filter((product) => {
      if (formatSizeFilterParams.length > 0) {
      if (product?.variants.some((variant) => formatSizeFilterParams.includes(variant?.selectedOptions.find(option => option.name === 'Size')?.value) && !variant.availableForSale)) {
        return false;
      }
      }
      return true;
    });

    Component.displayName = name
    return (
      <Component
        activeProduct={activeProduct}
        products={filteredProducts}
        activeProductHandle={activeProductHandle}
        setActiveProductHandle={setActiveProductHandle}
        loading={loading}
        quickViewError={quickViewError}
        url={url}
        additionalViewFullDetailsText={additionalViewFullDetailsText}
      />
    )
  }
