import React, { useState, useCallback, useMemo } from "react"
import { useCore } from "./useCore"
import { useApp } from "./useApp"
import { useShopify } from "./useShopify"
import { useQueries } from "./useQueries"
import { useCheckout, useCheckoutContext } from "./useCheckout"
import { useAnalytics } from "./useAnalytics"
import { CheckoutProcessor, FREE_GIFT_ATTR } from "../utils/checkoutProcessor"
import { useCustomerContext } from "./useCustomer";
import { useEmarsys } from "./useEmarsys";
import { useLocation } from "./useLocation"

export const useCart = () => {
  const {
    helpers: { storage, decodeShopifyId, encodeShopifyId },
  } = useCore()
  const {
    mutations: { CHECKOUT_LINE_ITEMS_REPLACE },
  } = useQueries()
  const { location: userLocation } = useLocation()
  const {
    config: {
      settings: { keys, routes },
    },
    globalState,
  } = useApp()
  const { customer } = useCustomerContext()
  const { checkout, livePromotionRules, freeGiftSets, setFreeGiftSets } = useCheckoutContext()
  const { setCheckout } = useCheckout()
  const { useMutation, checkoutNormaliser } = useShopify()
  const { trackCartUpdate } = useAnalytics()
  const { trackPage } = useEmarsys()
  const [loading, setLoading] = useState(false)
  const [loadingRemove, setLoadingRemove] = useState(false)
  const [errors, setErrors] = useState([])
  const checkoutId = React.useMemo(() => {
    return checkout?.id || storage.get(keys?.checkout)
  }, [checkout, storage, keys])
  const [, dispatch] = globalState

  /**
   * A subset of freeGiftSets that contains only free gift sets still available
   * for the customer to choose from
   */
  const availableFreeGiftSets = useMemo(() => {
    const addedFreeGiftsTally = {} // promotionRuleKey => count
    const checkoutLineItems = checkout ? checkout.lineItems : []
    for (const checkoutLineItem of checkoutLineItems) {
      for (const customAttribute of checkoutLineItem.customAttributes) {
        if (customAttribute.key === FREE_GIFT_ATTR) {
          addedFreeGiftsTally[customAttribute.value] = addedFreeGiftsTally[customAttribute.value] || 0
          addedFreeGiftsTally[customAttribute.value] += checkoutLineItem.quantity
        }
      }
    }
    const available = []
    for (const freeGiftSet of freeGiftSets) {
      if (typeof addedFreeGiftsTally[freeGiftSet.promotionActionKey] === 'undefined' || addedFreeGiftsTally[freeGiftSet.promotionActionKey] < freeGiftSet.takeCount) {
        available.push(freeGiftSet)
      }
    }
    return available
  }, [
    freeGiftSets,
    checkout,
  ])

  /**
   * A list of products in availableFreeGiftSets, excluding those that are
   * already included in the cart
   */
  const availableFreeGiftProducts = useMemo(() => {
    let freeGiftProducts = []
    for (const freeGiftSet of availableFreeGiftSets) {
      freeGiftProducts = freeGiftProducts.concat(freeGiftSet.fromProducts)
    }
    return freeGiftProducts
    // Disabling the filtering as we already count the number of gifts available
    // a GWP product should be able to be added multiple times if mutltiple promotions are available

    // return freeGiftProducts.filter((product) => {
    //   const shopifyProduct = JSON.parse(product.shopify.shopifyRaw)
    //   const checkoutLineItems = checkout.lineItems || []
    //   return shopifyProduct.variants.every(variant => checkoutLineItems.every(lineItem => lineItem.variant.id !== variant.id))
    // })
  }, [availableFreeGiftSets])

  const [lineItemsReplace] = useMutation(CHECKOUT_LINE_ITEMS_REPLACE)

  const addAllToCart = useCallback(
    async (items) => {
      setLoading(true)

      const processor = new CheckoutProcessor(checkout, livePromotionRules)
      for (const item of items) {
        processor.addLineItem(item?.product, item?.variant, item?.quantity || 1, item?.customAttributes || [])
      }
      const {
        lineItemInput: newLineItems,
        freeGiftSets: newFreeGiftSets,
      } = processor.finaliseLineItemInput()

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          countryCode: userLocation,
          lineItems: newLineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setFreeGiftSets(newFreeGiftSets)
        setCheckout(data?.checkout)
        if (!location.pathname.startsWith(routes.CART)) {
          dispatch({
            type: "SHOW_CART",
          })
        }
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate(
        "add",
        items?.[0]?.variant?.id,
        items?.[0]?.quantity || 1,
        normalisedCheckout?.lineItems,
      )
      trackPage(customer, normalisedCheckout)
    },
    [checkout, livePromotionRules, userLocation, lineItemsReplace, checkoutId, checkoutNormaliser, trackCartUpdate, trackPage, customer, setFreeGiftSets, setCheckout, routes.CART, dispatch],
  )

  const addToCart = useCallback(
    async (product, variant, quantity = 1, customAttributes = []) => {
      setLoading(true)
      
      const processor = new CheckoutProcessor(checkout, livePromotionRules)
      processor.addLineItem(product, variant, quantity, customAttributes)
      const {
        lineItemInput: newLineItems,
        freeGiftSets: newFreeGiftSets,
      } = processor.finaliseLineItemInput()

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          countryCode: userLocation,
          lineItems: newLineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setFreeGiftSets(newFreeGiftSets)
        setCheckout(data?.checkout)
        if (!location.pathname.startsWith(routes.CART)) {
          dispatch({
            type: "SHOW_CART",
          })
        }
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate(
        "add",
        variant.id,
        quantity,
        normalisedCheckout?.lineItems,
      )
      trackPage(customer, normalisedCheckout)
    },
    [checkout, livePromotionRules, userLocation, lineItemsReplace, checkoutId, checkoutNormaliser, trackCartUpdate, trackPage, customer, setFreeGiftSets, setCheckout, routes.CART, dispatch],
  )

  const removeFromCart = useCallback(
    async lineItemId => {
      setLoadingRemove(true)

      const lineItemToRemove = checkout?.lineItems.find(
        ({ id }) => id === lineItemId,
      )
      if (lineItemToRemove) {
        const quantity = lineItemToRemove.quantity
        const processor = new CheckoutProcessor(checkout, livePromotionRules)
        processor.removeLineItem(lineItemToRemove.id)
        const {
          lineItemInput: newLineItems,
          freeGiftSets: newFreeGiftSets,
        } = processor.finaliseLineItemInput()
        trackCartUpdate("remove", lineItemToRemove.variant.id, quantity, checkout?.lineItems)

        const {
          data: { checkoutLineItemsReplace: data, userErrors: errors },
        } = await lineItemsReplace({
          variables: {
            checkoutId,
            countryCode: userLocation,
            lineItems: newLineItems,
          },
        })

        if (errors?.length) setErrors(errors)
        if (data) {
          setFreeGiftSets(newFreeGiftSets)
          await setCheckout(data?.checkout)
          trackPage(customer, checkoutNormaliser(data?.checkout))
        }
      }

      setLoadingRemove(false)
    },
    [checkout, livePromotionRules, trackCartUpdate, lineItemsReplace, checkoutId, userLocation, setFreeGiftSets, setCheckout, trackPage, customer, checkoutNormaliser],
  )

  const updateQuantity = useCallback(
    async (lineItemId, quantity) => {
      setLoading(true)
      const lineItemToUpdate = checkout?.lineItems.find(
        ({ id }) => id === lineItemId,
      )
      const processor = new CheckoutProcessor(checkout, livePromotionRules)
      
      
      processor.updateLineItemQuantity(lineItemId, quantity)
      const {
        lineItemInput: newLineItems,
        freeGiftSets: newFreeGiftSets,
      } = processor.finaliseLineItemInput()

      const {
        data: { checkoutLineItemsReplace: data, userErrors: errors },
      } = await lineItemsReplace({
        variables: {
          checkoutId,
          countryCode: userLocation,
          lineItems: newLineItems,
        },
      })

      if (errors?.length) setErrors(errors)
      if (data) {
        setFreeGiftSets(newFreeGiftSets)
        setCheckout(data?.checkout)
      }
      setLoading(false)

      const normalisedCheckout = checkoutNormaliser(data?.checkout)

      trackCartUpdate(
        `change`,
        lineItemToUpdate.variant.id,
        quantity,
        normalisedCheckout?.lineItems,
      )
      trackPage(customer, normalisedCheckout)
    },
    [checkout, livePromotionRules, lineItemsReplace, checkoutId, userLocation, checkoutNormaliser, trackCartUpdate, trackPage, customer, setFreeGiftSets, setCheckout],
  )

  const addFreeGiftToCart = useCallback((selectedVariant) => {
    // Find the corresponding freeGiftSet which the variant belongs to
    let freeGiftSet = null
    let shopifyProduct = null
    loop: {
      for (const set of availableFreeGiftSets) {
        for (const product of set.fromProducts) {
          const parsedShopifyProduct = JSON.parse(product.shopify.shopifyRaw)
          for (const variant of parsedShopifyProduct.variants) {
            if (decodeShopifyId(variant.id, "ProductVariant")?.replace("gid://shopify/ProductVariant/", "") === selectedVariant.id?.replace("gid://shopify/ProductVariant/", "")) {
              freeGiftSet = set
              shopifyProduct = parsedShopifyProduct
              break loop
            }
          }
        }
      }
    }
    if (!freeGiftSet || ! shopifyProduct) {
      return
    }

    // Add free gift to cart
    return addToCart(shopifyProduct, selectedVariant, 1, [
      { key: FREE_GIFT_ATTR, value: freeGiftSet.promotionActionKey },
    ])
  }, [addToCart, availableFreeGiftSets, decodeShopifyId])

  return {
    addAllToCart,
    addToCart,
    removeFromCart,
    updateQuantity,
    addFreeGiftToCart,
    availableFreeGiftProducts,
    loading,
    loadingRemove,
    errors,
  }
}
