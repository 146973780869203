import React from "react"

import { useApp } from "../../../hooks/useApp"
import { useCheckout, useCheckoutContext } from "../../../hooks/useCheckout"
import { useLocation } from "../../../hooks/useLocation"
import { useCore } from "../../../hooks/useCore"

export const withCartWidget = Component => ({ name = "CartTotal" }: any) => {
  const { globalState,
    config: {
      settings: { keys },
    },
   } = useApp()
  const {location} = useLocation()
  const {
    helpers: { storage },
  } = useCore()

  const { createCheckout } = useCheckout()

  const { checkout, count } = useCheckoutContext()

  const [{ activeCart }, dispatch] = globalState

  const handleActiveCart = () => {
    dispatch({
      type: "TOGGLE_CART",
      payload: !activeCart,
    })
  }

  // React.useEffect(() => {
    // if (!checkout) createCheckout()
  // }, [checkout, createCheckout])

  React.useEffect(() => {
    const checkoutId = storage.get(keys?.checkout)
    console.log("withCartWidget: ", checkoutId, checkout, location);

    if (!checkout && location) createCheckout(location)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location])

  Component.displayName = name
  return <Component handleActiveCart={handleActiveCart} count={count} />
}
