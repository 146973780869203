import React from "react"

import { useMessage } from "../../../hooks/useMessage"

export const withMessage = Component => ({
  name = "Message",
  tags,
  layout,
}) => {
  const { message, colour } = useMessage(tags)

  Component.displayName = name
  return <Component message={message} layout={layout} colour={colour}/>
}
