import React from "react"

import { withNavigationHeaderMobileUtils } from "./withNavigationHeaderMobileUtils"
import {
  Outer,
  Inner,
  List,
  ListItem,
  SubList,
  SubListItem,
  AdditionalLink,
} from "./NavigationHeaderMobileUtilsStyles"
import { Link } from "../../../../../Link/Link"
import { Icon } from "../../../../../Icon/Icon"

export const NavigationHeaderMobileUtils = withNavigationHeaderMobileUtils(
  ({
    items,
    accountUrl,
    storeUrl,
    additionalFindAStoreText,
    additionalMyAccountText,
  }) => (
    <Outer>
      <div>
        <AdditionalLink to={storeUrl}>
          <Icon name={"markerOutline"} width={14} height={18} />
          <p>{additionalFindAStoreText}</p>
        </AdditionalLink>
        <AdditionalLink to={accountUrl}>
          <Icon name={"account"} width={14} height={18} />
          <p>{additionalMyAccountText}</p>
        </AdditionalLink>
      </div>
      <Inner>
        {items?.map(item => (
          <List key={item._key}>
            <ListItem>
              <Link
                title={item?.title}
                url={item?.url}
                external={item?.external}
                isExternal={item?.isExternal}
              />
            </ListItem>
            {item.items.length > 0 && (
              <SubList>
                {item.items.map(item => (
                  <SubListItem key={item._key}>
                    <Link
                      title={item?.title}
                      url={item?.url}
                      external={item?.external}
                      isExternal={item?.isExternal}
                    />
                  </SubListItem>
                ))}
              </SubList>
            )}
          </List>
        ))}
      </Inner>
    </Outer>
  ),
)
