import React from "react"

import { getAdditionalText } from "../../../utils/getAdditionalText"

export const withQuickView = Component => ({
  name = "QuickView",
  handle,
  parentProductHandle,
  layout = "card",
}) => {
  const {
    template: {
      collection: { additionalQuickViewText },
    },
  } = getAdditionalText()

  Component.displayName = name
  return (
    <Component
      handle={handle}
      additionalQuickViewText={additionalQuickViewText}
      parentProductHandle={parentProductHandle}
      layout={layout}
    />
  )
}
