import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const SearchButton = tw.button`
  flex flex-row items-center
`

export const Text = tw.p`
  text-12 font-bold tracking-10 leading-2 hidden mlg:block uppercase
`

export const StyledIcon = styled(Icon)`
  ${tw`mr-1-2`}
`
