import React from "react"
import { Link as GatsbyLink } from "gatsby"
import { withLink } from "./withLink"
import { CustomLink } from "./LinkStyles"

export const Link = withLink(
  (
    { 
      title, 
      url, 
      external, 
      className, 
      isExternal, 
      color, 
      hjTrackClick 
    }: {
      title: any; 
      url: any; 
      external: any; 
      className?: any; 
      isExternal: any; 
      color: any; 
      hjTrackClick: any;
    }
  ) => (
  <CustomLink
    as={url && (isExternal || external ? `a` : GatsbyLink)}
    href={(isExternal || external) ? url : ""}
    className={className}
    target={external ? `_blank` : ""}
    title={title}
    onClick={() => hjTrackClick(title)}
    to={!isExternal ? url : ""}
    color={color?.hex}
  >
    {title}
  </CustomLink>
))
