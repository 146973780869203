import tw, { styled } from "twin.macro"
import { StyledButton } from "../../../Styled/Button"

export const Wrapper = tw.div`
flex flex-col md:flex-row 
`

export const ImagesWrapper = tw.div`
  w-full md:max-w-30 mr-0 mb-4 md:mb-0 md:mr-4
`

export const DetailsWrapper = tw.div`
  w-full
`

export const ViewFullDetails = styled(StyledButton)`
  ${tw`mt-0-8`}
`

export const Placeholder = tw.div`h-45 flex items-center justify-center`
