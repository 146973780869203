import tw, { styled } from "twin.macro"

const styles = {
  colour: {
    navyFilledWithWhiteText: tw`bg-navy text-white border border-navy`,
    whiteFilledWithNavyText: tw`bg-white border border-white`,
    redFilledWithWhiteText: tw`bg-red text-white border border-red`,
    whiteFilledWithRedText: tw`bg-white text-red border border-white`,
    whiteBorderedWithWhiteText: tw`bg-transparent border border-white text-white`,
    whiteBorderedWithWhiteTextHoverNavy: tw`bg-transparent border border-white hover:border-navy text-white hover:text-navy`,
    blackBorderedWithBlackText: tw`bg-transparent border border-black text-black`,
    navyBorderedWithNavyText: tw`bg-transparent border border-navy`,
  },
  size: {
    primary: tw`px-1-6 py-1-6 md:px-2-4`,
    secondary: tw`py-0-8 px-1-6 md:py-1-6 md:px-2-4`,
    tertiary: tw`py-1-1 px-1-8`,
    filter: tw`py-1-4`,
  },
  stretch: tw`w-full h-full`,
  wide: tw`w-full`,
  state: {
    disabled: tw`pointer-events-none opacity-50`,
  },
}

export const StyledButton = styled.button`
  ${tw`select-none outline-none leading-none inline-block font-body text-center text-14 font-medium tracking-5 uppercase border-2 border-solid rounded focus:outline-none transition-all max-w-full duration-200`}
  ${({ size }) => (size && styles.size[size]) || styles.size[`secondary`]}
  ${({ stretch }) => stretch && styles.stretch}
  ${({ wide }) => wide && styles.wide}
  ${({ colour }) =>
    (colour && styles.colour?.[colour]) ||
    styles.colour[`navyFilledWithWhiteText`]}
  ${({ disabled }) => disabled && styles.state.disabled}
  span {
    ${tw`block`}
  }
`

export const UnderlinedButton = styled.button`
  span,
  a {
    ${({ size }) => {
      if (size === "primary") return tw`font-semibold uppercase`
      if (size === "tertiary")
        return tw`font-semibold uppercase tracking-10 text-13`
    }}
    ${({ colour }) => {
      switch (colour) {
        case "underlinedLinkWhite":
          return tw`text-white`
        case "underlinedLinkRed":
          return tw`text-red`
        case "underlinedLinkNavy":
          return tw`text-navy`
        case "underlinedLinkNavyLight":
          return tw`text-navy bg-opacity-60`
        default:
          return tw`text-navy`
      }
    }}
    ${tw`relative text-14`}
    &:after {
      content: "";
      ${tw`absolute left-0 w-full`}
      ${({ size }) => {
        if (size === "primary") return tw`h-0-1 -bottom-0-2`
        else return tw`h-0-1 -bottom-0-4`
      }}
      ${({ colour }) => {
        switch (colour) {
          case "underlinedLinkWhite":
            return tw`bg-white`
          case "underlinedLinkRed":
            return tw`bg-red`
          case "underlinedLinkNavyLight":
            return tw`bg-navy bg-opacity-60`
          default:
            return tw`bg-navy`
        }
      }}
    }
  }
`
