import tw, { styled } from "twin.macro"
import { Icon } from "../../../../../Icon/Icon"

export const Wrapper = tw.div`px-2-4`

export const Back = tw.button`w-full flex flex-row items-center text-16 leading-1.62 py-1 my-1 border-b border-grey-lightest text-grey`

export const StyledIcon = styled(Icon)`
  ${tw`mr-1`}
`

export const ShopAll = tw.div`mt-3 pb-3 border-b border-grey-lightest`

export const List = tw.div`pt-3-2 pb-1-6 border-b border-grey-lightest`

export const ListItem = tw.div`font-bold uppercase text-14 leading-1.42 tracking-10 mb-2-4`

export const SubListItem = tw.div`pl-1-6 text-14 leading-1.42 tracking-5 mb-1-6 text-navy-light`

export const ImagesWrapper = tw.div`grid grid-cols-2 gap-1-6`
