import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useAnnouncement } from "../../hooks/useAnnouncement"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"

export const withAnnouncement = Component => ({
  name = "Announcement",
  global,
  show,
}: {
  name?: any
  global?: any
  show: any
}) => {
  const {
    items,
    hide,
    active,
    countdownItem,
    displayCountdown,
    backgroundColour = { hex: "#EFDC9C" },
  } = useAnnouncement(global)

  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { countdownData } = useStaticQuery(graphql`
    query SANITY_SETTINGS_COUNTDOWN {
      countdownData: sanitySettingAnnouncement {
        stickyCountdown
        days
        hours
        minutes
        seconds
      }
    }
  `)
  
  const {
    stickyCountdown = false,
    days = "Days",
    hours = "Hours",
    minutes = "Minutes",
    seconds = "Seconds",
  } = countdownData || {}

  const handleHide = () => hide()

  const isAnnouncementHidden = storage.get(keys?.announcement) === "hidden"

  Component.displayName = name
  return (
    <Component
      active={active}
      items={items}
      handleHide={handleHide}
      show={show}
      isAnnouncementHidden={isAnnouncementHidden}
      displayCountdown={displayCountdown}
      stickyCountdown={stickyCountdown}
      countdownItem={countdownItem}
      days={days}
      hours={hours}
      minutes={minutes}
      seconds={seconds}
      backgroundColour={backgroundColour}
    />
  )
}
