import React from "react"

import { Outer, Inner } from "./ImageWithHoverStyles"
import { Image } from "../Image/Image"

export const ImageWithHover = ({ image, hoverImage, ratio }) => (
  <Outer className={"group"}>
    <Image image={image} ratio={ratio} />
    {hoverImage?.src ? (
      <Inner>
        <Image image={hoverImage} ratio={ratio} />
      </Inner>
    ) : null}
  </Outer>
)
