import tw, { css, styled } from "twin.macro"

export const Scroll = tw.div`w-full overflow-y-auto overflow-x-auto `
export const Table = tw.div`table w-full border border-grey-lightest`
export const Row = styled.div`
  ${tw`table-row`}
  :first-of-type {
    ${tw`bg-grey-light border-white`}
  }
`
export const Column = styled.div`
  ${tw`table-cell p-1-2 text-14 border border-grey-lightest`}
  ${({ layout }) =>
    layout === "sizeChart"
      ? tw`text-center whitespace-nowrap`
      : tw`leading-1.4`}
  ${({ cols }) => css`
    width: ${100 / cols}%;
  `}

  ul,
  ol {
    list-style: initial;
    list-style-position: inside;
  }
  a {
    text-decoration: underline;
  }
`
