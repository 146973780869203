import { Link } from "gatsby"
import tw, { styled } from "twin.macro"

import { Icon } from "../../Icon/Icon"

export const StyledIcon = styled(Icon)`
  ${tw`ml-1-6`}
`

export const StyledLink = styled(Link)`
  ${tw`hidden mlg:block`}
`
