import React from "react"

import { withVariants } from "./withVariants"
import { Wrapper, Grid, Variant } from "./VariantsStyles"
import {
  StyledSelectWrapper,
  StyledSelect,
  StyledSelectOption,
  StyledInputIconWrapper,
} from "../../Styled/Form"
import { Icon } from "../../Icon/Icon"

export const Variants = withVariants(
  ({ handleVariant, options, selectedOptions, SIZE, isVariantOutOfStock }) =>
    options?.length > 0 ? (
      <Wrapper>
        {options?.map((option, index) =>
          option.name === SIZE ? (
            <Grid key={option?.name?.toString() + index}>
              {option?.values?.map((value, index) => (
                <Variant
                  key={value?.toString() + index}
                  active={
                    selectedOptions?.find(({ name }) => name === option?.name)
                      ?.value === value
                  }
                  outOfStock={isVariantOutOfStock({ name: option.name, value })}
                  onClick={() => handleVariant({ name: option.name, value })}
                >
                  {value}
                </Variant>
              ))}
            </Grid>
          ) : option?.values.length > 1 ? (
            <StyledSelectWrapper spacing>
              <StyledSelect
                layout={"productVariant"}
                value={
                  selectedOptions?.find(({ name }) => name === option?.name)
                    ?.value
                }
                onChange={({ target: { value } }) =>
                  handleVariant({ name: option.name, value })
                }
              >
                <StyledSelectOption disabled value={""} selected>
                  {option?.name}
                </StyledSelectOption>
                {option?.values?.map((value, index) => (
                  <StyledSelectOption
                    key={value?.toString() + index}
                    value={value}
                    disabled={isVariantOutOfStock({ name: option.name, value })}
                  >
                    {value}
                  </StyledSelectOption>
                ))}
              </StyledSelect>
              <StyledInputIconWrapper>
                <Icon
                  name={"chevron"}
                  className={`mb-0-8 mr-0-8`}
                  width={"12"}
                  height={"12"}
                />
              </StyledInputIconWrapper>
            </StyledSelectWrapper>
          ) : null,
        )}
      </Wrapper>
    ) : null,
)
