import React from "react"
import { DesktopCardSwatches } from "./DesktopCardSwatches"
import { MobileCardSwatches } from "./MobileCardSwatches"

import { withCardSwatches } from "./withCardSwatches"

export const CardSwatches = withCardSwatches(
  ({
    activeProductHandle,
    defaultProductHandle,
    swatches,
    setActiveProductHandle,
    setHoverActiveProductHandle,
    isMobile,
  }) =>
    isMobile ? (
      <MobileCardSwatches
        activeProductHandle={activeProductHandle}
        defaultProductHandle={defaultProductHandle}
        swatches={swatches}
        setActiveProductHandle={setActiveProductHandle}
        setHoverActiveProductHandle={setHoverActiveProductHandle}
      />
    ) : (
      <DesktopCardSwatches
        activeProductHandle={activeProductHandle}
        defaultProductHandle={defaultProductHandle}
        swatches={swatches}
        setActiveProductHandle={setActiveProductHandle}
        setHoverActiveProductHandle={setHoverActiveProductHandle}
      />
    ),
)
