import React from "react"

import Account from "../../../static/icons/account.svg"
import Arrow from "../../../static/icons/arrow.svg"
import ArrowLeft from "../../../static/icons/arrow-left.svg"
import ArrowLeftSmall from "../../../static/icons/arrow-left-small.svg"
import Cart from "../../../static/icons/cart.svg"
import Chevron from "../../../static/icons/chevron.svg"
import ChevronUp from "../../../static/icons/chevron-up.svg"
import ChevronLeft from "../../../static/icons/chevron-left.svg"
import ChevronRight from "../../../static/icons/chevron-right.svg"
import ChevronDown from "../../../static/icons/chevron-down.svg"
import Cross from "../../../static/icons/cross.svg"
import Delivery from "../../../static/icons/delivery.svg"
import Email from "../../../static/icons/email.svg"
import GiftCard from "../../../static/icons/gift-card.svg"
import LiveChat from "../../../static/icons/live-chat.svg"
import Marker from "../../../static/icons/marker.svg"
import MarkerOutline from "../../../static/icons/marker-outline.svg"
import Plus from "../../../static/icons/plus.svg"
import QuickView from "../../../static/icons/quick-view.svg"
import Returns from "../../../static/icons/returns.svg"
import Search from "../../../static/icons/search.svg"
import SearchStore from "../../../static/icons/search-store.svg"
import Star from "../../../static/icons/star.svg"
import Tick from "../../../static/icons/tick.svg"
import Video from "../../../static/icons/video.svg"
import Wishlist from "../../../static/icons/wishlist.svg"
import WishlistFilled from "../../../static/icons/wishlist-filled.svg"
import Facebook from "../../../static/icons/facebook.svg"
import Pinterest from "../../../static/icons/pinterest.svg"
import Instagram from "../../../static/icons/instagram.svg"
import Logo from "../../../static/icons/logo.svg"
import Menu from "../../../static/icons/menu.svg"
import Minus from "../../../static/icons/minus.svg"
import SocialGoogle from "../../../static/icons/social-google.svg"
import SocialFacebook from "../../../static/icons/social-facebook.svg"
import Remove from "../../../static/icons/remove.svg"

import AfterPay from "../../../static/icons/payment-afterpay.svg"
import American from "../../../static/icons/payment-american.svg"
import ApplePay from "../../../static/icons/payment-applepay.svg"
import MasterCard from "../../../static/icons/payment-mastercard.svg"
import Paypal from "../../../static/icons/payment-paypal.svg"
import Visa from "../../../static/icons/payment-visa.svg"
import Zip from "../../../static/icons/payment-zip.svg"

export const withIcon =
  Component =>
  ({ componentName = "Icon", width, height, name, title, className }: any) => {
    const icons = {
      account: Account,
      arrow: Arrow,
      arrowLeft: ArrowLeft,
      arrowLeftSmall: ArrowLeftSmall,
      cart: Cart,
      chevron: Chevron,
      chevronUp: ChevronUp,
      chevronLeft: ChevronLeft,
      chevronRight: ChevronRight,
      chevronDown: ChevronDown,
      cross: Cross,
      delivery: Delivery,
      mail: Email,
      giftCard: GiftCard,
      liveChat: LiveChat,
      marker: Marker,
      markerOutline: MarkerOutline,
      quickView: QuickView,
      plus: Plus,
      returns: Returns,
      search: Search,
      searchStore: SearchStore,
      star: Star,
      tick: Tick,
      video: Video,
      wishlist: Wishlist,
      wishlistFilled: WishlistFilled,
      facebook: Facebook,
      pinterest: Pinterest,
      instagram: Instagram,
      logo: Logo,
      menu: Menu,
      minus: Minus,
      afterPay: AfterPay,
      american: American,
      applePay: ApplePay,
      masterCard: MasterCard,
      paypal: Paypal,
      visa: Visa,
      zip: Zip,
      socialGoogle: SocialGoogle,
      socialFacebook: SocialFacebook,
      remove: Remove,
    }

    const icon = icons[name]

    Component.displayName = componentName
    return icon ? (
      <Component
        icon={icon}
        title={title || name}
        width={width || icon.viewBox.split(" ")[2]}
        height={height || icon.viewBox.split(" ")[3]}
        className={className}
      />
    ) : (
      <p>{name}</p>
    )
  }
