import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { useRoutes } from "../../../hooks/useRoutes"

export const withFindAStore = Component => ({ name = "FindAStore", showMobile }) => {
  const { linkResolver } = useRoutes()
  const { header } = useStaticQuery(graphql`
    query SANITY_TEMPLATE_GLOBAL_HEADER {
      header: sanityTemplateGlobal {
        findAStore: _rawFindAStore(resolveReferences: { maxDepth: 2 })
      }
    }
  `)

  const { findAStore: originalFindAStore } = header || {}

  const { title, url } = linkResolver(originalFindAStore)
  Component.displayName = name
  return title?.length > 0 ? <Component title={title} url={url} showMobile={showMobile} /> : null
}
