import React from "react"

export const withLoading = Component => ({
  name = "Loading",
  size,
  notEmpty,
}) => {
  const placeholders = Array.from({ length: size }).map(
    (node, index) => `placeholder-${index}`,
  )

  Component.displayName = name
  return <Component placeholders={placeholders} notEmpty={notEmpty} />
}
