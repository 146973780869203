import React from "react"

import { Link } from "../../Link/Link"
import { withNavigationFooter } from "./withNavigationFooter"
import { List, Title, ListItem } from "./NavigationFooterStyles"

export const NavigationFooter = withNavigationFooter(
  ({ items }): JSX.Element => (
    <>
      {items?.map(item => (
        <List key={item._key}>
          <Title>
            <Link
              title={item?.title}
              url={item?.url}
              trackingPrefix={"footer"}
              external={item?.external}
              isExternal={item?.isExternal}
            />
          </Title>
          {item?.items?.map(listItem => (
            <ListItem key={listItem._key}>
              <Link
                title={listItem?.title}
                url={listItem?.url}
                trackingPrefix={"footer"}
                external={listItem?.external}
                isExternal={listItem?.isExternal}
              />
            </ListItem>
          ))}
        </List>
      ))}
    </>
  ),
)
