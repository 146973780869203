import React, { useCallback } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useFunctions } from "../../../../hooks/useFunctions"
import { useCheckoutContext, useCheckout } from "../../../../hooks/useCheckout"
import { useCustomerContext } from "../../../../hooks/useCustomer"
import { useRoutes } from "../../../../hooks/useRoutes"
import { useShopify } from "../../../../hooks/useShopify"
import { useApp } from "../../../../hooks/useApp"
import { useHotjar } from "../../../../hooks/useHotjar"
import { useLocation } from "../../../../hooks/useLocation"

export const withCheckout = Component => ({ name = "Checkout" }) => {
  const { customer } = useCustomerContext()

  const { checkoutUrl } = useCheckout()

  const { checkout } = useCheckoutContext()

  const { loading, checkoutMultipass } = useFunctions()

  const { linkResolver } = useRoutes()

  const { formatMoney } = useShopify()

  const { trackHotjarEvent } = useHotjar()

  const { cart } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_CHECKOUT {
      cart: sanityPageCart {
        freeShippingThreshold
        freeShippingCallToActionMessage
        continueShopping: _rawContinueShopping(
          resolveReferences: { maxDepth: 2 }
        )
        additionalCheckoutButton
        additionalCheckoutButtonLoading
        additionalKeepShoppingButton
      }
    }
  `)

  const {
    freeShippingThreshold,
    freeShippingCallToActionMessage,
    continueShopping,
    additionalCheckoutButton,
    additionalCheckoutButtonLoading,
    additionalKeepShoppingButton,
  } = cart || {}

  const { globalState } = useApp()
  
  const { location } = useLocation()

  const [, dispatch] = globalState

  const handleClose = useCallback(() => dispatch({ type: "HIDE_CART" }), [
    dispatch,
  ])

  const handleCheckout = useCallback(
    async event => {
      event.preventDefault()
      await checkoutMultipass(customer?.email, checkout.id, checkoutUrl)
    },
    [checkoutMultipass, checkout.id, checkoutUrl, customer?.email],
  )
  
  const freeShippingAmount =
    freeShippingThreshold - checkout?.paymentDue?.amount

  const freeShippingCallToAction = location === "AU" ?
    freeShippingAmount > 0
      ? freeShippingCallToActionMessage.replace(
          "${amount}",
          formatMoney(freeShippingAmount),
        )
      : ""
    : ""
  
  const continueButton = linkResolver(continueShopping)

  trackHotjarEvent("checkout_page")

  Component.displayName = name
  return (
    <Component
      checkoutUrl={checkoutUrl}
      loading={loading}
      handleCheckout={handleCheckout}
      continueButton={continueButton}
      additionalCheckoutButton={additionalCheckoutButton}
      additionalCheckoutButtonLoading={additionalCheckoutButtonLoading}
      additionalKeepShoppingButton={additionalKeepShoppingButton}
      handleClose={handleClose}
      freeShippingAmount={freeShippingAmount}
      freeShippingCallToAction={freeShippingCallToAction}
    />
  )
}
