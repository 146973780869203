import React from "react"

export const withImage =
  Component =>
  ({
    name = "Image",
    image,
    ratio = undefined,
    link = {},
    alt: externalAlt = "",
    ...rest
  }) => {
    const { alt } = image || {}

  const aspectRatio =
  typeof ratio === "string"
  ? Number(ratio?.split("/")[0]) / Number(ratio?.split("/")[1])
  : typeof ratio === "object" && ratio?.width > 0 && ratio?.height > 0
  ? Number(ratio?.width) / Number(ratio?.height)
  : undefined

    Component.displayName = name
    return (
      <Component
        image={image}
        link={link}
        alt={externalAlt ? externalAlt : alt}
        aspectRatio={aspectRatio}
        ratio={ratio}
        {...rest}
      />
    )
  }
