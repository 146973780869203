import tw, { styled } from "twin.macro"
import { Swatch } from "../../Swatch/Swatch"

export const Outer = styled.div`
  ${({ type }) => type === "bundle" ? tw`flex flex-col my-1-7` : tw`flex flex-col my-2-4`}
`

export const Inner = styled.div`
  ${({ type }) => type === "bundle" ? tw`flex flex-wrap items-center` : tw`flex flex-wrap items-center mb-0-4`}
`

export const Text = tw.p`
  text-14 leading-1.78
`

export const StyledSwatch = styled(Swatch)`
  ${tw`mr-1 mb-1 p-0-4`}
`
