import React from "react"

import { withMessage } from "./withMessage"
import { Wrapper } from "./MessageStyles"

export const Message = withMessage(({ message, layout, colour }) =>
  message ? (
    <Wrapper layout={layout} colour={colour}>
      {message}
    </Wrapper>
  ) : null,
)
