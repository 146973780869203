import React, { useState, useReducer, createContext } from "react"

export const AppContext: any = createContext({})

export const AppProvider = ({ children, config: siteConfig }): JSX.Element => {
  const [config] = useState(siteConfig || {})
  const [activeProduct, setActiveProduct] = useState(false)
  const [activeCollection, setActiveCollection] = useState(false)
  const [activeSubscribe, setActiveSubscribe] = useState(false)
  const [discountCode, setDiscountCode] = useState("")
  // Used to filter out RS results
  const [outOfStockVariants, setOutOfStockVariants] = useState([]);

  const values = {
    config,
    activeProduct,
    setActiveProduct,
    activeCollection,
    setActiveCollection,
    activeSubscribe,
    setActiveSubscribe,
    discountCode,
    setDiscountCode,
    outOfStockVariants,
    setOutOfStockVariants
  }

  const initialState = {
    activeCart: false,
    activeSearch: false,
    activeMenu: false,
    activeAnnouncement: true,
    activeCookies: true,
    searchTerms: "",
    sortBy: null,
    resultsStats: {
      currentPage: null,
      pageSize: null,
      numberOfResults: null,
    },
    searchResults: null,
    addressFormActive: false,
    newAddressId: "",
    orderDetailActive: false,
    activeOrderName: "",
  }

  const reducer = (state, action) => {
    switch (action.type) {
      case "TOGGLE_CART":
        return { ...state, activeCart: action.payload, activeSearch: false }
      case "SHOW_CART":
        return { ...state, activeCart: true }
      case "HIDE_CART":
        return { ...state, activeCart: false }
      case "SHOW_SEARCH":
        return {
          ...state,
          activeSearch: true,
          activeCart: false,
          activeMenu: false,
        }
      case "HIDE_SEARCH":
        return { ...state, activeSearch: false }
      case "TOGGLE_MENU":
        return { ...state, activeMenu: action.payload }
      case "CLOSE_MENU":
        return { ...state, activeMenu: false }
      case "HIDE_ANNOUNCEMENT":
        return { ...state, activeAnnouncement: false }
      case "HIDE_COOKIES":
        return { ...state, activeCookies: false }
      case "SET_SEARCH_TERMS":
        return { ...state, searchTerms: action.payload }
      case "SET_SORT_BY":
        return { ...state, sortBy: action.payload }
      case "SET_RESULTS_STATS":
        return { ...state, resultsStats: { ...action.payload } }
      case "SET_SEARCH_RESULTS":
        return { ...state, searchResults: action.payload }
      case "ROUTE_CHANGE":
        return {
          ...state,
          activeCart: false,
          activeSearch: false,
          activeMenu: false,
        }
      case "SHOW_ADDRESS_FORM":
        return { ...state, addressFormActive: true }
      case "HIDE_ADDRESS_FORM":
        return { ...state, addressFormActive: false }
      case "SET_NEW_ADDRESS_ID":
        return { ...state, newAddressId: action.payload }
      case "SHOW_ORDER_DETAIL":
        return { ...state, orderDetailActive: true }
      case "HIDE_ORDER_DETAIL":
        return { ...state, orderDetailActive: false }
      case "SET_ACTIVE_ORDER_NAME": {
        return { ...state, activeOrderName: action.payload }
      }
    }
  }

  return (
    <AppContext.Provider
      value={{ ...values, globalState: useReducer(reducer, initialState) }}
    >
      {children}
    </AppContext.Provider>
  )
}

export const withApp = Component => (props): JSX.Element => (
  <AppContext.Consumer>
    {values => <Component {...props} layout={values} />}
  </AppContext.Consumer>
)
