import React from "react"

import { withHeader } from "./withHeader"
import {
  StyledHeader,
  Container,
  Inner,
  HeaderMasthead,
  HeaderOffset,
  HeaderPanel,
  PanelLeft,
  PanelRight,
  HeaderAnnouncement,
  DesktopBrandWrapper,
  MobileBrandWrapper,
  MobileSearchWrapper,
  DesktopSearchWrapper,
} from "./HeaderStyles"
import { Announcement } from "../Announcement/Announcement"
import { NavigationHeaderDesktop } from "../Navigation/Header/Desktop/NavigationHeaderDesktop"
import { NavigationHeaderMobile } from "../Navigation/Header/Mobile/NavigationHeaderMobile"
import { CartWidget } from "../Cart/Widget/CartWidget"
import { AccountWidget } from "../Account/Widget/AccountWidget"
import { WishlistWidget } from "../Wishlist/Widget/WishlistWidget"
import { SearchWidget } from "../Search/Widget/SearchWidget"
import { Menu } from "./Menu/Menu"
import { Brand } from "./Brand/Brand"
import { Instant } from "../Search/Instant/Instant"
import { CartDrawer } from "../Cart/Drawer/CartDrawer"
import { FindAStore } from "./FindAStore/FindAStore"

export const Header = withHeader(
  ({
    reference,
    handleToggleMenu,
    isMenuOpen,
    isScrolled,
    isSearchOpen,
    isDesktop,
    isHeaderAnnouncementActive,
    isHeaderAnnouncementRegionalActive,
    displayCountdown,
  }) => (
    <>
      <StyledHeader active={isMenuOpen}>
        <HeaderPanel>
          <Announcement show={!isScrolled} />
          <Announcement global show={!isScrolled} />
          <Container active={!isScrolled}>
            <HeaderMasthead ref={reference} isScrolled={isScrolled}>
              {isSearchOpen && !isDesktop ? (
                <MobileSearchWrapper>
                  <Instant />
                </MobileSearchWrapper>
              ) : (
                <Inner>
                  <PanelLeft>
                    <Menu
                      isMenuOpen={isMenuOpen}
                      handleClick={handleToggleMenu}
                    />
                    <MobileSearchWrapper>
                      <SearchWidget />
                    </MobileSearchWrapper>
                    <FindAStore />
                  </PanelLeft>
                  <DesktopBrandWrapper>
                    {!isScrolled && <Brand />}
                  </DesktopBrandWrapper>
                  <MobileBrandWrapper>
                    <Brand />
                  </MobileBrandWrapper>
                  <PanelRight>
                    <DesktopSearchWrapper>
                      <SearchWidget />
                    </DesktopSearchWrapper>
                    <WishlistWidget />
                    <AccountWidget />
                    <CartWidget />
                  </PanelRight>
                </Inner>
              )}
            </HeaderMasthead>
          </Container>
          {isSearchOpen && isDesktop ? (
            <DesktopSearchWrapper>
              <Instant />
            </DesktopSearchWrapper>
          ) : (
            <NavigationHeaderDesktop isScrolled={isScrolled} />
          )}
          {!isMenuOpen && <HeaderAnnouncement />}
        </HeaderPanel>
        <NavigationHeaderMobile active={isMenuOpen} />
      </StyledHeader>
      <HeaderOffset
        isHeaderAnnouncementActive={isHeaderAnnouncementActive}
        isHeaderAnnouncementRegionalActive={isHeaderAnnouncementRegionalActive}
        displayCountdown={displayCountdown}
      />
      <CartDrawer />
    </>
  ),
)
