import React, { useState } from "react"

export const withAccordion = Component => ({ name = "Accordion", active: externalActive = undefined, onClick = undefined, title, ...props }) => {
  const [active, setActive] = useState(false)

  const isActive = onClick ? externalActive : active

  const handleActive = () => !onClick && setActive(!active)

  Component.displayName = name

  return <Component {...props} active={isActive} handleActive={handleActive} title={title} onClick={onClick} />
}
