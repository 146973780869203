import React from "react"

import { withAccountWidget } from "./withAccountWidget"
import { StyledIcon, StyledLink } from "./AccountWidgetStyles"

export const AccountWidget = withAccountWidget(
  ({ customer, accountLink, loginLink }): JSX.Element => (
    <StyledLink to={customer ? accountLink : loginLink} title={"My account"}>
      <StyledIcon name={"account"} />
    </StyledLink>
  ),
)
