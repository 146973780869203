import React from "react"

import { withHeadOffice } from "./withHeadOffice"
import { Wrapper, Title, Address, Number, Text, Contact } from "./HeadOfficeStyles"
import { Link } from "gatsby"

export const HeadOffice = withHeadOffice(({ address, number, postcode, state, suburb, title, contactTitle, url }) => (
  <Wrapper>
    <Title>{title}</Title>
    <Address>
      <Text>{address}</Text>
      <Text>{suburb}</Text>
      <Text>{`${state}, ${postcode}`}</Text>
    </Address>
    <Number href={`tel:${number}`}>
      <Text>{number}</Text>
    </Number>
    <Contact as={url && Link} to={url}>
      {contactTitle}
    </Contact>
  </Wrapper>
))
