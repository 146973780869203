import tw, { styled } from "twin.macro"
import { Select } from "../../Select/Select"

export const Wrapper = styled.div``

export const InnerWrapper = tw.div`
  flex flex-row
`

export const Message = styled.p`
  ${tw`inline-block text-14 leading-1.42 tracking-5 py-0-4 px-0-8 mb-3-2 bg-navy bg-opacity-20 border-navy border-opacity-20 rounded`}
  ${({ highlight }) => (highlight ? tw`text-red` : tw`text-navy`)}
`

export const StyledSelect = styled(Select)`
  ${tw`mr-0-8`}
`
