import React from "react"
import { GatsbyImage, StaticImage } from "gatsby-plugin-image"
import Img from "gatsby-image"
import { Link } from "gatsby"

import { Clickable } from "./ImageStyles"
import { withImage } from "./withImage"

export const Image = withImage(
  ({ alt, handleClick, image, link, fullHeight, aspectRatio, ratio, ...props }) => (
    <Clickable
      as={link?.url && (link?.isExternal ? `a` : Link)}
      href={link?.isExternal && link?.url}
      onClick={handleClick}
      target={link?.external && `_blank`}
      title={link?.title}
      to={!link?.isExternal && link?.url}
      stretch={fullHeight ? "true" : "false"}
    >
      {image?.src && (
        <Img
          fluid={{ 
            ...image, 
            srcSet: image?.srcSet || image?.srcset || "",
            sizes: image.sizes || "",
            aspectRatio: aspectRatio ? aspectRatio : image?.dimensions?.aspectRatio 
          }}
          alt={alt}
          style={fullHeight ? { height: "100%" } : {}}
          loading={`lazy`}
          {...props}
        />
      )}
      {image?.images && (
        <GatsbyImage
          image={{ ...image, aspectRatio }}
          alt={alt}
          style={fullHeight ? { height: "100%" } : {}}
          loading={`lazy`}
          {...props}
        />
      )}
    </Clickable>
  ),
)
