import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCheckoutContext } from "../../../hooks/useCheckout"
import { useCart } from "../../../hooks/useCart"

export const withCartDrawer =
  Component =>
  ({ name = "CartDrawer" }) => {
    const { globalState } = useApp()

    const { checkout, count } = useCheckoutContext()
    const { availableFreeGiftProducts } = useCart()

    const { cart } = useStaticQuery(graphql`
      query SANITY_PAGE_CART_DRAWER {
        cart: sanityPageCart {
          drawerTitle
          additionalCartDrawerClose
        }
      }
    `)

    const { drawerTitle, additionalCartDrawerClose } = cart || {}

    const [{ activeCart }, dispatch] = globalState

    const handleClose = () => dispatch({ type: "HIDE_CART" })

    const lineItems = useMemo(() => {
      const ret = checkout?.lineItems || []
      return ret
    }, [checkout])

    Component.displayName = name
    return (
      <Component
        lineItems={lineItems}
        count={count}
        active={activeCart}
        handleClose={handleClose}
        drawerTitle={drawerTitle}
        additionalCartDrawerClose={additionalCartDrawerClose}
        freeGiftProducts={availableFreeGiftProducts}
      />
    )
  }
