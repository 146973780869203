import { useRef, useLayoutEffect } from "react"

import { useCore } from "./useCore"

const getScrollPosition = ({ isBrowser }) => {
  if (!isBrowser) return { x: 0, y: 0 }

  const target = document.body
  const position = target.getBoundingClientRect()

  return { x: position.left, y: position.top }
}

export const useScrollPosition = (effect, deps, wait) => {
  const {
    helpers: { isBrowser },
  } = useCore()
  const position = useRef(getScrollPosition({ isBrowser }))

  let throttleTimeout = null

  const callBack = () => {
    const currPos = getScrollPosition({ isBrowser })
    effect({ prevPos: position.current, currPos })
    position.current = currPos
    throttleTimeout = null
  }

  useLayoutEffect(() => {
    const handleScroll = () => {
      if (wait) {
        if (throttleTimeout === null) {
          // intentionally ignore, do not store throttleTimeout between renders
          // eslint-disable-next-line react-hooks/exhaustive-deps
          throttleTimeout = setTimeout(callBack, wait)
        }
      } else {
        callBack()
      }
    }

    window.addEventListener(`scroll`, handleScroll)

    return () => window.removeEventListener(`scroll`, handleScroll)
  }, deps)
}
