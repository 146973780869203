import tw, { styled } from "twin.macro"
import { StyledContainer } from "../Styled/Container"

import { Announcement } from "./Announcement/Announcement"

export const StyledHeader = styled.header`
  ${tw`fixed flex flex-col inset-x-0 top-0 z-50 transition-all duration-300 bg-white`}
  ${({ active }) => (active ? tw`h-full-vh mlg:h-auto` : null)}
`

export const Container = styled.div`
  ${tw`border-b border-grey-lightest`}
  ${({ active }) =>
    active ? tw`mlg:translate-y-0 mlg:h-auto` : tw`mlg:-translate-y-20 mlg:h-0`}
`

export const Inner = styled(StyledContainer)`
  ${tw`flex flex-row max-w-full`}
`

export const HeaderOffset = styled.div`
  ${tw`transition-all duration-300 `}
  ${({ isHeaderAnnouncementActive, displayCountdown }) =>
    displayCountdown
      ? tw`h-16-6 mlg:h-24`
      : isHeaderAnnouncementActive
      ? tw`h-10-6 mlg:h-18`
      : tw`h-9-5 mlg:h-15-3`}
`

export const HeaderPanel = styled.div`
  ${tw`relative top-0 transform transition-all duration-300`}
`

export const HeaderMasthead = styled.div`
  ${tw`flex flex-row items-center h-6-4 w-full bg-white`}
  ${({ isScrolled }) => (isScrolled ? tw`mlg:h-5-6` : tw`mlg:h-6-4`)}
`

export const PanelLeft = tw.div`
  flex-1 mlg:flex-none flex flex-row justify-start items-center mlg:w-20
`

export const PanelRight = tw.div`
  flex-1 mlg:flex-none flex flex-row justify-end items-center mlg:w-20 z-20
`

export const HeaderAnnouncement = styled(Announcement)`
  ${tw`flex-1 flex`}
`

export const DesktopBrandWrapper = tw.div`
  hidden mlg:flex flex-1 justify-center
`

export const MobileBrandWrapper = tw.div`
  flex flex-1 justify-center mlg:hidden
`

export const MobileSearchWrapper = tw.div`
  block mlg:hidden w-full
`

export const DesktopSearchWrapper = tw.div`
  hidden mlg:block
`
