import { useCallback, useContext, useEffect, useRef, useState } from "react"

import { useApp } from "./useApp"
import { useCore } from "./useCore"
import { useQueries } from "./useQueries"
import { useShopify } from "./useShopify"
import { useCustomerLogout } from "./useCustomer"
import { useShop, useShopContext } from "./useShop"
import { useLocation } from "./useLocation"
import { CheckoutContext } from "../providers/checkout"
import { CheckoutProcessor } from "../utils/checkoutProcessor"
import { countryCurrencyMap } from "./useCountryCurrency"

export const useCheckoutContext = () => {
  const checkoutData: any = useContext(CheckoutContext)
  return { ...checkoutData }
}

export const useCheckout = () => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    queries: { GET_PRODUCTS_BY_HANDLE },
  } = useQueries()

  const {
    mutations: {
      CHECKOUT_CREATE,
      CHECKOUT_ATTRIBUTES_UPDATE,
      CHECKOUT_SHIPPING_ADDRESS_UPDATE,
      CHECKOUT_EMAIL_UPDATE,
      CHECKOUT_DISCOUNT_APPLY,
      CHECKOUT_GIFTCARDS_APPEND,
      CHECKOUT_CUSTOMER_ASSOCIATE,
      CHECKOUT_DISCOUNT_CODE_REMOVE,
    },
    queries: { GET_CHECKOUT, GET_SHIPPING_RATES },
  } = useQueries()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const { shop } = useShopContext()

  const {
    checkout,
    setCheckout: saveCheckout,
    setInitializedLoading,
    livePromotionRules,
    setFreeGiftSets,
  } = useCheckoutContext()

  const { checkoutNormaliser, useMutation, useQuery, client } = useShopify()
  const { logoutCustomer } = useCustomerLogout()
  const { getShop } = useShop()
  const { shopifyStore, shopifyStoreDomain } = useLocation()
  const [errors, setErrors] = useState(null)
  const [loading, setLoading] = useState(false)
  const checkoutId = storage.get(keys?.checkout)

  const [checkoutCreate] = useMutation(CHECKOUT_CREATE)
  const [checkoutAttributeUpdate] = useMutation(CHECKOUT_ATTRIBUTES_UPDATE)
  const [checkoutCustomerAssociate] = useMutation(CHECKOUT_CUSTOMER_ASSOCIATE)
  const [checkoutShippingAddressUpdate] = useMutation(
    CHECKOUT_SHIPPING_ADDRESS_UPDATE,
  )
  const [checkoutEmailUpdate] = useMutation(CHECKOUT_EMAIL_UPDATE)
  const [checkoutDiscountApply] = useMutation(CHECKOUT_DISCOUNT_APPLY)
  const [checkoutDiscountRemove] = useMutation(CHECKOUT_DISCOUNT_CODE_REMOVE)
  const [checkoutGiftcardAppend] = useMutation(CHECKOUT_GIFTCARDS_APPEND)

  const { refetch: getCheckoutQuery } = useQuery(GET_CHECKOUT, {
    fetchPolicy: "no-cache",
    skip: true,
  })
  const { refetch: getShippingRatesQuery } = useQuery(GET_SHIPPING_RATES, {
    fetchPolicy: "no-cache",
    skip: true,
  })

  const { location } = useLocation()

  const getCheckout = useCallback(() => {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      try {
        if (checkoutId) {
          const {
            data: { node: checkout },
          } = await getCheckoutQuery({ checkoutId, countryCode: location })
  
          console.log("getCheckout: ", checkout);
  
          if (checkout && checkout.id) {
            resolve(checkout);
          } else {
            reject(new Error('Checkout or checkout.id not found'));
          }
        } else {
          resolve(false);
        }
      } catch (error) {
        console.error(error);
        reject(error);
      }
    });
  }, [checkoutId, getCheckoutQuery, location])

  const setCheckout = useCallback(
    checkout => {
      try {
        saveCheckout(checkoutNormaliser(checkout))
        storage.set(keys?.checkout, checkout?.id)
      } catch (error) {
        console.error(error)
      }
    },
    [saveCheckout, checkoutNormaliser, keys?.checkout, storage],
  )

  // const raceConditions = useRef({})

  const createCheckout = useCallback(
    async (presentmentCurrencyCode = "AUD", forceNew = false) => {
      try {
        const existingCheckout = !forceNew ? await getCheckout() : null;
        const isCorrectMarket = existingCheckout?.buyerIdentity?.countryCode === storage.get(keys?.location)
        console.log("existingCheckout: ", existingCheckout);
        console.log("isCorrectMarket: ", isCorrectMarket);
        if (
          (existingCheckout && (!existingCheckout?.id || Object.keys(existingCheckout).length < 1)) || 
          !isCorrectMarket ||
          forceNew ||
          existingCheckout?.completedAt !== null
        ) {
          
          const {
            data: {
              checkoutCreate: { checkout },
            },
          } = await checkoutCreate({
            variables: {
              countryCode: location || "AU",
              buyerIdentity: {
                countryCode: location || "AU",
              },
              input: { presentmentCurrencyCode: location === "NZ" ? "NZD" : "AUD"},
            },
          })
          if (checkout) {
            setCheckout(checkout)
          }
        } else {
          setCheckout(existingCheckout)

          // Populate eligible free gifts based on current checkout and promotion rules
          const processor = new CheckoutProcessor(
            checkoutNormaliser(existingCheckout),
            livePromotionRules,
          )
          const { freeGiftSets } = processor.finaliseLineItemInput()
          setFreeGiftSets(freeGiftSets)

          setInitializedLoading(false)
        }
        getShop()
      } catch (error) {
        storage.remove(keys?.checkout)
        console.error(error)
      }
    },
    [getCheckout, storage, keys?.location, keys?.checkout, getShop, location, shop?.currencyCode, checkoutCreate, setCheckout, checkoutNormaliser, livePromotionRules, setFreeGiftSets, setInitializedLoading],
  )

  // const prevLivePromotionRules = useRef(livePromotionRules)

  // useEffect(() => {
  //   if (prevLivePromotionRules?.current !== livePromotionRules) {
  //     prevLivePromotionRules.current = livePromotionRules
  //     try {
  //       window.createCheckoutLimiter = (window.createCheckoutLimiter || 1) + 1
  //       if (window.createCheckoutLimiter < 100) {
  //         createCheckout(false, false)
  //       }
  //     } catch {
  //       // nothing
  //     }
  //   }
  // }, [createCheckout, livePromotionRules, prevLivePromotionRules])

  const updateAttributes = useCallback(
    async input => {
      const {
        data: { checkoutAttributesUpdateV2: data },
      } = await checkoutAttributeUpdate({
        variables: { checkoutId, input, countryCode: location },
      })
      setCheckout(data?.checkout)
    },
    [checkoutAttributeUpdate, setCheckout, checkoutId, location],
  )

  const updateCustomer = useCallback(
    async customerAccessToken => {
      const {
        data: { checkoutCustomerAssociateV2: data },
      } = await checkoutCustomerAssociate({
        variables: { checkoutId, customerAccessToken, countryCode: location },
      })
      setCheckout(data?.checkout)
    },
    [checkoutCustomerAssociate, setCheckout, checkoutId, location],
  )

  const updateShippingAddress = useCallback(
    async input => {
      const {
        data: { checkoutShippingAddressUpdateV2: data },
      } = await checkoutShippingAddressUpdate({
        variables: {
          checkoutId,
          countryCode: location,
          shippingAddress: {
            firstName: input?.firstName,
            lastName: input?.lastName,
            address1: input?.address1,
            address2: input?.address2,
            city: input?.city,
            country: input?.country,
            phone: input?.phone,
            province: input?.province,
            zip: input?.zip,
          },
        },
      })
      setCheckout(data?.checkout)
    },
    [checkoutShippingAddressUpdate, setCheckout, checkoutId, location],
  )

  const updateEmail = useCallback(
    async email => {
      const {
        data: { checkoutEmailUpdateV2: data },
      } = await checkoutEmailUpdate({
        variables: { checkoutId, email, countryCode: location },
      })
      setCheckout(data?.checkout)
    },
    [checkoutEmailUpdate, setCheckout, checkoutId, location],
  )

  const applyDiscountCode = useCallback(
    async discountCode => {
      console.log(
        '%cuseCheckout.ts line:239 "applyDiscount"',
        "color: #007acc;",
        "applyDiscount",
      )
      setLoading(true)
      const {
        data: { checkoutDiscountCodeApplyV2: data },
      } = await checkoutDiscountApply({
        variables: { checkoutId, discountCode, countryCode: location },
      })
      setLoading(false)
      if (!data.checkoutUserErrors.length) {
        storage.set(
          keys?.discounts,
          [...(storage.get(keys?.discounts) || []), discountCode].filter(
            (value, index, self) => self.indexOf(value) === index,
          ),
        )
        setCheckout(data?.checkout)
        setErrors(null)
      } else {
        setErrors(data.checkoutUserErrors)
      }
    },
    [
      checkoutDiscountApply,
      setCheckout,
      checkoutId,
      keys?.discounts,
      storage,
      location,
    ],
  )

  const removeDiscountCode = useCallback(async () => {
    console.log(
      '%cuseCheckout.ts line:286 "removeDiscount"',
      "color: #007acc;",
      "removeDiscount",
    )
    setLoading(true)
    const {
      data: { checkoutDiscountCodeRemove: data },
    } = await checkoutDiscountRemove({
      variables: { checkoutId, countryCode: location },
    })
    setLoading(false)
    if (!data.checkoutUserErrors.length) {
      storage.set(keys?.discounts, [])
      setCheckout(data?.checkout)
      setErrors(null)
    } else {
      setErrors(data.checkoutUserErrors)
    }
  }, [
    checkoutDiscountRemove,
    setCheckout,
    checkoutId,
    keys?.discounts,
    storage,
    location,
  ])

  const applyGiftCardCode = useCallback(
    async giftCardCode => {
      const {
        data: { checkoutGiftCardsAppend: data },
      } = await checkoutGiftcardAppend({
        variables: {
          checkoutId,
          giftCardCodes: [giftCardCode],
          countryCode: location,
        },
      })

      if (!data.checkoutUserErrors.length) {
        setCheckout(data.checkout)
        return this
      } else {
        return data
      }
    },
    [checkoutGiftcardAppend, setCheckout, checkoutId, location],
  )

  const getShippingRates = useCallback(async () => {
    const {
      data: { node: checkout },
    } = await getShippingRatesQuery({ id: checkoutId, countryCode: location })

    setCheckout(checkout)
    return checkout
  }, [getShippingRatesQuery, setCheckout, checkoutId, location])

  const applyDiscounts = useCallback(async () => {
    const discountCodes = storage.get(keys?.discounts) || []
    for (const discountCode of discountCodes) {
      await applyDiscountCode(discountCode)
    }
  }, [applyDiscountCode, keys?.discounts, storage])

  // const raceRefs = useRef({})

  const duplicateCheckout = useCallback(
    async (changes = {}) => {
      const {
        currencyCode,
        customAttributes,
        lineItems,
        note,
        email,
        shippingAddress,
      } = checkout
      const discounts = storage.get(keys?.discounts) || []

      const {
        data: { checkoutCreate: data },
      } = await checkoutCreate({
        variables: {
          countryCode: location,
          input: {
            ...(email && { email }),
            ...(note && { note }),
            ...(customAttributes && {
              customAttributes:
                customAttributes?.map(({ key, value }) => ({
                  key,
                  value,
                })) || [],
            }),
            presentmentCurrencyCode: location === "NZ" ? "NZD" : "AUD",
            lineItems:
              lineItems?.map(item => ({
                variantId: item?.variant.id,
                quantity: item?.quantity || 1,
                customAttributes:
                  item?.customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              })) || [],
            shippingAddress: shippingAddress
              ? {
                  address1: shippingAddress.address1,
                  address2: shippingAddress.address2,
                  city: shippingAddress.city,
                  company: shippingAddress.company,
                  country: shippingAddress.country,
                  firstName: shippingAddress.firstName,
                  lastName: shippingAddress.lastName,
                  phone: shippingAddress.phone,
                  province: shippingAddress.province,
                  zip: shippingAddress.zip,
                }
              : undefined,
            ...changes,
          },
        },
      })
      if (!discounts.length) setCheckout(data?.checkout)
      if (discounts.length) applyDiscounts()
    },
    [
      checkout,
      storage,
      keys?.discounts,
      checkoutCreate,
      location,
      setCheckout,
      applyDiscounts,
    ],
  )

  const migrateCheckout = useCallback(
    async (changes = {}) => {
      const { lineItems, note, email, customAttributes, shippingAddress } =
        checkout
      const discounts = storage.get(keys?.discounts) || []

      if (lineItems?.length) {
        const mappedLineItems =
          lineItems?.map(item => ({
            handle: item?.variant?.product?.handle,
            sku: item?.variant?.sku,
            quantity: item?.quantity,
            customAttributes: item?.customAttributes,
          })) || []

        const { data: matchedProducts } = await client.query({
          query: GET_PRODUCTS_BY_HANDLE(
            mappedLineItems.map(product => product?.handle),
          ),
          variables: {
            countryCode: location || "AU",
            firstImages: 0,
            firstMetafields: 0,
            firstVariants: 100,
          },
        })

        const migratedLineItems =
          mappedLineItems.map(lineItem => ({
            variantId: matchedProducts[
              `product${lineItem?.handle?.replace(/-/g, "")}`
            ]?.variants?.edges
              ?.filter(({ node }) => node?.sku === lineItem?.sku)
              .map(({ node }) => node?.id)[0],
            quantity: lineItem?.quantity,
            customAttributes: lineItem?.customAttributes?.map(
              ({ key, value }) => ({
                key,
                value,
              }),
            ),
          })) || []

        const {
          data: { checkoutCreate: data },
        } = await checkoutCreate({
          variables: {
            countryCode: location,
            input: {
              ...(email && { email }),
              ...(note && { note }),
              ...(customAttributes && {
                customAttributes:
                  customAttributes?.map(({ key, value }) => ({
                    key,
                    value,
                  })) || [],
              }),
              presentmentCurrencyCode: location === "NZ" ? "NZD" : "AUD",
              lineItems: migratedLineItems,
              shippingAddress: shippingAddress || undefined,
              ...changes,
            },
          },
        })
        if (!discounts.length) setCheckout(data?.checkout)
        if (discounts.length) applyDiscounts()
        getShop()
        logoutCustomer()
      } else {
        createCheckout(false, true)
      }
    },
    [checkout, location, createCheckout, checkoutCreate, setCheckout, applyDiscounts, logoutCustomer, getShop, client, GET_PRODUCTS_BY_HANDLE, keys?.discounts, storage],
  )

  const updateCurrency = useCallback(
    async presentmentCurrencyCode => {
      await duplicateCheckout({
        countryCode: location,
        presentmentCurrencyCode: presentmentCurrencyCode,
      })
      getShop()
    },
    [duplicateCheckout, getShop, location],
  )

  // const dumbState = useRef({})

  // useEffect(() => {
  //   dumbState.current.dupe = duplicateCheckout
  //   dumbState.current.getShop = getShop
  //   dumbState.current.checkout = checkout
  // }, [duplicateCheckout, getShop, dumbState, checkout])

  // const updateRegion = useCallback(async () => {
  //   await dumbState?.current?.dupe()
  //   dumbState?.current?.getShop()
  // }, [dumbState])

  // const prevLocationRef = useRef(location)

  // useEffect(() => {
  //   // update checkout context countryCode location on change

  //   if (
  //     location !== prevLocationRef?.current ||
  //     !checkoutLocationMatches(checkout, location)
  //   ) {
  //     prevLocationRef.current = location
  //     updateRegion()
  //   }
  // }, [location, checkout, updateRegion, prevLocationRef])

  // function checkoutLocationMatches(checkout, location) {
  //   if (checkout && !dumbState.current.checkoutChecked) {
  //     dumbState.current.checkoutChecked = true
  //     const checkoutCurrencyCode = checkout?.currencyCode
  //     const checkoutCountryCode = countryCurrencyMap.find(
  //       country => country.Code === checkoutCurrencyCode,
  //     )?.CountryCode
  //     return checkoutCountryCode === location
  //   } else {
  //     return true
  //   }
  // }

  const checkoutUrl = checkout.webUrl
    ? checkout.webUrl.replace(
        `${shopifyStore}.myshopify.com`,
        shopifyStoreDomain,
      )
    : ""

  return {
    checkoutUrl,
    loading,
    errors,
    createCheckout,
    getCheckout,
    setCheckout,
    migrateCheckout,
    updateCurrency,
    updateAttributes,
    updateShippingAddress,
    updateEmail,
    updateCustomer,
    applyDiscountCode,
    removeDiscountCode,
    applyGiftCardCode,
    getShippingRates,
  }
}
