import tw, { styled } from "twin.macro"

const styles = {
  default: tw`w-full max-w-full mx-auto px-2 md:px-3`,
  collapse: {
    "<md": tw`px-0 md:px-3`,
    "md>": tw`md:px-0`,
    "<lg": tw`px-0 lg:px-3`,
    "lg>": tw`lg:px-0`,
    default: tw`px-0 md:px-0`,
  },
  spacing: {
    default: tw`pb-4 md:pb-8`,
    sm: tw`pb-4 md:pb-4-8`,
  },
  width: {
    xs: tw`max-w-xs`,
    sm: tw`max-w-sm`,
    md: tw`max-w-md`,
    mlg: tw`max-w-mlg`,
    lg: tw`max-w-lg`,
    xl: tw`max-w-xl`,
    default: tw`max-w-xl`,
  },
}

export const StyledContainer = styled.div<ContainerProps>`
  ${styles.default}
  ${({ collapse }) =>
    (collapse && styles.collapse[collapse]) ||
    (collapse && styles.collapse[`default`])}
  ${({ spacing }) =>
    (spacing && styles.spacing[spacing]) ||
    (spacing && styles.spacing[`default`])}
  ${({ width }) => styles.width[width] || styles.width[`default`]}
`

type ContainerProps = {
  collapse?: "<md" | "md>" | "<lg" | "lg>" | "default"
  spacing?: "default" | "sm"
  width?: "xs" | "sm" | "md" | "lg" | "xl" | "default"
}
