import { graphql, useStaticQuery } from "gatsby"
import React, { useEffect, useState } from "react"
import { useCart } from "../../hooks/useCart"
import { useShopify } from "../../hooks/useShopify"

export const withFreeGiftPicker = Component => ({ name = "SizeGuide" }) => {
  const { availableFreeGiftProducts } = useCart()
  
  const { getHandle, getProducts } = useShopify()

  const [items, setItems] = useState([])

  const { productTemplateData } = useStaticQuery(graphql`
    query PRODUCT_TEMPLATE_FREE_GIFT_PICKER {
      productTemplateData: sanityTemplateProduct {
        description: _rawAdditionalFreeGiftDescription(
          resolveReferences: { maxDepth: 2 }
        )
      }
    }
  `)

  const { description } = productTemplateData || {}

  useEffect(() => {
    const fetchItems = async () => {

      const items =
        availableFreeGiftProducts?.length > 0
          ? await getProducts({
              handles: availableFreeGiftProducts?.map(product =>
                getHandle(product),
              ),
              firstImages: 2,
              firstVariants: 50,
            })
          : []


      const filteredItems = items?.filter(item => item?.variants?.length)

      const dict = filteredItems?.reduce((dict, item) => {
        dict[item.id] = item
        return dict
      }, {})

      
      setItems(Object.values(dict))
    }
    fetchItems()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [availableFreeGiftProducts])

  Component.displayName = name
  return <Component items={items} description={description} />
}
