import { graphql, useStaticQuery } from "gatsby"
import React, { useState, useLayoutEffect, useRef } from "react"

import { useApp } from "../../hooks/useApp"
import { useScrollPosition } from "../../hooks/useScroll"
import { useWindowSize } from "../../hooks/useWindowSize"
import { useAnnouncement } from "../../hooks/useAnnouncement"

const OFFSET_THRESHOLD = 20

export const withHeader = Component => ({ name = "Header" }: any) => {
  const { globalState } = useApp()
  const { windowSize } = useWindowSize()
  const { displayCountdown } = useAnnouncement()

  const [offsetHeight, setOffsetHeight] = useState(0)
  const [isScrolled, setIsScrolled] = useState(false)
  const [isShowTop, setIsShowTop] = useState(true)
  const ref = useRef(null)

  const { announcementsData, announcementsDataRegional } = useStaticQuery(graphql`
    query SANITY_SETTINGS_HEADER_ANNOUNCEMENT_ACTIVE {
      announcementsData: sanitySettingAnnouncement {
        items: headerAnnouncements {
          title
        }
      }
      announcementsDataRegional: sanitySettingAnnouncementRegional {
        items: headerAnnouncements {
          title
        }
      }
    }
  `)
  const { items } = announcementsData || {}
  const isHeaderAnnouncementActive = items?.length > 0

  const { items: itemsRegional } = announcementsDataRegional || {}
  const isHeaderAnnouncementRegionalActive = itemsRegional?.length > 0

  const [
    { activeMenu: isMenuOpen, activeSearch: isSearchOpen },
    dispatch,
  ] = globalState

  useLayoutEffect(() => {
    const handleRedraw = () => {
      const offset = ref.current.offsetHeight

      if (offset !== offsetHeight) {
        setOffsetHeight(offset)
      }
    }

    window.addEventListener(`resize`, handleRedraw)
    handleRedraw()
    return () => window.removeEventListener(`resize`, handleRedraw)
  })

  useScrollPosition(
    ({ currPos, prevPos }) => {
      const shown = currPos.y > -OFFSET_THRESHOLD ? true : currPos.y > prevPos.y
      if (shown !== isShowTop) {
        setIsShowTop(shown)
      }
    },
    [isShowTop, offsetHeight],
    500,
  )

  useScrollPosition(
    ({ currPos }) => {
      const scrolled = currPos.y !== 0 ? true : false
      if (scrolled !== isScrolled) {
        setIsScrolled(scrolled)
      }
    },
    [isScrolled],
    500,
  )

  const handleToggleMenu = () => {
    dispatch({
      type: "TOGGLE_MENU",
      payload: !isMenuOpen,
    })
  }

  const isDesktop = windowSize.width > 1025

  Component.displayName = name
  return (
    <Component
      reference={ref}
      handleToggleMenu={handleToggleMenu}
      isMenuOpen={isMenuOpen}
      isScrolled={isScrolled}
      isSearchOpen={isSearchOpen}
      isDesktop={isDesktop}
      isHeaderAnnouncementActive={isHeaderAnnouncementActive}
      isHeaderAnnouncementRegionalActive={isHeaderAnnouncementRegionalActive}
      displayCountdown={displayCountdown}
    />
  )
}
