import tw, { styled } from "twin.macro"

export const QuickViewButton = styled.div`
  ${({ layout }) => {
    switch (layout) {
      case "card":
        return tw`bg-grey-light border border-grey-light rounded-50 py-0-6 px-2 text-14 leading-1.42 tracking-5 flex flex-row items-center`
      case "catalogue":
        return tw`p-0-4 group-hover:p-0`
      default:
        break
    }
  }}
`
export const QuickViewButtonDot = tw.div`
  bg-white p-0-8 border-white rounded-full group-hover:p-1-2 duration-200
`
