import React from "react"
import { Global, css } from "@emotion/react"
import tw from "twin.macro"

import "swiper/swiper-bundle.min.css"
import "@reach/dialog/styles.css"

import { useCore } from "../../hooks/useCore"
import { useApp } from "../../hooks/useApp"

export const LayoutStyles = () => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const isCookiesHidden = storage.get(keys?.cookies) === "hidden"

  return (
    <Global
      styles={css`
        * {
          -ms-text-size-adjust: 100%;
          -webkit-text-size-adjust: 100%;
          -ms-overflow-style: none;
          -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
          text-rendering: optimizeLegibility;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          box-sizing: border-box;
          &:focus {
            outline: none !important;
          }
          input,
          textarea {
            border-radius: 0;
          }
          input,
          textarea,
          select {
            -webkit-appearance: none;
          }
          /* Chrome, Safari, Edge, Opera */
          input::-webkit-outer-spin-button,
          input::-webkit-inner-spin-button {
            -webkit-appearance: none;
            margin: 0;
          }

          /* Firefox */
          input[type="number"] {
            -moz-appearance: textfield;
          }
        }
        body {
          ${tw`text-14 font-body font-normal text-navy bg-white break-words leading-none overflow-y-scroll`}
        }

        .filter-range-radio + .filter-range-label {
          span {
            ${tw`leading-none`}
          }
          ${tw`mb-1-6 mt-0`}
        }

        .filter-range-list {
          ${tw`px-1-6 text-14 leading-1.42 tracking-5`}
          padding-bottom: 0 !important;
        }

        .filter-range-radio + .filter-range-label {
          ::before {
            ${tw`rounded-2 h-1-6 w-1-8 box-border border-navy border-opacity-20 border my-0 ml-0 mr-1-6`}
          }
          ::after {
            content: "";
            ${tw`absolute top-0-7 left-0-6 w-0-4 h-0-8 border border-white box-border transition-all duration-200 rounded-none`}
            border-width: 0 2px 2px 0;
            transform: rotate(45deg) scale(0);
          }
        }
        .filter-range-radio:checked + .filter-range-label {
          ::before {
            background-color: #0C2340;
          }
          ::after {
            transform: rotate(45deg) scale(1);
          }
        }

        .filter-range-label {
          ::before {
            border-color: #0C2340 !important;
          }
        }

        .swiper-slide {
          ${tw`h-auto`}
        }

        #gorgias-web-messenger-container {
          ${tw`z-30`}
        }
        @media (min-width:1024px){
          #chat-window {
            ${!isCookiesHidden ? "bottom: 160px !important;" : null}
          }
        }
        #chat-button {
          ${tw`z-20`}
          ${!isCookiesHidden ? "bottom: 80px !important;" : null}
        }

        #feedbackify #fbya #fbyb .fby-tab.fby-tab-l {
          z-index: 100 !important;
        }

        .catalogue [data-reach-tab][data-selected] {
          text-decoration: underline;
        }
      `}
    />
  )
}
