import tw, { styled, css } from "twin.macro"

export const StyledOl = styled.ol`
  ${tw`list-none list-inside`}
  ${css`
    counter-reset: section;
    li::before {
      counter-increment: section;
      content: counters(section, ".") " ";
    }
  `}
`
export const StyledUl = tw.ul`list-disc list-outside ml-2`
export const StyledLi = styled.li<LiProps>`
  ${tw`leading-1.5`}
  ${({ level }) => !level && tw`mb-3 last:mb-0`}
  ${({ level, listItem }) =>
    listItem === "bullet"
      ? tw`list-disc`
      : level === 1
      ? tw`list-decimal`
      : level === 3
      ? tw`list-alpha`
      : level === 4 && tw`list-roman`}
  ${({ level }) =>
    level !== 2 &&
    css`
      &::before {
        content: "" !important;
      }
    `}
    ${({ level }) =>
    level &&
    css`
      > ul,
      > ol {
        ${tw`ml-2`}
      }
    `}
`

type LiProps = {
  level: number
  listItem: string
}
