import React from "react"

import { withBackInStock } from "./withBackInStock"
import { EmailForm, Title, Description } from "./BackInStockStyles"
import { StyledInput } from "../../Styled/Form"
import { StyledButton } from "../../Styled/Button"
import { ModalExternal, ModalContentsWithLayout } from "../../Modal/Modal"

export const BackInStock = withBackInStock(
  ({
    restockFormActive,
    setRestockFormActive,
    sent,
    loading,
    handleSubmit,
    email,
    handleChange,
    restockTitle,
    restockDescription,
    restockSubmittingButtonText,
    restockSubmitButtonText,
    restockSuccessTitle,
    restockSuccessDescription,
  }) => (
    <ModalExternal isOpen={restockFormActive} setIsOpen={setRestockFormActive}>
      <ModalContentsWithLayout layout={"backInStock"}>
        <EmailForm onSubmit={handleSubmit}>
          {!sent ? (
            <>
              <Title>{restockTitle}</Title>
              <Description>{restockDescription}</Description>
              <StyledInput
                value={email}
                onChange={handleChange}
                spacing
                placeholder="email"
                required
              />
              <StyledButton
                type={"submit"}
                wide={"true"}
                colour={"black-reverse"}
              >
                {loading
                  ? restockSubmittingButtonText
                  : restockSubmitButtonText}
              </StyledButton>
            </>
          ) : (
            <>
              <Title>{restockSuccessTitle}</Title>
              <Description>{restockSuccessDescription}</Description>
            </>
          )}
        </EmailForm>
      </ModalContentsWithLayout>
    </ModalExternal>
  ),
)
