import tw, { styled, css } from "twin.macro"

export const Wrapper = styled.div`
  ${tw`text-14 leading-1.42 tracking-5 text-red`}
  ${({ layout }) => {
    if (layout === "card") return tw`mt-0-4`
    if (layout === "product") return tw`flex-1 uppercase font-semibold`
  }}
  ${({ colour }) => {
    return css`
      color: ${colour}
    `
  }}  
`
