import React from "react"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withHeroCard = Component => ({
  name = "HeroCard",
  card,
  ratio = undefined,
  handlePromoClick = null,
  className = "",
}) => {
  const { linkResolver } = useRoutes()
  const { image: imageVideo, content, left, top, mobileLeft, mobileTop } =
    card || {}
  const link = linkResolver(imageVideo?.link)

  Component.displayName = name
  return (
    <Component
      imageVideo={imageVideo}
      content={content}
      left={left}
      top={top}
      mobileLeft={mobileLeft}
      mobileTop={mobileTop}
      ratio={ratio}
      className={className}
      link={link}
      handlePromoClick={handlePromoClick}
    />
  )
}
