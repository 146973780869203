import { useStaticQuery, graphql } from "gatsby"

export const useLanguages = () =>
  useStaticQuery(graphql`
    query LANGUAGES_SANITY_SETTINGS {
      languages: sanitySettingLanguages {
        hrefLangs: languages {
          _key
          language
          region
          url
        }
      }
    }
  `)
