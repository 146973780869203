import { useCallback } from "react"
import { useApp } from "./useApp"
import { useCore } from "./useCore"

export const useRoutes = () => {
  const {
    config: {
      settings: { routes },
    },
  } = useApp()
  const {
    helpers: { isBrowser },
  } = useCore()

  const findRoute = type => {
    const route = Object.entries(routes)?.find(([key]) =>
      type
        ?.toLowerCase()
        ?.replace(`pageaccount`, ``)
        ?.includes(key?.toLowerCase()),
    ) || [`page`, routes[`page`]]

    return {
      type: route[0]?.toLowerCase(),
      url: route[1],
    }
  }

  const linkResolver = item => {
    const externalLink = item?.link
    const document = item?.document || item
    const type = document?._type || document?.__typename || document?.type

    if (!document) return {}
    if (type === `navigationSub`) return { url: null, type: `navigationSub` }

    if (externalLink)
      return {
        title: item?.title || document?.title || null,
        url: externalLink,
        external: item?.external,
        isExternal: true,
      }

    const route = findRoute(type)
    return {
      title: item?.title || document?.title || null,
      type: route?.type || null,
      external: item?.external,
      url: routeResolver({
        item: document[route?.type] || document,
        type: route?.type,
      }),
    }
  }

  const routeResolver = ({ item, type }) => {
    const dynamicTypes = [
      `product`,
      `collection`,
      `flexible`,
      `generic`,
      `page`,
      `article`,
      `career`,
      `catalogue`,
    ]
    const itemType = type || item?.type || item?._type
    const handle =
      item?.meta?.metaCanonicalURL?.current ||
      item?.slug?.current ||
      item?.shopify?.shopifyHandle ||
      item?.handle ||
      item?._id?.replace(`drafts.`, ``).replace(`page`, ``).toLowerCase()
    const route = findRoute(itemType)

    return `${route?.url}${
      handle && dynamicTypes?.includes(route?.type?.toLowerCase())
        ? `/${handle}`
        : ``
    }`
  }

  const getUrlParameter = useCallback(
    (name, location = (isBrowser && window?.location) || null) =>
      new URLSearchParams(location?.search)?.get(name),
    [isBrowser],
  )

  const setUrlParameter = useCallback(
    (
      name = ``,
      value = ``,
      location = (isBrowser && window?.location) || null,
    ) => {
      const url = new URLSearchParams(location?.search)
      name && value ? url?.set(name, value) : name && url?.delete(name)

      return `${location?.pathname}${
        url?.toString() ? `?${url?.toString()}` : ``
      }`
    },
    [isBrowser],
  )

  return {
    getUrlParameter,
    linkResolver,
    routeResolver,
    setUrlParameter,
  }
}
