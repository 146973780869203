import React from "react"

import { withInstantSearchResultsBottom } from "./withInstantSearchResultsBottom"
import { ButtonWrapper } from "./InstantSearchResultsStyles"
import { UnderlinedButton } from "../../../Styled/Button"

export const InstantSearchResultsBottom = withInstantSearchResultsBottom(
  ({
    handleSubmit,
    additionalViewAllResultsText,
  }): JSX.Element => (
    <>
      <ButtonWrapper>
        <UnderlinedButton size="primary" onClick={handleSubmit}>
          <span>{additionalViewAllResultsText}</span>
        </UnderlinedButton>
      </ButtonWrapper> 
    </>
  ),
)
