import tw, { styled } from "twin.macro"

const styles = {
  layout: {
    faq: tw`mb-1-6`,
  },
  border: {
    faq: tw`border border-navy rounded`,
    info: tw`border-t border-navy-light`,
    bundleSize: tw`border-t border-navy border-opacity-30`,
    filter: tw`border-b border-navy border-opacity-20`,
  },
  borderBottom: {
    info: tw`border-b border-navy-light`,
    bundleSize: tw`border-b border-navy border-opacity-30`,
    filter: tw`border-b border-navy border-opacity-20`,
  },
  title: {
    faq: tw`justify-between p-1-6`,
    info: tw`justify-between py-1-6`,
    bundleSize: tw`justify-between py-1-6`,
    filter: tw`justify-between py-1-4 mlg:py-1-6`,
  },
  size: {
    faq: tw`text-14 leading-1.78`,
    info: tw`text-14 leading-1.71 font-semibold uppercase tracking-10`,
    bundleSize: tw`text-14 leading-1.71 font-semibold`,
    filter: tw`text-14 leading-1.71 font-semibold uppercase tracking-10`,
  },
  icon: {
    faq: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    info: tw`w-1-2 h-1-2 md:w-1-4 md:h-1-4`,
    bundleSize: tw`mr-1`,
    filter: tw`w-1-2 h-1-2`,
  },
  content: {
    info: tw`text-14 leading-1.78 pb-2-4`,
    bundleSize: tw`py-2`,
    faq: tw`px-1-6`,
    filter: tw``,
  },
}

export const Wrapper = styled.div<LayoutProps>`
  ${tw`w-full`}
  ${({ layout }) => (layout ? styles.border[layout] : null)}
  ${({ layout }) => (layout ? styles.layout[layout] : null)}
  :last-of-type {
    ${({ layout }) => (layout ? styles.borderBottom[layout] : null)}
  }
`
export const TitleWrapper = styled.button<LayoutProps>`
  ${tw`w-full flex items-center focus:outline-none`}
  ${({ layout }) => (layout ? styles.title[layout] : null)}
  span {
    svg {
      ${({ layout }) => (layout ? styles.icon[layout] : null)}
    }
  }
`

export const InnerTitleWrapper = styled.div`
  ${tw`w-4/5 text-left flex flex-col`}
`

export const Title = styled.h3<LayoutProps>`
  ${({ layout }) => (layout ? styles.size[layout] : null)}
`
export const Content = styled.div<LayoutProps>`
  ${({ layout }) => (layout ? styles.content[layout] : null)}
`

export const IconOuter = tw.div`
  relative
`

export const IconInner = styled.div<ActiveProps>`
  ${tw`absolute top-1/2 right-0 transform -translate-y-1/2`}
  ${({ active }) =>
    active === "true"
      ? tw`opacity-100 visible pointer-events-auto`
      : tw`opacity-0 invisible pointer-events-none`}
`

export const PrefixIcon = tw.div`
  absolute left-0 w-4 h-7-2 flex items-center
`

export const IconWrapper = tw.div`
  w-4 max-h-4
`

type ActiveProps = {
  active: "true" | "false"
}

type LayoutProps = {
  layout: "faq" | "features" | "info"
}
