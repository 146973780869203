import React from "react"

import { withCartItem } from "./withCartItem"
import {
  Wrapper,
  Thumbnail,
  Details,
  InnerTitleWrapper,
  InnerTitle,
  OuterTitle,
  QuantityWrapper,
  QuantityButton,
  QuantityNumber,
  Remove,
  SelectedOptions,
  StyledRow,
  Warning,
  DiscountMessage,
  FreeGiftMessage,
  DiscountMessageGst,
} from "./CartItemStyles"
import { Image } from "../../Image/Image"
import { Icon } from "../../Icon/Icon"
import { Link } from "gatsby"
import { PriceTag } from "../../Product/Price/PriceStyles"
import { CartItemPrice } from "./Price/CartItemPrice"
import configDefault from "../../../../config.default"
import { useLocation } from "../../../hooks/useLocation"

export const CartItem = withCartItem(
  ({
    layout,
    isGiftCard,
    item,
    url,
    loading,
    loadingRemove,
    handleQuantity,
    handleChange,
    handleRemove,
    inputQuantity,
    warning,
    isAddQuantityDisabled,
    isPreOrder,
    additionalRemoveLineItemButtonText,
    additionalRemovingLineItemButtonText,
    additionalPreOrderText,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardMessageLabel,
    additionalGiftCardReceiverEmailLabel,
    giftCardConstraints,
    discountMessage,
    discountAllocationsAmount,
    isFreeGift,
  }): JSX.Element => {
    const { location } = useLocation()

    return (
      <Wrapper
        layout={layout}
        loading={loading || loadingRemove ? "true" : "false"}
      >
        <Thumbnail
          as={Link}
          to={url}
          title={item.title}
          image={item.variant.image.src ? "true" : "false"}
          layout={layout}
        >
          <Image image={item.variant.image} ratio={"15/19"} />
        </Thumbnail>
        <Details>
          {isFreeGift && <FreeGiftMessage>Gift</FreeGiftMessage>}
          <OuterTitle as={Link} to={url} title={item.title} layout={layout}>
            {item.title}
          </OuterTitle>
          <StyledRow>
            <InnerTitleWrapper>
              <InnerTitle as={Link} to={url} title={item.title} layout={layout}>
                {item.title}
              </InnerTitle>
              {!isGiftCard ? (
                <SelectedOptions>
                  {item.variant.selectedOptions.map((option, idx) => (
                    <span
                      key={idx + "item-variant-selected-options"}
                    >{`${option.name}: ${option.value}`}</span>
                  ))}
                  {isPreOrder ? <b>{additionalPreOrderText}</b> : null}
                </SelectedOptions>
              ) : (
                <SelectedOptions>
                  {item?.customAttributes?.map((option, idx) => {
                    if (option.value !== "") {
                      if (option.key === giftCardConstraints.SENDER) {
                        return (
                          <span key={idx + "itemCustomAttributes1"}>
                            {additionalGiftCardSenderLabel}: {option.value}
                          </span>
                        )
                      } else if (option.key === giftCardConstraints.RECEIVER) {
                        return (
                          <span key={idx + "itemCustomAttributes1"}>
                            {additionalGiftCardReceiverLabel}: {option.value}
                          </span>
                        )
                      } else if (
                        option.key === giftCardConstraints.RECEIVER_EMAIL
                      ) {
                        return (
                          <span key={idx + "itemCustomAttributes1"}>
                            {additionalGiftCardReceiverEmailLabel}:{" "}
                            {option.value}
                          </span>
                        )
                      } else if (option.key === giftCardConstraints.MESSAGE) {
                        return (
                          <span key={idx + "itemCustomAttributes1"}>
                            {additionalGiftCardMessageLabel}: {option.value}
                          </span>
                        )
                      } else if (!option?.key?.startsWith?.("_")) {
                        return (
                          <span key={idx + "itemCustomAttributes1"}>
                            {option?.key}: {option.value}
                          </span>
                        )
                      }
                    }
                  })}
                </SelectedOptions>
              )}
            </InnerTitleWrapper>
            {!isGiftCard && !isFreeGift ? (
              <div>
                <QuantityWrapper>
                  <QuantityButton
                    title={"minus"}
                    disabled={loading || item?.quantity === 1}
                    onClick={() => handleQuantity(item?.quantity - 1)}
                  >
                    <Icon name={"minus"} width={10} height={10} />
                  </QuantityButton>
                  <QuantityNumber
                    disabled={loading}
                    type={"number"}
                    value={inputQuantity}
                    onChange={handleChange}
                  />
                  <QuantityButton
                    title={"add"}
                    disabled={isAddQuantityDisabled}
                    onClick={() => handleQuantity(item?.quantity + 1)}
                  >
                    <Icon name={"plus"} width={10} height={10} />
                  </QuantityButton>
                </QuantityWrapper>
              </div>
            ) : null}
          </StyledRow>
          {warning ? (
            <StyledRow>
              <Warning>{warning}</Warning>
            </StyledRow>
          ) : null}
          <StyledRow align="end">
            <Remove disabled={loadingRemove || loading} onClick={handleRemove}>
              <Icon name={"remove"} />
              <span>
                {loadingRemove
                  ? additionalRemovingLineItemButtonText
                  : additionalRemoveLineItemButtonText}
              </span>
            </Remove>
            {isGiftCard ? (
              <>
                <div>
                  <PriceTag layout={layout}>
                    $
                    {(item.variant?.priceV2?.amount * item.quantity).toFixed(2)}
                  </PriceTag>
                  <div style={{ fontSize: "10px" }}>
                    {location !== "AU" ? "GST Removed" : ""}
                  </div>
                </div>
              </>
            ) : (
              <CartItemPrice
                layout={layout}
                variant={item.variant}
                discountAllocationsAmount={discountAllocationsAmount}
              />
            )}
          </StyledRow>

          {discountMessage?.length > 0 &&
            discountMessage.map(message => {
              return message ===
                configDefault.settings.international.GST_REMOVED_MESSAGE ? (
                <DiscountMessageGst key={message}>{message}</DiscountMessageGst>
              ) : (
                <DiscountMessage key={message}>{message}</DiscountMessage>
              )
            })}
        </Details>
      </Wrapper>
    )
  },
)
