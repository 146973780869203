import tw, { styled, css } from "twin.macro"
import { ButtonLink } from "../ButtonLink/ButtonLink"
import { RichText } from "../RichText/RichText"

export const Wrapper = styled.div`
  ${({ maxWidth }) => {
    return maxWidth
      ? css`
          margin: 0 auto;
          @media (min-width: 768px) {
            max-width: ${maxWidth}px;
          }
        `
      : null
  }}

  ${({ horizontalPaddingAround }) => {
    return typeof horizontalPaddingAround === "number"
      ? css`
          padding-left: ${horizontalPaddingAround}px;
          padding-right: ${horizontalPaddingAround}px;
        `
      : tw`md:px-2`
  }}

  ${({ verticalPaddingAround }) => {
    return typeof verticalPaddingAround === "number"
      ? css`
          padding-top: ${verticalPaddingAround}px;
          padding-bottom: ${verticalPaddingAround}px;
        `
      : tw`py-2`
  }}
`

export const Desktop = tw.div`hidden md:flex flex-col gap-2`

export const Mobile = tw.div`md:hidden`

export const Title = styled(RichText)``

export const Description = styled(RichText)`
  ${({ hideDescriptionOnMobile }) => {
    return hideDescriptionOnMobile ? tw`hidden md:block` : null
  }}
  ${({ descriptionMarginTop }) => {
    return descriptionMarginTop
      ? css`
          margin-top: ${descriptionMarginTop}%;
        `
      : null
  }}
`

export const Buttons = styled.div`
  ${tw`flex flex-row -mx-0-8 items-center`}
  ${({ align }) => {
    if (align === "left") return tw`justify-start`
    if (align === "right") return tw`md:justify-end`
    return tw`justify-center`
  }}
  ${({ buttonMarginTop }) => {
    return buttonMarginTop
      ? css`
          margin-top: ${buttonMarginTop}%;
        `
      : null
  }}
`

export const Button = styled(ButtonLink)`
  ${tw`mx-0-8 whitespace-nowrap`}
  ${({ buttonsPadding }) => {
    return css`
      margin-left: ${buttonsPadding}px;
      margin-right: ${buttonsPadding}px;
    `
  }}
`
