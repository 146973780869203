import React from "react"

import { useHotjar } from '../../hooks/useHotjar'

export const withLink = Component => ({
  name = "Link",
  title,
  url,
  external,
  className,
  isExternal,
  trackingPrefix = "",
  color = {},
}: {
  name?: any;
  title: any;
  url: any;
  external: any;
  className?: any;
  isExternal: any;
  trackingPrefix: any;
  color: any;
}) => {
  Component.displayName = name
  const { trackHotjarEvent } = useHotjar()
  const hjTrackClick = (title) => {
    trackHotjarEvent(trackingPrefix + "link_click")
    trackHotjarEvent(trackingPrefix + "link_click-"+title)
  }
  return (
    <Component
      title={title}
      url={url}
      className={className}
      external={external}
      isExternal={isExternal}
      color={color}
      hjTrackClick={hjTrackClick}
    />
  )
}
