import React, { createContext, useState, useEffect, useCallback } from "react"

import { useCore } from "../hooks/useCore"

export const LocationContext = createContext<LocationContextInterface | null>(null)

export const LocationProvider = ({ children, config }): JSX.Element => {
  const {
    helpers: { storage },
  } = useCore()

  const { keys } = config?.settings
  const { location: locationService } = config?.services
  const defaultStore = config?.services?.shopify?.defaultShopName
  const defaultStoreDomain = config?.stores[defaultStore]?.shopDomain
  const storageStore = storage.get(keys?.shopify)
  const shopifyStore = config?.stores[storageStore] ? storageStore : defaultStore
  const shopifyStoreDomain = config?.stores[shopifyStore]?.shopDomain || defaultStoreDomain

  const [settings, setSettings] = useState({
    country: storage.get(keys?.country) || "",
    location: storage.get(keys?.location),
    locations: Object.values(config?.stores).map((store: any) => store?.siteLocation),
    locating: !storage.get(keys?.country)?.length,
    visitor: !config?.stores[shopifyStore]?.siteCountries?.includes(storage.get(keys?.country) || ""),
    shopifyStore,
    shopifyStoreDomain,
  })

  const updateLocation = useCallback(
    (countryCode, updateCountry = false) => {
      setSettings(prevState => ({
        ...prevState,
        country: updateCountry ? countryCode : settings?.country,
        location: countryCode,
        shopifyStore: shopifyStore ||
        Object.values(config?.stores).find((store: any) => store?.siteCountries.includes(countryCode))?.shopName || defaultStore,
        shopifyStoreDomain: shopifyStoreDomain ||
        Object.values(config?.stores).find((store: any) => store?.siteCountries.includes(countryCode))?.shopDomain || defaultStoreDomain,
        visitor: locationService?.forcelocation ? !config?.stores[shopifyStore]?.siteCountries?.includes(settings?.country) : false,
        locating: false,
      }))
    },
    [config?.stores, locationService?.forcelocation, defaultStore, settings?.country, defaultStoreDomain, shopifyStore, shopifyStoreDomain]
  )

  useEffect(() => {
    fetch(locationService?.serviceUrl)
      .then(res => res.json())
      .then(result => {
        updateLocation(result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation, true)
        storage.set(keys?.location, result[locationService?.countryFieldKey] || config?.stores[defaultStore]?.siteLocation)
      })
      .catch((err) => {
        console.error(err)
        updateLocation(config?.stores[defaultStore]?.siteLocation, true)
      })
  }, [
    config?.stores,
    defaultStore,
    keys?.country,
    keys?.location,
    keys?.shopify,
    locationService?.countryFieldKey,
    locationService?.serviceUrl,
    storage,
    updateLocation,
    settings?.country,
    settings?.location,
    settings?.shopifyStore,
  ])

  const providerValue = React.useMemo(() => {
    return { updateLocation, settings, ...settings }
  }, [settings, updateLocation])

  return <LocationContext.Provider value={providerValue}>{children}</LocationContext.Provider>
}

export const withLocation = Component => (props): JSX.Element => (
  <LocationContext.Consumer>
    {({ updateLocation, ...settings }) => <Component {...props} {...settings} updateLocation={updateLocation} />}
  </LocationContext.Consumer>
)

export interface LocationContextInterface {
  country: string
  location: string
  shopifyStore: string
  shopifyStoreDomain: string
  locations: Array<string>
  locating: boolean
  visitor: boolean
  updateLocation: (countryCode: string) => void
}
