import React from "react"

import { useWishlist } from "../../../hooks/useWishlist"

export const withWishlist = Component => ({ name = "Wishlist", product, selectedVariant = {} }) => {
  const { existsInWishlist, addToWishlist, deleteFromWishlist, loading: wishlistLoading } = useWishlist()

  Component.displayName = name
  return (
    <Component
      addToWishlist={addToWishlist}
      deleteFromWishlist={deleteFromWishlist}
      existsInWishlist={existsInWishlist(product?.handle)}
      product={product}
      selectedVariant={selectedVariant}
      wishlistLoading={wishlistLoading}
    />
  )
}
