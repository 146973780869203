import React, { useEffect, useState } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useApp } from "../../../hooks/useApp"
import { useCart } from "../../../hooks/useCart"

export const withCartItem = Component => ({
  name = "CartItem",
  item,
  layout = "",
  lineItems,
}: any) => {
  const {
    loading: cartLoading,
    loadingRemove,
    removeFromCart,
    updateQuantity,
  } = useCart()

  const { cart, giftcard } = useStaticQuery(graphql`
    query SANITY_PAGE_CART_ITEM {
      cart: sanityPageCart {
        additionalRemoveLineItemButtonText
        additionalRemovingLineItemButtonText
        additionalInventoryWarning
        additionalPreOrderText
      }
      giftcard: sanityPageGiftcard {
        settingGiftCardProduct {
          shopify {
            handle: shopifyHandle
          }
        }
        additionalGiftCardMessageLabel
        additionalGiftCardSenderLabel
        additionalGiftCardReceiverLabel
        additionalGiftCardReceiverEmailLabel
      }
    }
  `)

  const {
    additionalRemoveLineItemButtonText,
    additionalRemovingLineItemButtonText,
    additionalInventoryWarning,
    additionalPreOrderText,
  } = cart || {}

  const {
    settingGiftCardProduct,
    additionalGiftCardSenderLabel,
    additionalGiftCardReceiverLabel,
    additionalGiftCardReceiverEmailLabel,
    additionalGiftCardMessageLabel,
  } = giftcard || {}

  const {
    config: {
      settings: {
        giftCardConstraints,
        routes: { PRODUCT, GIFTCARD },
        keys: { preOrderKey, preOrderValue },
      },
    },
  } = useApp()

  const [inputQuantity, setInputQuantity] = useState(item?.quantity)

  const [warning, setWarning] = useState("")

  const isPreOrder =
    item?.customAttributes?.find(attribute => attribute.key === preOrderKey)
      ?.value === preOrderValue

  const getQuantityAvailable = quantity => {
    if(item?.variant?.requiresShipping === false){
      return -1
    }
    return (
      quantity > 0 &&
      (quantity <= item?.variant?.quantityAvailable || isPreOrder)
    )
  }

  const currentVariantInOtherLineItemsTotalQuantity = lineItems
    .filter(currentItem => currentItem.id !== item.id)
    .reduce(
      (acc, cur) =>
        cur.variant.id === item.variant.id ? acc + cur.quantity : acc,
      0,
    )

  const isAddQuantityDisabled =
  item?.variant?.requiresShipping === false ? false : cartLoading ||
    (item?.variant?.quantityAvailable -
      currentVariantInOtherLineItemsTotalQuantity <=
      item?.quantity &&
      !isPreOrder)

  const handleQuantity = quantity => {
    setInputQuantity(quantity)
    if (getQuantityAvailable(quantity)) {
      updateQuantity(item?.id, quantity)
      setWarning("")
    } else {
      setWarning(
        additionalInventoryWarning.replace(
          "${amount}",
          item?.variant?.quantityAvailable,
        ),
      )
    }
  }

  const handleRemove = () => removeFromCart(item?.id)

  const handleChange = ({ target: { value } }) => {
    const quantity = parseInt(value, 10)
    setInputQuantity(quantity)
    if (getQuantityAvailable(quantity)) {
      updateQuantity(item?.id, quantity)
      setWarning("")
    } else {
      setWarning(
        additionalInventoryWarning.replace(
          "${amount}",
          item?.variant?.quantityAvailable,
        ),
      )
    }
  }

  // intentionally ignore inputQuantity in the dependencies, this hook will only run when cart item quantity change.
  useEffect(() => {
    if (item.quantity !== inputQuantity) {
      if (cartLoading) {
        setInputQuantity(inputQuantity)
      } else {
        setInputQuantity(item.quantity)
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item.quantity, cartLoading])

  const isGiftCard =
    item?.variant?.product?.handle === settingGiftCardProduct?.shopify?.handle

  const url = isGiftCard ? GIFTCARD : `${PRODUCT}/${item?.variant?.product?.handle}`

  const cartPromotionMessages = item.customAttributes
    .find(item => item.key === "_appliedPromotionRules")
    ?.value?.split(",")
  const discountMessage = [...(cartPromotionMessages ? cartPromotionMessages : []),
    ...item.discountAllocations.map(discount => discount?.discountApplication?.title)].filter(message => message !== "Promotional discount")

  const discountAllocationsAmount =
    item?.discountAllocations?.reduce(
      (acc, cur) => acc + Number(cur.allocatedAmount.amount),
      0,
    ) / item.quantity

  const isFreeGift = item.customAttributes
    .find(item => item.key === "_promotionalFreeGift")
    ?.value?.split(",")

  Component.displayName = name
  return (
    <Component
      layout={layout}
      isGiftCard={isGiftCard}
      item={item}
      url={url}
      loading={cartLoading}
      loadingRemove={loadingRemove}
      handleQuantity={handleQuantity}
      handleChange={handleChange}
      inputQuantity={inputQuantity}
      isAddQuantityDisabled={isAddQuantityDisabled}
      warning={warning}
      isPreOrder={isPreOrder}
      handleRemove={handleRemove}
      additionalRemoveLineItemButtonText={additionalRemoveLineItemButtonText}
      additionalRemovingLineItemButtonText={
        additionalRemovingLineItemButtonText
      }
      additionalPreOrderText={additionalPreOrderText}
      additionalGiftCardSenderLabel={additionalGiftCardSenderLabel}
      additionalGiftCardMessageLabel={additionalGiftCardMessageLabel}
      additionalGiftCardReceiverLabel={additionalGiftCardReceiverLabel}
      additionalGiftCardReceiverEmailLabel={
        additionalGiftCardReceiverEmailLabel
      }
      giftCardConstraints={giftCardConstraints}
      discountAllocationsAmount={discountAllocationsAmount}
      discountMessage={discountMessage}
      isFreeGift={isFreeGift}
    />
  )
}
