import { useStaticQuery, graphql } from "gatsby"

export const useOrganisation = () =>
  useStaticQuery(graphql`
    query ORGANISATION_SANITY_SETTINGS {
      organisation: sanitySettingOrganisation {
        title
        description
        author
        logo {
          asset {
            url
          }
        }
        addressLocality
        addressRegion
        postalCode
        streetAddress
        separator
        url
      }
    }
  `)
