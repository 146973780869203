import React from "react"
import { withTextButtonsGrid } from "./withTextButtonsGrid"
import {
  Wrapper,
  Title,
  Description,
  Buttons,
  Button,
  Desktop,
  Mobile,
} from "./TextButtonsGridStyles"

export const TextButtonsGrid = withTextButtonsGrid(
  ({
    align,
    buttons,
    mobileButtons,
    buttonMarginTop,
    description,
    mobileDescription,
    descriptionMarginTop,
    hideOnMobile,
    hideDescriptionOnMobile,
    maxWidth,
    buttonsPadding,
    horizontalPaddingAround,
    verticalPaddingAround,
    title,
    mobileTitle,
  }) => {
        
    return title?.length > 0 || description?.length > 0 || buttons?.length > 0 ? (
      <Wrapper
        maxWidth={maxWidth}
        horizontalPaddingAround={horizontalPaddingAround}
        verticalPaddingAround={verticalPaddingAround}
      >
        <Desktop>
          <Title>{title}</Title>
          <Description descriptionMarginTop={descriptionMarginTop}>
            {description}
          </Description>
          <Buttons align={align} buttonMarginTop={buttonMarginTop}>
            {buttons?.map((button, idx) => (
              <Button
                buttonsPadding={buttonsPadding}
                key={idx + button?._key}
                link={button?.link}
                style={button?.style}
                size={button?.size}
              />
            ))}
          </Buttons>
        </Desktop>
        {hideOnMobile ? null : (
          <Mobile>
            <Title>{mobileTitle || title}</Title>
            <Description
              descriptionMarginTop={descriptionMarginTop}
              hideDescriptionOnMobile={hideDescriptionOnMobile}
            >
              {mobileDescription || description}
            </Description>
            <Buttons align={align} buttonMarginTop={buttonMarginTop}>
              {mobileButtons?.map((button, idx) => (
                <Button
                  buttonsPadding={buttonsPadding}
                  key={idx + 'mobbtns' + button?._key}
                  link={button?.link}
                  style={button?.style}
                  size={button?.size}
                />
              ))}
            </Buttons>
          </Mobile>
        )}
      </Wrapper>
    ) : null
}
)