import tw from "twin.macro"

export const Wrapper = tw.nav`
  flex flex-col md:mx-1-6 mb-4 w-full
`

export const Title = tw.h3`
  text-14 leading-1.71 mb-1-6 font-bold uppercase 
`

export const Address = tw.div`
  mb-1-6 text-white text-opacity-70
`

export const Number = tw.a`
  mb-1-6 text-white text-opacity-70
`

export const Text = tw.p`
  text-14 leading-1.42 font-medium
`

export const Contact = tw.div`
  text-14 leading-1.42 font-medium hidden md:block text-white text-opacity-70
`
