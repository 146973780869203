import tw, { styled } from "twin.macro"
import { Link } from "gatsby"

export const Wrapper = tw.div`
`

export const Products = tw.div`
  grid grid-cols-2 mlg:grid-cols-4 gap-2 mb-2 w-4/5
`

export const ButtonWrapper = tw.div`
  flex justify-start pb-8 md:pb-0
`

export const Divider = tw.hr`
  text-grey-lightest my-2
`

export const SubTitle = tw.h3`
  text-14 leading-1.42 tracking-10 font-semibold uppercase mb-2
`

export const Collections = tw.div`
  flex flex-wrap mb-2
`

export const Collection = styled(Link)`
  ${tw`bg-grey-light px-0-8 py-0-4 text-14 leading-1.42 tracking-5 mr-0-8 mb-0-4`}
`
