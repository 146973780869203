import React, { memo } from "react"
import { Link } from "gatsby"

import { withSearchProductCard } from "./withSearchProductCard"
import {
  Wrapper,
  InnerWrapper,
  ImageWrapper,
  DetailsWrapper,
  Details,
  DetailsRow,
  Wishlist,
  Title,
  Rating,
  QuickViewWrapper,
} from "../CardStyles"
import { ImageWithHover } from "../../../ImageWithHover/ImageWithHover"
import { Price } from "../../Price/Price"
import { Badge } from "../../Badge/Badge"
import { Message } from "../../Message/Message"
import { Wishlist as WishlistIcon } from "../../Wishlist/Wishlist"
import { CardSwatches } from "../../Swatches/Card/CardSwatches"
import { QuickView } from "../../QuickView/QuickView"
import { StarRating } from "../../../Okendo/StarRating"
import { LoadingSpinner } from "../../../Search/List/Loading/Loading"

export const SearchProductCardComponent = ({
  defaultProductHandle,
  activeProduct,
  hoverActiveProduct,
  url,
  product,
  products,
  activeProductHandle,
  setActiveProductHandle,
  setHoverActiveProductHandle,
  parentProductHandle,
  hideQuickView,
  isGiftCard,
  isSizeSoldOut,
  soldOutSize,
  collectionsObjForBreadCrumbs,
  layout,
  price,
  isPriceLoaded,
}) => {
  return (
    <Wrapper>
      <InnerWrapper>
        <ImageWrapper
          className="group"
          image={hoverActiveProduct?.images?.length > 0}
        >
          <Link
            state={
              collectionsObjForBreadCrumbs?.length > 0
                ? { collections: collectionsObjForBreadCrumbs }
                : null
            }
            to={url}
            title={activeProduct?.title}
          >
            <ImageWithHover
              image={hoverActiveProduct?.images?.[0]}
              hoverImage={hoverActiveProduct?.images?.[1]}
              ratio={"15/19"}
            />
          </Link>
          {hideQuickView ? null : (
            <QuickViewWrapper>
              <QuickView
                handle={activeProduct?.handle}
                parentProductHandle={parentProductHandle}
              />
            </QuickViewWrapper>
          )}
        </ImageWrapper>
        {!isGiftCard && layout !== "suggestion" && (
          <CardSwatches
            products={products}
            defaultProductHandle={defaultProductHandle}
            activeProductHandle={activeProductHandle}
            setActiveProductHandle={setActiveProductHandle}
            setHoverActiveProductHandle={setHoverActiveProductHandle}
          />
        )}
        <DetailsWrapper>
          {layout !== "suggestion" && (
            <Badge
              isSizeSoldOut={isSizeSoldOut}
              tags={activeProduct?.tags}
              layout={"card"}
            >
              {!soldOutSize ? undefined : `Size ${soldOutSize} Sold Out Online`}
            </Badge>
          )}
          <DetailsRow>
            <Title
              as={Link}
              to={url}
              state={
                collectionsObjForBreadCrumbs?.length > 0
                  ? { collections: collectionsObjForBreadCrumbs }
                  : null
              }
              title={activeProduct?.title}
            >
              {activeProduct?.title}
            </Title>
            <Wishlist>
              {!isGiftCard && <WishlistIcon product={activeProduct} />}
            </Wishlist>
          </DetailsRow>
          {!isGiftCard && (
            <Details>
              {isPriceLoaded ? (
                <Price variant={price} layout={"card"} />
              ) : (
                <LoadingSpinner />
              )}
              <Rating>
                <StarRating productId={activeProduct?.productLegacyResourceId?.toString() || product?._id?.toString() || ""} />
              </Rating>
            </Details>
          )}
          <Message tags={activeProduct?.tags} layout={"card"} />
        </DetailsWrapper>
      </InnerWrapper>
    </Wrapper>
  )
}

export const SearchProductCard = withSearchProductCard(
  memo(SearchProductCardComponent),
)
