import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import { getAdditionalText } from "../../../../utils/getAdditionalText"
import { useApp } from "../../../../hooks/useApp"
import { useRoutes } from "../../../../hooks/useRoutes"

export const withInstantSearchNoResults = Component => ({
  name = "InstantSearchNoResults",
}) => {
  const {
    template: {
      global: {
        additionalPopularCollectionsTitle,
        additionalBrowseAllProducts,
      },
    },
  } = getAdditionalText()

  const {
    config: {
      settings: { routes },
    },
  } = useApp()

  const { linkResolver } = useRoutes()

  const { global, searchEmpty } = useStaticQuery(graphql`
    query SANITY_PAGE_SEARCH_NO_RESULTS {
      global: sanityTemplateGlobal {
        noResults: _rawNoResults(resolveReferences: { maxDepth: 2 })
        popularCollections {
          title
          id
          shopify {
            handle: shopifyHandle
          }
        }
      }
      searchEmpty: sanityPageSearch {
        searchNoResultsButton: _rawSearchNoResultsButton(
          resolveReferences: { maxDepth: 2 }
        )
      }
    }
  `)

  const { noResults, popularCollections } = global || {}

  const { searchNoResultsButton } = searchEmpty || {}

  const noResultsLink = linkResolver(searchNoResultsButton)

  const formattedPopularCollections = popularCollections?.map(
    ({ title, id, shopify }) => ({
      title,
      id,
      url: `${routes.COLLECTION}/${shopify.handle}`,
    }),
  )

  Component.displayName = name
  return (
    <Component
      noResults={noResults}
      popularCollections={formattedPopularCollections}
      noResultsLink={noResultsLink}
      additionalPopularCollectionsTitle={additionalPopularCollectionsTitle}
      additionalBrowseAllProducts={additionalBrowseAllProducts}
    />
  )
}
