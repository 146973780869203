import React, { useEffect, useState } from "react"
import { useApp } from "../hooks/useApp"
import { useCore } from "../hooks/useCore"

export const WishlistContext: any = React.createContext({})

export const WishlistProvider = ({ children }): JSX.Element => {
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const savedWishlist = storage.get(keys.wishlist)

  const [wishlist, setWishlist] = useState([])

  const values = {
    wishlist,
    setWishlist,
    count: wishlist.length,
  }

  useEffect(() => {
    storage.set(keys.wishlist, wishlist)
  }, [keys.wishlist, storage, wishlist])

  useEffect(() => {
    if (savedWishlist?.length) {
      setWishlist(savedWishlist)
    }
    // initialize at first render
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return (
    <WishlistContext.Provider value={{ ...values }}>
      {children}
    </WishlistContext.Provider>
  )
}
