import React from "react"

import { withNewsletter } from "./withNewsletter"
import { Content, ImageWrapper } from "./NewsletterStyles"
import { NewsletterForm } from "./NewsletterForm"
import { ImageVideo } from "../ImageVideo/ImageVideo"
import { ModalExternal, ModalContentsWithLayout } from "../Modal/Modal"

export const Newsletter = withNewsletter(
  ({ activeSubscribe, setActiveSubscribe, featuredImage, imagePosition }) => (
    <ModalExternal isOpen={activeSubscribe} setIsOpen={setActiveSubscribe}>
      <ModalContentsWithLayout
        layout={
          imagePosition === "top" || imagePosition === "bottom"
            ? "newsletter-sm"
            : "newsletter-lg"
        }
      >
        <Content imagePosition={imagePosition}>
          <ImageWrapper>
            <ImageVideo imageVideo={featuredImage} fullHeight />
          </ImageWrapper>
          <NewsletterForm setActiveSubscribe={setActiveSubscribe} />
        </Content>
      </ModalContentsWithLayout>
    </ModalExternal>
  ),
)
