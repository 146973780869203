import tw, { styled } from "twin.macro"
import { Swatch } from "../../Swatch/Swatch"

export const Wrapper = tw.div`
  flex flex-row mb-1-6 items-center
`

export const SliderWrapper = tw.div`
  relative px-2-4 mb-1-6
`

export const SliderInnerWrapper = tw.div`
  max-w-11 md:max-w-22 mx-auto
`

export const Prev = styled.button`
  ${tw`absolute left-0 top-0-2 z-10`}
  &.swiper-button-disabled {
    ${tw`opacity-25`}
  }
  span {
    svg {
      ${tw`w-1-8 md:w-2-1 h-1-8 md:h-2-1`}
    }
  }
`

export const Next = styled.button`
  ${tw`absolute right-0 top-0-2 z-10`}
  &.swiper-button-disabled {
    ${tw`opacity-25`}
  }
  span {
    svg {
      ${tw`w-1-8 md:w-2-1 h-1-8 md:h-2-1`}
    }
  }
`

export const StyledSwatch = styled(Swatch)`
  ${({ isSlider }) => (!isSlider ? tw`mr-1` : null)}
  ${tw`p-0-3 block`}
`
