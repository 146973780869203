import tw, { styled } from "twin.macro"

export const VideoWrapper = tw.div`
  relative w-full h-full focus:outline-none
`

export const IconWrapper = styled.button`
  ${tw`absolute inset-0 w-full h-full flex items-center justify-center focus:outline-none`}
  div {
    ${tw`z-10`}
  }
`

export const VideoPlayerWrapper = tw.div`
  relative w-full 
`

export const VideoPlayerRatio = styled.div<VideoPlayerRatioProps>`
  ${tw``}
  padding-bottom: ${({ ratio }) => {
    if (typeof ratio === "string")
      return (Number(ratio.split("/")[1]) / Number(ratio.split("/")[0])) * 100
    if (typeof ratio === "number") return (1 / ratio) * 100
  }}%;
`

export const VideoPlayerInnerWrapper = styled.div`
${tw`block w-full h-auto`}
${({ ratio }) => ratio ? tw`absolute inset-0 w-full h-full` : tw`w-full`}
`
// ${tw`inset-0 w-full h-full`}

type VideoPlayerRatioProps = {
  ratio: string | number
}
