import React, { useState } from "react"

import SwiperCore, { Navigation } from "swiper"
import { Swiper, SwiperSlide } from "swiper/react"
import { Icon } from "../../../Icon/Icon"
import {
  Wrapper,
  SliderWrapper,
  Prev,
  Next,
  StyledSwatch,
  SliderInnerWrapper,
} from "./CardSwatchesStyles"

SwiperCore.use([Navigation])

export const MobileCardSwatches = ({
  activeProductHandle,
  defaultProductHandle,
  swatches,
  setActiveProductHandle,
  setHoverActiveProductHandle,
}) => {
  const [isExpanded, setIsExpanded] = useState(false)
  return swatches.length > 3 ? (
    isExpanded ? (
      <SliderWrapper>
        <SliderInnerWrapper>
          <Swiper
            className={`${defaultProductHandle}-swatch-swiper-mobile`}
            id={`${defaultProductHandle}-swatch-swiper-mobile`}
            slidesPerView={4}
            spaceBetween={0}
            navigation={{
              nextEl: `#${defaultProductHandle}-mobile-next`,
              prevEl: `#${defaultProductHandle}-mobile-prev`,
            }}
          >
            {swatches?.map(({ id, title, handle, swatchImage }) => (
              <SwiperSlide key={id}>
                <StyledSwatch
                  isSlider
                  title={title}
                  image={swatchImage}
                  active={activeProductHandle === handle}
                  onClick={() => setActiveProductHandle(handle)}
                  onMouseEnter={() => setHoverActiveProductHandle(handle)}
                  onMouseLeave={() =>
                    setHoverActiveProductHandle(activeProductHandle)
                  }
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </SliderInnerWrapper>
        <Prev id={`${defaultProductHandle}-mobile-prev`}>
          <Icon name={"arrowLeft"} />
        </Prev>
        <Next id={`${defaultProductHandle}-mobile-next`}>
          <Icon name={"arrow"} />
        </Next>
      </SliderWrapper>
    ) : (
      <Wrapper>
        {swatches.slice(0, 3)?.map(({ title, handle, swatchImage }) => (
          <StyledSwatch
            isSlider={false}
            key={handle}
            title={title}
            image={swatchImage}
            active={activeProductHandle === handle}
            onClick={() => setActiveProductHandle(handle)}
            onMouseEnter={() => setHoverActiveProductHandle(handle)}
            onMouseLeave={() =>
              setHoverActiveProductHandle(activeProductHandle)
            }
          />
        ))}
        <button type="button" onClick={() => setIsExpanded(true)}>
          <Icon name="plus" width="12" height="12" />
        </button>
      </Wrapper>
    )
  ) : (
    <Wrapper>
      {swatches?.map(({ title, handle, swatchImage }) => (
        <StyledSwatch
          isSlider={false}
          key={handle}
          title={title}
          image={swatchImage}
          active={activeProductHandle === handle}
          onClick={() => setActiveProductHandle(handle)}
          onMouseEnter={() => setHoverActiveProductHandle(handle)}
          onMouseLeave={() => setHoverActiveProductHandle(activeProductHandle)}
        />
      ))}
    </Wrapper>
  )
}
