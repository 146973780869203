import React from "react"

import { withNewsletterForm } from "./withNewsletterForm"
import { StyledFormErrors } from "../Styled/Form"
import {
  Wrapper,
  Form,
  Success,
  Title,
  Description,
  Email,
  Submit,
  Terms,
  Close,
  Label,
} from "./NewsletterStyles"

export const NewsletterForm = withNewsletterForm(
  ({
    success,
    email,
    handleChange,
    handleSubmit,
    handleClose,
    errors,
    title,
    description,
    placeholder,
    submitButtonText,
    termsText,
    terms,
    privacy,
    closeButtonText,
    successTitle,
    successDescription,
    successCloseButtonText,
  }) => (
    <Wrapper>
      {!success ? (
        <Form onSubmit={handleSubmit}>
          <Title>{title}</Title>
          <Description>{description}</Description>
          <Label htmlFor="email">{placeholder}</Label>
          <Email id={"email"} value={email} onChange={handleChange} />
          {errors.length > 0 ? (
            <StyledFormErrors>{errors}</StyledFormErrors>
          ) : null}
          <Submit type="submit" colour={"navyFilledWithWhiteText"} wide>
            {submitButtonText}
          </Submit>
          <Terms>
            {termsText?.split("{privacy}")[0]}
            <a
              href={terms?.url}
              className={`underline`}
              target={`_blank`}
              rel="noopener noreferrer"
            >
              {privacy?.title}
            </a>
            {termsText?.split("{privacy}")[1]?.split("{terms}")[0]}
            <a
              href={privacy?.url}
              className={`underline`}
              target={`_blank`}
              rel="noopener noreferrer"
            >
              {terms?.title}
            </a>
            {termsText?.split("{privacy}")[1]?.split("{terms}")[1]}
          </Terms>
          <Close onClick={handleClose}>
            <span>{closeButtonText}</span>
          </Close>
        </Form>
      ) : (
        <Success>
          <Title>{successTitle}</Title>
          <Description>{successDescription}</Description>
          <Close onClick={handleClose}>
            <span>{successCloseButtonText}</span>
          </Close>
        </Success>
      )}
    </Wrapper>
  ),
)
