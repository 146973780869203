import tw, { styled } from "twin.macro"

export const Wrapper = tw.nav`w-full mlg:w-auto flex justify-center mlg:justify-start py-1-6`

export const List = tw.ul`
  flex flex-row
`

export const ListItem = styled.li`
  ${tw`text-12 border-r-2 px-1-2 border-white`}
  :first-of-type {
    ${tw`pl-0`}
  }
  :last-of-type {
    ${tw`border-0 mlg:border-r-2 pr-0 mlg:pr-1-2`}
  }
`
