import gql from "graphql-tag"

import { PRODUCT_FRAGMENT } from "../fragments/productFragment"
import { COLLECTION_FRAGMENT } from "../fragments/collectionFragment"

export const GET_COLLECTION = gql`
  query($handle: String!) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
    }
  }
  ${COLLECTION_FRAGMENT}
`

export const GET_COLLECTION_PRODUCT_REFERENCES = gql`
  query($handle: String!, $firstProducts: Int) {
    collection: collectionByHandle(handle: $handle) {
      products(first: $firstProducts) {
        edges {
          node {
            id
            handle
            tags
          }
        }
      }
    }
  }
`

export const GET_COLLECTION_PRODUCT_COMPLETE = gql`
  query(
    $handle: String!
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstMetafields: Int
    $firstProducts: Int
    $firstVariants: Int
    $afterVariants: String
  ) {
    collection: collectionByHandle(handle: $handle) {
      ...CollectionFragment
      products(first: $firstProducts) {
        edges {
          node {
            ...CustomizedProductFragment
          }
        }
      }
    }
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`

export const GET_COLLECTIONS_BY_HANDLE = handles => gql`
  query(
    $firstCollections: Int
    $firstImages: Int
    $firstMedia: Int
    $firstMetafields: Int
    $firstProducts: Int
    $firstVariants: Int
    $afterVariants: String
  ) {
    ${handles?.map(
      handle => `
      collection${handle.replace(
        /-/g,
        "",
      )}: collectionByHandle(handle: "${handle}") {
        ...CollectionFragment
        products(first: $firstProducts) {
          edges {
            node {
              ...CustomizedProductFragment
            }
          }
        }
      }
    `,
    )}
  }
  ${COLLECTION_FRAGMENT}
  ${PRODUCT_FRAGMENT}
`
