import React from "react"
import { withSearchWidget } from "./withSearchWidget"

import { SearchButton, Text, StyledIcon } from "./SearchWidgetStyles"

export const SearchWidget = withSearchWidget(({ handleClick, text }) => (
  <SearchButton title={"search"} onClick={handleClick}>
    <StyledIcon name={"search"} />
    <Text>{text}</Text>
  </SearchButton>
))
