import { useStaticQuery, graphql } from "gatsby"

export const useSocial = () =>
  useStaticQuery(graphql`
    query SOCIAL_SANITY_SETTINGS {
      social: sanitySettingSocial {
        channels {
          title
          url
        }
      }
    }
  `)
