import { useSearch } from "@usereactify/search"
import { navigate } from "gatsby"
import React, { useCallback, useEffect, useRef, useState } from "react"
import { useApp } from "../../../../hooks/useApp"
import { useDebounce } from "../../../../hooks/useDebounce"

import { getAdditionalText } from "../../../../utils/getAdditionalText"

export const withInstantSearchForm = Component => ({
  name = "InstantSearchForm",
}) => {
  const {
    template: {
      global: { additionalInstantSearchPlaceholderText },
    },
    page: {
      search: { additionalSearchButtonText, additionalSearchPlaceholderText },
    },
  } = getAdditionalText()

  const {
    globalState,
    config: {
      settings: { routes },
    },
  } = useApp()

  const inputRef = useRef()
  const { searchTerm, setSearchTerm } = useSearch()
  const [value, setValue] = useState("")
  const [{ activeSearch }, dispatch] = globalState

  const handleSubmit = useCallback(
    event => {
      event.preventDefault()

      if (searchTerm) navigate(`${routes.SEARCH}?q=${searchTerm}`)
    },
    [routes, searchTerm],
  )

  const handleChange = useCallback(({ target: { value } }) => setValue(value), [
    setValue,
  ])

  const handleClose = useCallback(() => {
    if (searchTerm) {
      setValue("")
      setSearchTerm("")
      if (inputRef?.current) inputRef.current?.focus()
    } else {
      dispatch({
        type: "HIDE_SEARCH",
      })
    }
  }, [dispatch, inputRef, searchTerm, setSearchTerm, setValue])

  useDebounce(
    () => {
      if (value !== searchTerm) setSearchTerm(value)
    },
    300,
    [value],
  )

  useEffect(() => {
    if (activeSearch && inputRef?.current) inputRef.current?.select()
  }, [activeSearch])

  Component.displayName = name
  return (
    <Component
      value={value}
      inputRef={inputRef}
      handleSubmit={handleSubmit}
      handleChange={handleChange}
      handleClose={handleClose}
      additionalSearchButtonText={additionalSearchButtonText}
      additionalSearchPlaceholderText={additionalSearchPlaceholderText}
      additionalInstantSearchPlaceholderText={
        additionalInstantSearchPlaceholderText
      }
    />
  )
}
