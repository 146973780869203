import React from "react"
import { withImageVideo } from "./withImageVideo"
import { Wrapper, Desktop, Mobile } from "./ImageVideoStyles"
import { Image } from "../Image/Image"
import { Video } from "../Video/Video"

export const ImageVideo = withImageVideo(
  ({
    link,
    desktop,
    desktopAlt,
    desktopVideo,
    mobile,
    mobileAlt,
    mobileVideo,
    ratio,
    autoplay,
    controls,
    fullHeight,
  }) => (
    <Wrapper fullHeight={fullHeight}>
      <Desktop fullHeight={fullHeight}>
        {desktopVideo ? (
          <Video
            url={desktopVideo}
            image={desktop}
            ratio={
              ratio
                ? ratio
                : desktop?.dimensions?.aspectRatio
                ? desktop?.dimensions?.aspectRatio
                : "16/9"
            }
            alt={desktopAlt}
            autoplay={autoplay}
            controls={controls}
          />
        ) : (
          <Image
            image={desktop}
            ratio={
              ratio
                ? ratio
                : desktop?.dimensions?.aspectRatio
                ? desktop?.dimensions?.aspectRatio
                : "16/9"
            }
            alt={desktopAlt}
            link={link}
            fullHeight={fullHeight}
          />
        )}
      </Desktop>
      <Mobile>
        {mobileVideo || desktopVideo ? (
          <Video
            url={mobileVideo ?? desktopVideo}
            image={mobile}
            ratio={
              ratio
                ? ratio
                : mobile?.dimensions?.aspectRatio
                ? mobile?.dimensions?.aspectRatio
                : mobileVideo
                ? "9/16"
                : "16/9"
            }
            alt={mobileAlt}
            autoplay={autoplay}
            controls={controls}
          />
        ) : (
          <Image image={mobile} ratio={ratio} alt={mobileAlt} link={link} />
        )}
      </Mobile>
    </Wrapper>
  ),
)
