import React from "react"

import { withSubscribe } from "./withSubscribe"
import {
  Form,
  Label,
  EmailWrapper,
  Email,
  SuccessMessage,
  Submit,
  Description,
} from "./SubscribeStyles"
import { StyledFormErrors } from "../Styled/Form"

export const Subscribe = withSubscribe(
  ({
    email,
    handleChange,
    handleSubmit,
    loading,
    errors,
    title,
    description,
    placeholder,
    submitButtonText,
    success,
    successMessage,
  }): JSX.Element => (
    <Form onSubmit={handleSubmit} autoComplete="off">
      <Label htmlFor={"email"}>{title}</Label>
      <Description>{description}</Description>
      <EmailWrapper>
        <Email
          id="email"
          name="email"
          type="email"
          value={email}
          onChange={handleChange}
          placeholder={placeholder}
          required
        />
        <Submit type={`submit`} disabled={loading}>
          {submitButtonText}
        </Submit>
      </EmailWrapper>
      {success && <SuccessMessage>{successMessage}</SuccessMessage>}
      {errors && <StyledFormErrors>{errors}</StyledFormErrors>}
    </Form>
  ),
)
