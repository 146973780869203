import React, { useState } from "react"
import { useCore } from "../../hooks/useCore"

export const withVideo = Component => ({
  name = "Video",
  autoplay = false,
  controls = false,
  ratio = undefined,
  ...rest
}) => {
  const {
    helpers: { isDomReady },
  } = useCore()  
  const [playing, setPlaying] = useState(autoplay)
  const handleVideoPlaying = () => {
    setPlaying(prev => !prev)
  }

  const formattedControls = autoplay ? controls : true

  const formattedRatio =
    typeof ratio === "object" && ratio?.width > 0 && ratio?.height > 0
      ? `${ratio.width}/${ratio.height}`
      : ratio

  Component.displayName = name
  return (
    <Component
      playing={playing}
      autoplay={autoplay}
      controls={formattedControls}
      handleVideoPlaying={handleVideoPlaying}
      ratio={formattedRatio}
      isDomReady={isDomReady}
      {...rest}
    />
  )
}
