import tw, { styled, css } from "twin.macro"

import { StyledContainer } from "../../../../../Styled/Container"

export const OuterBackgroundLeft = styled.div`
  ${tw`absolute w-screen h-full top-0 left-0 bg-white hidden md:block transform transition-all duration-300 z-10`}
`
export const OuterBackgroundRight = styled.div`
  ${tw`absolute w-screen h-full top-0 right-0 bg-white hidden md:block transform transition-all duration-300 z-10`}
`

export const Outer = styled.section`
  ${tw`absolute inset-x-0 w-full top-full bg-white hidden md:block transform transition-all duration-300 bg-white z-20 border-grey-lightest`}
  ${({ active }) =>
    active
      ? tw`pointer-events-auto visible border-t`
      : tw`pointer-events-none invisible border-t-0`}
`

export const Inner = styled(StyledContainer)`
  ${tw`relative flex flex-col py-6 justify-between z-10`}
`

export const Dropdown = styled.div`
  ${tw`grid`}
  ${({ cols }) => css`
    grid-template-columns: repeat(${cols}, minmax(0, 1fr));
  `}}
`

export const List = styled.div`
  ${({ twocol }) => (twocol ? tw`col-span-2` : tw`col-span-1`)}
  ${tw`w-full px-4 border-r border-grey-lightest`}
  :first-of-type {
    ${tw`pl-0`}
  }
  :last-of-type {
    ${tw`pr-0 border-r-0`}
  }
`

export const ShopAll = tw.div`
  mt-3
`
