import React, { useMemo } from "react"
import { graphql, useStaticQuery } from "gatsby"

import { useNavigation } from "../../../hooks/useNavigation"

export const withNavigationFooter = Component => ({ name = "NavigationFooter" }: any) => {
  const { mapItems } = useNavigation()

  const { menus } = useStaticQuery(graphql`
    query SANITY_SETTINGS_FOOTER_MENU {
      menus: sanitySettingMenus {
        footer {
          items: _rawItems(resolveReferences: { maxDepth: 8 })
        }
      }
    }
  `)

  const { footer } = menus || {}

  const items = useMemo(() => mapItems(footer?.items), [footer?.items, mapItems])

  Component.displayName = name
  return <Component items={items} />
}
