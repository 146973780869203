import tw, { styled } from "twin.macro"
import { css } from "@emotion/react"

export const Wrapper = styled.li<LayoutProps & LoadingProps>`
  ${({ layout }) => (layout === "drawer" ? tw`px-1` : null)}
  ${({ loading }) => (loading === "true" ? tw`opacity-50` : tw`opacity-100`)}
  ${tw`flex flex-row items-center pt-2-1 pb-2-8 md:py-3-2 border-b border-black border-opacity-10`}
  :last-of-type {
    ${tw`border-b-0`}
  }
`

export const Thumbnail = styled.div<LayoutProps & ImageProps>`
  ${tw``}
  ${tw`relative block`}
  ${({ image }) => {
    if (image === "false")
      return css`
        background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 1000 1000'%3E%3Cpath fill='%23767676' d='M500.5 345c-119.1 0-215.7 96.6-215.7 215.8 0 119.2 96.6 215.8 215.7 215.8S716.2 680 716.2 560.8c0-119.2-96.6-215.8-215.7-215.8zm-1.1 370c-84.7 0-153.3-68.7-153.3-153.3 0-84.7 68.6-153.3 153.3-153.3 84.7 0 153.3 68.7 153.3 153.3C652.6 646.4 584 715 499.4 715zm373.5-490.3H730l-53-108.3c-5.2-9.8-14.5-15.5-24.2-15.9-1-.1-2-.2-3-.2H357c-5.9 0-11.3 1.8-15.9 5-4.6 2.7-8.6 6.8-11.4 12.1l-53.5 107.3H194v-30.9c0-17.4-14.1-31.5-31.5-31.5S131 176.4 131 193.8v30.9h-4c-64.6 0-117 52.5-117 117.2v440.6c0 64.7 52.4 117.1 117.1 117.1H873c64.7 0 117.1-50.5 117.1-115.2V341.9c-.1-64.7-52.5-117.2-117.2-117.2zm58.6 557.8c0 32.4-26.2 58.6-58.5 58.6H127.1c-32.3 0-58.5-26.2-58.5-58.6V343.8c0-32.4 26.2-58.6 58.5-58.6h171v-.1c9.1-.9 17.7-6.5 22.7-15.8l53.2-107h259.1l52.4 106.9c5 9.5 13.9 15.1 23.2 15.9v.1H873c32.3 0 58.5 26.2 58.5 58.6v438.7zm-155-435.1c-17 0-30.7 13.7-30.7 30.7s13.7 30.7 30.7 30.7 30.7-13.8 30.7-30.7c0-17-13.7-30.7-30.7-30.7z'/%3E%3C/svg%3E");
        background-repeat: no-repeat;
        background-size: 40%;
        background-position: 50% 50%;
      `
  }}
  ${({ layout }) =>
    layout === "drawer"
      ? tw`w-8-8 h-11-2 mr-2-1`
      : tw`w-8-8 h-11-2 md:w-11-2 md:h-14-2 mr-2-1 md:mr-3-9`}
`

export const Details = tw.div`
  flex-1 flex flex-col justify-between
`

export const InnerTitleWrapper = tw.div`
  mb-0-8
`

export const InnerTitle = styled.h3<LayoutProps>`
  ${({ layout }) => (layout === "drawer" ? tw`hidden` : tw`hidden md:block`)}
  ${tw`font-bold text-16 leading-1.56 tracking-2 mb-0-8`}
`

export const OuterTitle = styled.h3<LayoutProps>`
  ${({ layout }) => (layout === "drawer" ? tw`block` : tw`md:hidden`)}
  ${tw`font-semibold text-14 leading-1.28 tracking-5 mb-0-8`}
`

export const SelectedOptions = tw.div`
  flex flex-col text-12 leading-1.33 text-grey mr-0-8
`

export const Remove = styled.button`
  ${tw`flex flex-row items-center mr-1-2 text-14 leading-1.71 text-navy text-opacity-60`}
  svg {
    ${tw`mr-0-8`}
  }
`

export const QuantityWrapper = tw.div`
  flex flex-row justify-between items-center border rounded max-w-5-6
`

export const QuantityNumber = tw.input`
  block text-16 md:text-14 leading-1.42 md:leading-1.64 w-1-8 text-center bg-transparent
`

export const QuantityButton = styled.button`
  ${tw`block p-0-4`}
  :disabled {
    ${tw`opacity-25 cursor-default`}
  }
`

export const StyledRow = styled.div`
  ${tw`flex flex-row items-start justify-between`}
  ${({ align }) => (align === "end" ? tw`items-end` : null)}
`

export const DiscountMessage = tw.div`
  text-12 text-red mt-0-4 leading-1.2
`
export const DiscountMessageGst = tw.div`
  text-10 text-grey mt-0-4 leading-1.2
`
export const Warning = tw.p`
  text-12 leading-1.2 text-red uppercase tracking-3
`

export const FreeGiftMessage = tw.p`
  text-14 leading-1.2 text-grey uppercase font-bold mb-0-4
`

type LayoutProps = {
  layout?: "drawer"
}

type LoadingProps = {
  loading: "true" | "false"
}

type ImageProps = {
  image: "true" | "false"
}
