import React from "react"

import { withCartWidget } from "./withCartWidget"
import { Wrapper, Count, StyledIcon } from "./CartWidgetStyles"

export const CartWidget = withCartWidget(
  ({ handleActiveCart, count }): JSX.Element => (
    <Wrapper title={"cart"} onClick={handleActiveCart}>
      <StyledIcon name={"cart"} />
      {count > 0 ? <Count>{count}</Count> : null}
    </Wrapper>
  )
)
