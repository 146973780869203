import React, { useState } from "react"

import { useAnnouncement } from "../../hooks/useAnnouncement"
import { useApp } from "../../hooks/useApp"
import { useCore } from "../../hooks/useCore"
import { getAdditionalText } from "../../utils/getAdditionalText"

export const withCookies = Component => ({ name = "Cookies" }) => {
  const { cookies, hideCookies } = useAnnouncement()
  const [active, setActive] = useState(false)
  const {
    helpers: { storage },
  } = useCore()

  const {
    config: {
      settings: { keys },
    },
  } = useApp()

  const {
    template: {
      global: { additionalCookieAcceptButtonText },
    },
  } = getAdditionalText()

  const handleHide = () => {
    setActive(false)
    hideCookies()
  }

  if (storage.get(keys?.cookies) !== "hidden" && !active) {
    setTimeout(() => setActive(true), 5000)
  }

  Component.displayName = name
  return (
    <Component
      cookies={cookies}
      handleHide={handleHide}
      active={active}
      additionalCookieAcceptButtonText={additionalCookieAcceptButtonText}
    />
  )
}
