import React from "react"

import {
  SubTitle,
  Collections,
  Collection,
} from "./InstantSearchResultsStyles"
import { withInstantSearchResults } from "./withInstantSearchResultsTop"

export const InstantSearchResultsTop = withInstantSearchResults(
  ({
    collections,
    additionalCollectionsTitle,
    additionalProductsTitle,
  }): JSX.Element => (
    <>
      <SubTitle>{additionalCollectionsTitle}</SubTitle>
      <Collections>
        {collections
          ?.filter((_, index) => index < 8)
          ?.map(({ id, title, url }) => (
            <Collection key={id} to={url} title={title}>
              {title}
            </Collection>
          ))}
      </Collections>
      <SubTitle>{additionalProductsTitle}</SubTitle>
    </>
  ),
)
