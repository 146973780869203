import React from "react"
import { withNavigationItemsImageCard } from "./withNavigationItemsImageCard"
import { Outer, Inner, Content } from "./NavigationItemsImageCardStyles"
import { ImageVideo } from "../../ImageVideo/ImageVideo"
import { Link } from "gatsby"

export const NavigationItemsImageCard = withNavigationItemsImageCard(
  ({ image, content, link }) => (
    <Outer>
      <ImageVideo imageVideo={image} />
      <Inner
        as={link?.url && (link?.isExternal ? `a` : Link)}
        href={link?.isExternal && link?.url}
        target={link?.external && `_blank`}
        title={link?.title}
        to={!link?.isExternal && link?.url}
      >
        <Content>{content}</Content>
      </Inner>
    </Outer>
  ),
)
